import { createSlice } from '@reduxjs/toolkit';
import {
  ComposeMessageAction,
  ChatHistoryReceivedAction,
  FetchChatHistoryAction,
  FetchRegisteredUsersAction,
  IsComposeMessageSentAction,
  RegisteredUsersInterface,
  RegisteredUsersReceivedAction,
  RequestAllVehicleLocationsAction,
  markSelectedMessagesAsReadAction,
  setUpdatedRegisteredUsersCountAction,
  getSafetyMessagesAction,
  safetyMessagesReceived,
  RequestingLocationCompletedAction,
  DeleteSafetyMessageAction,
  AddOrEditSafetyMessageAction
} from '../actions/RegisteredUsers';
import { getFlippedGeoJSONForGoogleMaps } from '../../CommonUtilities/CommonUtilities';

const initialState: RegisteredUsersInterface = {
  isFetchingRegisteredUsers: false,
  registeredUsers: null,
  chatHistory: null,
  isFetchingChatHistory: false,
  updatedRegisteredUsersCount: 0,
  isComposeMessageSent: false,
  safetyMessagesData: null,
  isRequestingLocation: false,
  isMessageRequestInProgress: false
};

const RegisteredUsersSlice = createSlice({
  name: 'RegisteredUsers',
  initialState,
  reducers: {
    getRegisteredUsers: (state, _action: FetchRegisteredUsersAction) => {
      state.isFetchingRegisteredUsers = true;
    },
    registeredUsersReceived: (state, action: RegisteredUsersReceivedAction) => {
      if (action.payload.shouldAppend) {
        action.payload.registeredUsers.results = [
          ...state.registeredUsers.results,
          ...action.payload.registeredUsers.results
        ];
      }
      // Flip the coords sytem for google maps
      action.payload.registeredUsers.results =
        action.payload.registeredUsers?.results?.map((registeredUser) => {
          return registeredUser?.location?.coordinates?.length
            ? {
                ...registeredUser,
                location: getFlippedGeoJSONForGoogleMaps(
                  registeredUser.location.coordinates,
                  'point'
                )
              }
            : registeredUser;
        });

      state.registeredUsers = action.payload.registeredUsers;
      state.updatedRegisteredUsersCount =
        action.payload.registeredUsers?.length;
      state.isFetchingRegisteredUsers = false;
    },
    composeMessageRequest: (state, _action: ComposeMessageAction) => {
      state.isMessageRequestInProgress = true;
    },
    isComposeMessageSentSuccessfullyReceived: (
      state,
      action: IsComposeMessageSentAction
    ) => {
      state.isComposeMessageSent = action.payload.isSent;
      state.isMessageRequestInProgress = false;
    },
    getChatHistory: (state, _action: FetchChatHistoryAction) => {
      state.isFetchingChatHistory = true;
    },
    chatHistoryReceived: (state, action: ChatHistoryReceivedAction) => {
      state.chatHistory = action.payload.chatHistory;
      state.isFetchingChatHistory = false;
    },
    setUpdatedRegisteredUsersCount: (
      state,
      _action: setUpdatedRegisteredUsersCountAction
    ) => {
      state.updatedRegisteredUsersCount = state.updatedRegisteredUsersCount + 1;
    },
    markSelectedMessagesAsRead: (
      _state,
      _action: markSelectedMessagesAsReadAction
    ) => {},
    requestAllVehicleLocation: (
      state,
      _action: RequestAllVehicleLocationsAction
    ) => {
      state.isRequestingLocation = true;
    },
    requestingLocationCompleted: (
      state,
      _action: RequestingLocationCompletedAction
    ) => {
      state.isRequestingLocation = false;
    },
    getLifeSafetyMessages: (_state, _action: getSafetyMessagesAction) => {},
    lifeSafetyMessagesReceived: (state, action: safetyMessagesReceived) => {
      state.safetyMessagesData = action.payload.data;
    },
    deleteLifeSafetyMessage: (_state, _action: DeleteSafetyMessageAction) => {},
    addOrEditLifeSafetyMessage: (
      _state,
      _action: AddOrEditSafetyMessageAction
    ) => {}
  }
});

export const {
  getRegisteredUsers,
  registeredUsersReceived,
  composeMessageRequest,
  getChatHistory,
  chatHistoryReceived,
  setUpdatedRegisteredUsersCount,
  markSelectedMessagesAsRead,
  requestAllVehicleLocation,
  isComposeMessageSentSuccessfullyReceived,
  getLifeSafetyMessages,
  lifeSafetyMessagesReceived,
  requestingLocationCompleted,
  deleteLifeSafetyMessage,
  addOrEditLifeSafetyMessage
} = RegisteredUsersSlice.actions;

export default RegisteredUsersSlice.reducer;
