import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFormattedDateAndTimeDynamic } from '../../../CommonUtilities/CommonUtilities';
import { STRINGS } from '../../../Constants/ConstantStrings';
import NoDataFound from '../../../SharedComponets/NoDataFound';
import { messageHistory } from '../../../Store/reducers/PublicView';
import { useReduxDispatch } from '../../../Store/reduxHooks';
import { NoMessagesData } from '../../../Universal/Assets';
import { device } from '../../../Universal/Foundation/breakpoints';
import { Button, Icon, Lozenge } from '../../../Universal/NovusDSImports';
import { btnStyles } from '../../../Universal/NovusDSImports/variants';
import { TabItems } from '../../globalStyles';
import TermsAndConditions from '../TermsConditions';
import {
  FormPrimaryHeader,
  HighlightMessage,
  MessageCards,
  PublicViewContainerStyles,
  UserRegistrationForm
} from '../styles';
import PALoader from '../../../SharedComponets/PALoader';

const MessageHistory = () => {
  const [showTermsAndConditions, setShowTermsAndConditions] =
      useState<boolean>(false),
    tabs = [
      {
        value: '',
        label: 'All Messages'
      },
      {
        label: STRINGS.PUBLIC_SAFETY_ANNOUNCEMENT,
        value: 'public safety announcements'
      },
      {
        label: STRINGS.SITUATIONAL_AWARNESS_UPDATES,
        value: 'situational awareness updates'
      }
    ],
    [selectedTab, setSelectedTab] = useState<string>(
      sessionStorage.getItem('message_type') || ''
    ),
    [isMobileView, setIsMobileView] = useState(false),
    dispatch = useReduxDispatch(),
    history = useHistory(),
    messageHistoryData = useSelector(
      (state: any) => state.PublicView.messageHistoryData
    ),
    isFetchingMessageHistoryData = useSelector(
      (state: any) => state.PublicView.isFetchingMessageHistoryData
    );

  const urlParams = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return Object.fromEntries(params.entries());
  }, []);

  useEffect(() => {
    return () => {
      if (sessionStorage.getItem('message_type')) {
        sessionStorage.removeItem('message_type');
      }
    };
  }, []);

  const getHistoryData = useCallback(() => {
    dispatch(
      messageHistory({
        closureId: urlParams?.closure_id,
        message_type: selectedTab
      })
    );
  }, [dispatch, selectedTab, urlParams?.closure_id]);

  useEffect(() => {
    if (!urlParams?.closure_id) {
      history.push('/');
    } else {
      getHistoryData();
    }
  }, [dispatch, getHistoryData, history, selectedTab, urlParams?.closure_id]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const termsConditions = () => {
    setShowTermsAndConditions(true);
  };

  const onTermsAndConditionsBackClick = () => {
    setShowTermsAndConditions(false);
  };

  const [selectedMessageIndex, setSelectedMessageIndex] = useState({
    selectedMessageIndex: null,
    messageCloseState: true,
    messageOpenState: false
  });

  const showFullMessage = (index) => {
    if (!selectedMessageIndex.messageOpenState) {
      setSelectedMessageIndex((prevState) => ({
        ...prevState,
        selectedMessageIndex:
          prevState.selectedMessageIndex === index ? null : index,
        messageCloseState: true,
        messageOpenState: prevState.selectedMessageIndex !== index
      }));
    }
  };

  const messageClose = (index) => {
    if (selectedMessageIndex.messageOpenState) {
      setSelectedMessageIndex((prevState) => ({
        ...prevState,
        selectedMessageIndex:
          prevState.selectedMessageIndex === index ? null : index,
        messageCloseState: true,
        messageOpenState: prevState.selectedMessageIndex !== index
      }));
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const MediaQuery = window.matchMedia(`${device.mobileXL}`);
      setIsMobileView(MediaQuery.matches);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.addEventListener('resize', handleResize);
    };
  }, []);

  const onBackClick = () => {
    history.push('/');
    if (sessionStorage.getItem('message_type')) {
      sessionStorage.removeItem('message_type');
    }
  };

  return (
    <PublicViewContainerStyles>
      {!showTermsAndConditions && (
        <div
          className="d-flex overflow-auto flex-column flex-grow-1 h-100 w-100"
          data-testid="messageHistoryPage"
        >
          <div
            className="container justify-content-between"
            onKeyDown={handleKeyDown}
          >
            <div className="gap-4 d-flex flex-column overflow-auto flex-grow-1">
              <FormPrimaryHeader className="text-lg-nowrap">
                <div className="form-main-heading">
                  {STRINGS.MESSAGE_HISOTRY}
                </div>
                {messageHistoryData && !messageHistoryData.length && (
                  <div className="submain-heading">
                    {STRINGS.CURRENTLY_NO_ITEMS_TO_DISPLAY}
                  </div>
                )}
              </FormPrimaryHeader>
              <HighlightMessage className="d-flex">
                <Icon icon={'refresh_ccw_02'} className="cursor-pointer" />
                <span className="ms-2">
                  <span
                    tabIndex={0}
                    className="text-decoration-underline cursor-pointer"
                    onClick={() => getHistoryData()}
                    onKeyDown={() => {
                      getHistoryData();
                    }}
                  >
                    {STRINGS.REFRESH_PAGE}
                  </span>
                  {STRINGS.PERIODICALLY_VIEW_INFORMATION}
                </span>
              </HighlightMessage>
              <div className="message-history-tabs">
                {tabs.map((tab, index: number) => (
                  <TabItems
                    key={index}
                    tabIndex={0}
                    className="text-nowrap"
                    onClick={() => {
                      setSelectedTab(tab.value);
                      sessionStorage.setItem('message_type', tab.value);
                    }}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        setSelectedTab(tab.value);
                        sessionStorage.setItem('message_type', tab.value);
                      }
                    }}
                    selectedtab={selectedTab === tab.value}
                  >
                    {tab.label}
                  </TabItems>
                ))}
              </div>
              <UserRegistrationForm className="bg-transparent flex-grow-1">
                {messageHistoryData && messageHistoryData.length ? (
                  <div className="form-body">
                    {messageHistoryData.map((message: any, index: number) => {
                      const formattedDateTime = getFormattedDateAndTimeDynamic(
                        message.created
                      );
                      return (
                        message.add_to_website &&
                        (!isMobileView ? (
                          <MessageCards
                            key={index}
                            onClick={() => showFullMessage(index)}
                            className={
                              selectedMessageIndex.selectedMessageIndex ===
                                index && selectedMessageIndex.messageOpenState
                                ? 'active-message-card'
                                : undefined
                            }
                          >
                            <div className="overflow-auto d-flex align-items-center">
                              <div className="d-flex overflow-auto flex-grow-1 flex-column flex-sm-row justify-content-between">
                                <Lozenge
                                  variant="lowemphasis"
                                  type="warning"
                                  text={message.message_type_name}
                                />
                                {!(
                                  selectedMessageIndex.selectedMessageIndex ===
                                  index
                                ) &&
                                  selectedMessageIndex.messageCloseState && (
                                    <div className="message-history-text px-3 text-truncate">
                                      <span className="text-truncate">
                                        {message.message_body}
                                      </span>
                                    </div>
                                  )}
                              </div>
                              <div className="d-flex gap-2 align-items-center">
                                <span className="message-formatted-time">
                                  {formattedDateTime}
                                </span>
                                <div
                                  onClick={() => messageClose(index)}
                                  data-testid="closeMessageIcon"
                                >
                                  {selectedMessageIndex.selectedMessageIndex ===
                                    index &&
                                    selectedMessageIndex.messageOpenState && (
                                      <Icon
                                        icon="close"
                                        width={18}
                                        height={18}
                                        cursor="pointer"
                                      />
                                    )}
                                </div>
                              </div>
                            </div>
                            {selectedMessageIndex.selectedMessageIndex ===
                              index &&
                              selectedMessageIndex.messageOpenState && (
                                <div className="message-history-text pt-3 text-break">
                                  {message.message_body}{' '}
                                </div>
                              )}
                          </MessageCards>
                        ) : (
                          <MessageCards
                            key={index}
                            onClick={() => showFullMessage(index)}
                            className={
                              selectedMessageIndex.selectedMessageIndex ===
                                index && selectedMessageIndex.messageOpenState
                                ? 'active-message-card'
                                : undefined
                            }
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <Lozenge
                                variant="lowemphasis"
                                type="warning"
                                text={message.message_type_name}
                              />
                              <div className="d-flex align-items-center justify-content-between gap-2">
                                <span className="message-formatted-time">
                                  {formattedDateTime}
                                </span>
                                <div onClick={() => messageClose(index)}>
                                  {selectedMessageIndex.selectedMessageIndex ===
                                    index &&
                                    selectedMessageIndex.messageOpenState && (
                                      <Icon
                                        icon="close"
                                        width={18}
                                        height={18}
                                        cursor="pointer"
                                      />
                                    )}
                                </div>
                              </div>
                            </div>
                            <div>
                              {selectedMessageIndex.messageCloseState && (
                                <div className="message-history-text text-truncate pt-3">
                                  <span className="text-truncate">
                                    {selectedMessageIndex.messageCloseState &&
                                      message.message_body}
                                  </span>
                                </div>
                              )}
                              {selectedMessageIndex.selectedMessageIndex ===
                                index &&
                                selectedMessageIndex.messageOpenState && (
                                  <div className="message-history-text pt-3 text-break">
                                    {message.message_body}
                                  </div>
                                )}
                            </div>
                          </MessageCards>
                        ))
                      );
                    })}
                  </div>
                ) : (
                  <>
                    {isFetchingMessageHistoryData ? (
                      <PALoader />
                    ) : (
                      <div className="d-flex justify-content-center flex-grow-1">
                        <NoDataFound
                          src={NoMessagesData}
                          description={STRINGS.NO_MESSAGES}
                          alt="no-messages"
                        />
                      </div>
                    )}
                  </>
                )}
              </UserRegistrationForm>
            </div>
            <div className="gap-3 d-flex flex-column align-items-center">
              <Button
                {...btnStyles.secondary}
                onClick={onBackClick}
                data-testid="goBackToClosureStatusPageButton"
              >
                {STRINGS.GO_BACK_TO_CLOSURES_STATUS_PAGE}
              </Button>
              <span
                className="navigation-link"
                onClick={termsConditions}
                aria-label="Policy,Terms and Conditions"
                tabIndex={0}
                role="link"
              >
                {STRINGS.TERMS_AND_CONDITIONS_POLICY}
              </span>
            </div>
          </div>
        </div>
      )}
      {showTermsAndConditions && (
        <TermsAndConditions onBack={onTermsAndConditionsBackClick} />
      )}
    </PublicViewContainerStyles>
  );
};

export default MessageHistory;
