import {
  FormPrimaryHeader,
  HighlightMessage,
  UserRegistrationForm
} from './styles';
import { Button } from '../../Universal/NovusDSImports';
import { btnStyles } from '../../Universal/NovusDSImports/variants';
import { STRINGS } from '../../Constants/ConstantStrings';
import { isProdInstance, isUatInstance } from '../../settings';

interface TermsAndConditionsProps {
  onBack: () => void;
}

const adminMobileNumber = isProdInstance
  ? '+18449863923'
  : isUatInstance
  ? '+18337920047'
  : '+18447176060';

export default function TermsAndConditions({
  onBack
}: TermsAndConditionsProps) {
  return (
    <div
      className="container align-items-center justify-content-between"
      data-testid="termsAndConditionsPage"
    >
      <div className="gap-4 d-flex flex-column overflow-auto">
        <FormPrimaryHeader className="text-lg-nowrap">
          <div className="form-main-heading">
            {STRINGS.TERMS_AND_CONDITIONS}
          </div>
        </FormPrimaryHeader>
        <HighlightMessage>
          {STRINGS.THIS_PAGE_IS_FOR_TECHINICAL}
        </HighlightMessage>
        <UserRegistrationForm>
          <div className="form-body gap-4">
            <h1>1. DESCRIPTION OF THE SERVICE:</h1>
            <p>
              The Commonwealth of PA Emergency Traveler Assistance System (511
              PAConnect) establishes bi-directional communications with the
              public during an emergency or event. It allows Pennsylvania state
              government agencies (AGENCIES) to use a variety of mechanisms to
              both distribute and receive information about users in a targeted
              geographic area.
            </p>
            <p>
              While the AGENCIES will at all times attempt to provide accurate
              and timely information, and this service will attempt to deliver
              the information in a timely manner, there is no guarantee of its
              accuracy or timeliness. Use of this system is voluntary.
            </p>
            <h1>2. LOCATION SERVICES:</h1>
            <p>
              When using the location service, we will request your permission
              to obtain your location through your mobile phone’s browser. Your
              location information will be used so that AGENCIES can be better
              prepared to respond appropriately to the situation at hand, and
              communicate location-relevant messages back to registered users.
              You may receive a notice from your browser saying that this site
              wants to know your location. If you grant permission, the agency
              may be aware of your approximate location at the time you grant
              your permission. From time to time during the course of this
              event, the agency may request an update to your location.
            </p>
            <h2>PRIVACY INFORMATION:</h2>
            <p>
              Your privacy is important to us and so this service uses only
              secure HTTPS communication with SSL Encryption to obtain data.
              When using this service, your location will be used by AGENCIES to
              gather information from you that will help the AGENCIES to respond
              appropriately to the situation at hand, and to communicate back to
              you with location-relevant messages to assist you throughout the
              event. Access to user location data during the event is restricted
              to authenticated users from Pennsylvania state government
              agencies. Your location data will be stored for the duration of
              the event (up to ten days), after which you will be automatically
              opted out of all services, and personally identifying information
              (your phone number) will be removed from the database.
              Un-identifiable location data may be retained for the purpose of
              after-action reviews and audits.
            </p>
            <h2>SHARING INFORMATION:</h2>
            <p>
              Your location data (latitude, longitude and accuracy estimate) is
              shared with Pennsylvania state government agencies which may
              include PennDOT, the Pennsylvania Turnpike Commission, the
              Pennsylvania State Police, the Pennsylvania Emergency Management
              Agency, and other approved agencies for the purpose of directing
              emergency response. Your location data will not be shared with any
              other parties or used for any other purpose than its intended use.
              All location data is obtained using only secure HTTPS
              communication with SSL Encryption. AGENCIES access to the data is
              protected with multiple authentication protocols. Access to user
              location data during the event is restricted to authenticated
              users from Pennsylvania state government agencies.
            </p>
            <h1>3. SMS SERVICES</h1>
            <p>
              When using this service, we will be sending text messages to your
              mobile phone so that AGENCIES can communicate updates and safety
              information messages to registered users. Your consent to opt-in
              to the texting service will be obtained using an SMS text message
              or IVR telephone-based process, depending on the preference you
              indicate when registering with the event. If SMS text messaging is
              selected, you will be sent a text message that confirms your
              consent to be contacted via text messages. If the IVR
              telephone-based option is selected, you will receive a phone call
              confirming your registration.
            </p>
            <p>
              Please note, when opting for these recurring SMS text messages,
              <b>
                {' '}
                message and data rates may apply. Go to{' '}
                <a
                  href={
                    isProdInstance
                      ? 'https://paconnect.511pa.com'
                      : isUatInstance
                      ? 'https://paconnect-t.511pa.com'
                      : 'https://paconnect.dev.ibidigital.com'
                  }
                >
                  {isProdInstance
                    ? 'https://paconnect.511pa.com'
                    : isUatInstance
                    ? 'https://paconnect-t.511pa.com'
                    : 'https://paconnect.dev.ibidigital.com'}{' '}
                </a>
                to learn more about the service. You can Reply UNREGISTER to{' '}
                {adminMobileNumber} to cancel. Carriers are not liable for
                delayed or undelivered messages. Please Call 911 if you are
                having an emergency.
              </b>
            </p>
            <p>
              <b>
                When using this service, you can opt-out at any time by texting
                UNREGISTER to {adminMobileNumber}.
              </b>{' '}
              You will not receive further texts after this unless you register
              to use the service again.
            </p>
            <p>
              <b>
                You will be automatically opted out of the service after the
                event(s) for which you registered has/have been closed.{' '}
              </b>
              You will not receive further texts after this unless you register
              to use the service again.
            </p>
            <h2>PRIVACY INFORMATION:</h2>
            <p>
              Your privacy is important to us. When using this service, your
              phone number will be used by AGENCIES in order to provide
              information regarding the event, and to provide supplementary
              safety information to assist you throughout the event. Access to
              this system during the event is restricted to authenticated users
              from Pennsylvania state government agencies. Your text message
              data will be stored for the duration of the event (up to ten
              days), after which you will be automatically opted out of texting
              services.
            </p>
            <p>
              We do not share your information with third parties other than for
              the purpose of providing this service. We may disclose information
              about our users, including personally identifiable information:
              (i) when requested by governmental or quasi-governmental
              authorities conducting an investigation; (ii) if required to do so
              by subpoena, court order or legal process; (iii) to verify or
              enforce compliance with the policies governing our service and
              applicable laws; or (iv) to protect against misuse or unauthorized
              use of our services.
            </p>
            <h2>SHARING INFORMATION:</h2>
            <p>
              Text messages and phone numbers are shared with Pennsylvania state
              government agencies which may include PennDOT, the Pennsylvania
              Turnpike Commission, the Pennsylvania State Police, the
              Pennsylvania Emergency Management Agency, and other approved
              agencies for the purpose of directing emergency response. Your
              data will not be shared with any other parties or used for any
              other purpose than its intended use. AGENCIES access to the data
              is protected with multiple authentication protocols. Access to
              user data during the event is restricted to authenticated users
              from Pennsylvania state government agencies.
            </p>
            <h1>4. 511 PA Connect Services</h1>
            <h2>USER CONDUCT:</h2>
            <p>
              You acknowledge and agree to the following with respect to use of
              Electronic Communications through these Services:
            </p>
            <p>
              You will not use any Electronic Communication for any purpose that
              is unlawful, abusive, harassing, libelous, defamatory, obscene or
              threatening;
            </p>
            <p>
              You may not knowingly restrict or inhibit any other user from
              using the Service;
            </p>
            <p>
              You may not impersonate any other person or entity, or
              misrepresent your affiliation with any other person or entity;
            </p>
            <p>
              You may not knowingly post or transmit any information or software
              which contains a virus, trojan horse, worm or other harmful
              component;
            </p>
            <p>
              You will not in any way express or imply that any opinions
              contained in your Electronic Communications are endorsed by 511PA
              Connect, or that the accuracy of any facts or circumstances
              described in your Electronic Communications have been verified by
              511PA Connect;
            </p>
            <p>
              You may not use the Service in any way for sending Spam. Spam is
              defined here as unsolicited communications directed to people not
              personally known to you, including but not limited to junk email,
              junk phone messages, junk text messages or any communication sent
              that 511PA Connect reasonably believes constitutes Spam, based on
              applicable laws and industry practices.
            </p>
            <h2>ACCEPTABLE USE:</h2>
            <h2>Prohibited Uses</h2>
            <p>
              You may use the Service only for lawful purposes and in accordance
              with this acceptable use policy. You may not:
            </p>
            <p>
              Use the Service in any way that violates any applicable federal,
              state, local or international law or regulation (including,
              without limitation, any laws regarding the export of data or
              software to and from the US or other countries).
            </p>
            <p>
              Use the Service for the purpose of exploiting, harming or
              attempting to exploit or harm minors in any way by exposing them
              to inappropriate content, asking for personally identifiable
              information, or otherwise.
            </p>
            <p>
              Use the Service to transmit, or procure the sending of, any
              advertising or promotional material, including any “junk email”,
              “junk text”, “junk phone messages”, “spam” or any other similar
              solicitation.
            </p>
            <p>
              Impersonate or attempt to impersonate 511PA Connect, a 511PA
              Connect employee, another user or any other person or entity,
              including by utilizing another user’s identification, password,
              account name or persona without authorization from that user.
            </p>
            <p>
              Use the Service in any manner that could disrupt, disable,
              overburden, damage, or impair the Service for you or others
              (including the ability to send timely notifications through the
              Service), via various means including overloading, “flooding,”
              “mailbombing,” “denial of service” attacks, or “crashing”.
            </p>
            <p>
              Use any robot, spider or other automatic device, process or means
              to access the Service for any purpose.
            </p>
            <p>
              Use any manual process to monitor or copy any of the material made
              available through the Service or for any other unauthorized
              purpose without prior written consent.
            </p>
            <p>
              Use any device, software or routine, including but not limited to,
              any viruses, trojan horses, worms, or logic bombs, that interfere
              with the proper working of the Service or could be technologically
              harmful.
            </p>
            <p></p>
            Attempt to gain unauthorized access to, interfere with, damage or
            disrupt any parts of the Service, the server on which the Service is
            stored, or any server, computer or database connected to the
            Service.
            <p>
              Attempt to probe, scan or test the vulnerability of a system or
              network or to breach security or authentication measures without
              511PA Connect’s express written consent.
            </p>
            <p>
              Take any action in order to obtain services to which you are not
              entitled.
            </p>
            <p>
              Attempt any action designed to circumvent or alter any method of
              measuring utilization of the Service.
            </p>
            <p>
              Otherwise attempt to interfere with the proper working of the
              Service.
            </p>
            <h2>SHARING INFORMATION:</h2>
            <p>
              Any information which is kept in 511PA Connect custody, is kept
              confidential and 511PA Connect will take reasonable and
              appropriate steps to protect the information that you share with
              us from unauthorized access or disclosure.
              <u>
                <b>
                  511PA Connect does not sell, rent or lease information to
                  third parties{' '}
                </b>
                unless compelled to do so by a court of competent jurisdiction.
              </u>
            </p>
            <p>
              511PA Connect welcomes your comments regarding this Privacy Policy
              and Information Sharing Policy or the Services 511PA Connect
              provides. If you have any questions, please contact 511PA Connect
              by e-mail, or postal mail.
            </p>
            <h2>
              <h5>Toronto</h5>
              <br />
              Arcadis
              <br />
              7th Floor,
              <br />
              55 St. Clair Avenue West,
              <br />
              M4V 2Y7
              <br />
              Toronto
              <br />
              Ontario
            </h2>
            <h2>DISCLAIMER OF WARRANTIES:</h2>
            <p>
              We make no representations or warranties, express or implied,
              including, to the extent permitted by applicable law, any implied
              warranty of merchantability or fitness for a particular purpose,
              about this Service.
            </p>
            <h2>LIMITATION OF LIABILITY AND GOVERNING LAW:</h2>
            <p>
              511PA Connect and AGENCIES will not be liable for any damages that
              users may incur, including special, indirect, incidental or
              consequential damages. IN NO WAY WILL 511PA Connect and AGENCIES
              BE HELD LIABLE FOR INCOMPLETE OR INACCURATE INFORMATION CONTAINED
              IN ANY OF THE ALERTS. THIS SERVICE IS NOT INTENDED TO BE USED FOR
              DECISION-MAKING PURPOSES AND SHOULD BE USED FOR INFORMATIONAL
              PURPOSES ONLY. The user hereby releases, remises, acquits, and
              forever discharges 511PA Connect and AGENCIES from any and all
              claims, demands, and causes of action, known or unknown, that the
              user had, have, or may have against 511PA Connect and/or AGENCIES
              arising out of, or related to, the use of the services described
              herein.
            </p>
            <p>
              Any controversy or claim arising out of or relating to the use of
              the services provided by 511PA Connect as described herein, or
              arising out of or relating to the terms of these Terms and
              Conditions, shall be settled by arbitration administered by the
              American Arbitration Association in accordance with its applicable
              Commercial Arbitration Rules, and judgment on the award rendered
              by the arbitrator(s) may be entered in any court having
              jurisdiction thereof. The arbitration shall be conducted before
              one (1) arbitrator and shall be conducted in Camden County, New
              Jersey. The cost of the arbitration shall be shared equally by the
              parties.
            </p>
            <p>
              These Terms and Conditions shall be governed by and interpreted in
              accordance with the laws of the Commonwealth of Pennsylvania.
            </p>
            <h2>RIGHTS AND REMEDIES:</h2>
            <p>
              If any user becomes aware of any content or activity that violates
              these Terms and Conditions, the user shall take all necessary
              action to prevent such content from being routed to, passed
              through, or stored on the 511PA Connect network and shall promptly
              notify 511PA Connect. Failure to comply may result in 511PA
              Connect taking action anywhere from a warning, to a suspension or
              termination of Service. 511PA Connect will endeavor to provide
              notice prior to any suspension or termination of Service, but may
              immediately suspend or terminate in instances where continued
              provision of Service may cause significant harm to 511PA Connect,
              the Service or other users.
            </p>
            <p>CHANGES TO THE TERMS OF USE</p>
            <p>
              511PA Connect reserves the right to modify these Terms and
              Conditions from time-to-time, in its sole discretion. Any use of
              511PA Connect network and Services after such modification shall
              constitute acceptance of such modification.
            </p>
            <h2>CONTACT INFORMATION:</h2>
            <p>
              For emergencies dial 911. For more information or help with this
              service, please email us at http://511pa.com/contact.
            </p>
            <p>Terms & Conditions updated May 30, 2024.</p>
          </div>
        </UserRegistrationForm>
      </div>
      <div>
        <Button
          {...btnStyles.secondary}
          onClick={onBack}
          data-testid="goBackToPreviousPageButton"
        >
          {STRINGS.GO_BACK_TO_PREVIOUS_PAGE}
        </Button>
      </div>
    </div>
  );
}
