import axios from 'axios';
import Cookies from 'js-cookie';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './App.css';
import ApplicationContainer from './ApplicationContainer';
import {
  clearCookiesAndLocalstorage,
  safeDecodeJWTToken,
  setTableRowLimitFromElementId
} from './CommonUtilities/CommonUtilities';
import { api_prefix } from './Constants/constants';
import { Helmet } from 'react-helmet';
import GlobalRouter from './Router/GlobalRouter';
import { getCurrentUser, setIsMobileScreen } from './Store/reducers/Common';
import { useReduxDispatch, useReduxSelector } from './Store/reduxHooks';
import { device } from './Universal/Foundation';
import { SECURE, isPublicView } from './settings';
import { RootState } from './store';
import { logoutRequest } from './Store/reducers/Login';
import { getClientInfoFetch } from './Store/reducers/ClientInfo';
import PALoader from './SharedComponets/PALoader';

declare global {
  interface Window {
    googleTranslateElementInit?: () => void;
    google: any;
  }
}

function App() {
  // const [isFetchingRefreshToken, setIsFetchingRefreshToken] = useState(false);
  const loginData = useSelector((state: RootState) => state.Login.loginData),
    isLoggedOut = useSelector((state: RootState) => state.Login.isLoggedOut),
    dispatch = useReduxDispatch();

  const { clientInfoReceived, client_name } = useReduxSelector(
    (state: RootState) => state.ClientInfo
  );

  const checkScreenWidth = useCallback(() => {
    const mediaQuery = window.matchMedia(`${device.mobileL}`);
    dispatch(
      setIsMobileScreen({
        isMobileScreen: mediaQuery.matches
      })
    );
  }, [dispatch]);

  const addEventListeners = useCallback(() => {
    window.addEventListener('resize', () => {
      checkScreenWidth();
    });
    window.addEventListener('storage', (e) => {
      if (e.key === 'isLoggedIn' && !e.newValue) {
        dispatch(logoutRequest({}));
      }
    });
  }, [checkScreenWidth, dispatch]);

  const fetchNewSetOfTokens = useCallback(
    async (showLoader = false) => {
      if (showLoader) {
        // setIsFetchingRefreshToken(true);
      }

      await axios
        .post(`${api_prefix}refresh/`, {
          refresh: Cookies.get('refresh')
        })
        .then((response) => {
          const tokenData = response.data;
          const CookiesData: any = Cookies.get('access');
          const decodedData = safeDecodeJWTToken(CookiesData);
          const refreshTokenDecoded = safeDecodeJWTToken(
            Cookies.get('refresh') || ''
          );
          const date = new Date(refreshTokenDecoded.exp * 1000); // Multiply by 1000 to convert seconds to milliseconds
          const formattedDate = new Date(date.toUTCString());
          if (decodedData?.exp) {
            Cookies.set('access', tokenData.access, {
              expires: formattedDate,
              secure: SECURE
            });
          }
          Cookies.set('refresh', tokenData.refresh, {
            expires: formattedDate,
            secure: SECURE
          });
          if (showLoader) {
            // setIsFetchingRefreshToken(false);
            if (!isPublicView) {
              dispatch(
                getCurrentUser({
                  fields:
                    'fullname,email,user_permission_set,phone_number,profile_image'
                })
              );
            }
          }
        })
        .catch(() => {
          clearCookiesAndLocalstorage();
        });
    },
    [dispatch]
  );

  const setRefreshTokenTimeOut = useCallback(
    (decoded: any) => {
      let time = decoded.exp * 1000 - new Date().getTime();
      time = time - 600000;
      setTimeout(() => {
        fetchNewSetOfTokens();
      }, time);
    },
    [fetchNewSetOfTokens]
  );

  const decodeToken = useCallback(() => {
    const accessToken = Cookies.get('access');
    if (accessToken) {
      const decoded = safeDecodeJWTToken(accessToken);
      const refreshTokenDecoded = safeDecodeJWTToken(
        Cookies.get('refresh') || ''
      );
      const date = new Date(refreshTokenDecoded.exp * 1000); // Multiply by 1000 to convert seconds to milliseconds
      const formattedDate = new Date(date.toUTCString());
      if (decoded.mfa_verified) {
        Cookies.set('isValidated', 'true', {
          expires: formattedDate,
          secure: SECURE
        });
        setRefreshTokenTimeOut(decoded);
        if (!isPublicView) {
          dispatch(
            getCurrentUser({
              fields:
                'fullname,email,user_permission_set,phone_number,profile_image'
            })
          );
        }
      }
    }
  }, [dispatch, setRefreshTokenTimeOut]);

  const checkIfAccessTokenIsPresentOnMount = useCallback(() => {
    const accessToken = Cookies.get('access');
    if (accessToken) {
      const decoded = safeDecodeJWTToken(accessToken);
      let diff = decoded.exp * 1000 - new Date().getTime();
      if (diff > 0) {
        if (localStorage.getItem('isLoggedIn')) {
          diff = diff - 600000; // checking 10min before original value
          // diff = diff - 600000; // checking 1min before for testing
          if (diff < 600000) {
            fetchNewSetOfTokens();
          } else {
            setTimeout(() => {
              fetchNewSetOfTokens();
            }, diff);
          }
          if (!isPublicView) {
            dispatch(
              getCurrentUser({
                fields:
                  'fullname,email,user_permission_set,phone_number,profile_image'
              })
            );
          }
        } else {
          // logging out instead of clearing state becuase it's infinitely calling this logoutRequest
          dispatch(logoutRequest({}));
        }
      } else {
        fetchNewSetOfTokens(true);
      }
    }
  }, [dispatch, fetchNewSetOfTokens]);

  useEffect(() => {
    setTableRowLimitFromElementId('App');
  }, []);

  useEffect(() => {
    checkIfAccessTokenIsPresentOnMount();
    addEventListeners();
    checkScreenWidth();
  }, [addEventListeners, checkIfAccessTokenIsPresentOnMount, checkScreenWidth]);

  useEffect(() => {
    dispatch(getClientInfoFetch({}));
  }, [dispatch]);

  useEffect(() => {
    if (loginData) {
      decodeToken();
    }
  }, [decodeToken, loginData]);

  return !clientInfoReceived ? (
    <div className="d-flex justify-content-center align-items-center min-vh-100">
      {' '}
      <PALoader />{' '}
    </div>
  ) : (
    <div className="App" id="App">
      <Helmet>{client_name && <title>{client_name}</title>}</Helmet>
      {Cookies.get('access') &&
      safeDecodeJWTToken(Cookies.get('access') || '').mfa_verified &&
      Cookies.get('refresh') &&
      !isLoggedOut &&
      localStorage.getItem('isLoggedIn') ? (
        <ApplicationContainer />
      ) : (
        <GlobalRouter />
      )}
    </div>
  );
}

export default withRouter(App);
