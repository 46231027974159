import { lazy, useCallback, useEffect, useState } from 'react';
import { STRINGS } from '../../Constants/ConstantStrings';
import { Badge, Button, CheckBox, Icon } from '../../Universal/NovusDSImports';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import {
  getMessagesData,
  messagesDataReceived,
  setActiveStatus,
  setNewMessage
} from '../../Store/reducers/Messages';
import { RootState } from '../../store';
// import Card from '../../SharedComponets/Card';
// import ComposeMessage from '../Closures/ComposeMessage';
// import ChatBox from './ChatBox';
import {
  getChatHistory,
  markSelectedMessagesAsRead
} from '../../Store/reducers/RegisteredUsers';
import dayjs from 'dayjs';
import { TabItems, TableWrapper } from '../globalStyles';
import { colorState } from '../../Universal/Foundation';
import TableTitle from '../../SharedComponets/TableTitle';
import {
  btnStyles,
  checkBoxStyles
} from '../../Universal/NovusDSImports/variants';
import { MessageStyles } from './styles';
import {
  getPermission,
  removeEventManagedByLabel
} from '../../CommonUtilities/CommonUtilities';
// import PALoader from '../../SharedComponets/PALoader';
// import NoDataFound from '../../SharedComponets/NoDataFound';
import { NoManageUsersIcon } from '../../Universal/Assets';
import PALoader from '../../SharedComponets/PALoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MessagesTableRowLimit } from '../../Constants/constants';
import usePrevious from '../../Hooks/UsePrevious';

const Card = lazy(() => import('../../SharedComponets/Card'));
const ComposeMessage = lazy(() => import('../Closures/ComposeMessage'));
const ChatBox = lazy(() => import('./ChatBox'));
const NoDataFound = lazy(() => import('../../SharedComponets/NoDataFound'));

const Messages = () => {
  const messagesData = useReduxSelector(
      (state: RootState) => state.Messages.messagesData
    ),
    selectedClosure = useReduxSelector(
      (state: RootState) => state.Common.selectedClosure
    ),
    isFetchingMessagesData = useReduxSelector(
      (state: RootState) => state.Messages.isFetchingMessagesData
    ),
    newMessage = useReduxSelector(
      (State: RootState) => State.Messages.newMessage
    ),
    currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    closuresUnreadMessagesCount = useReduxSelector(
      (state: RootState) => state.Closures.closuresUnreadMessagesCount
    ),
    isActiveStatusChanged = useReduxSelector(
      (state: RootState) => state.Messages.isActiveStatusChanged
    ),
    [selectedUsers, setSelectedUsers] = useState<any>(null),
    [isSelectAll, setIsSelectAll] = useState<boolean>(false),
    [selectedChat, setSelectedChat] = useState<any>(null),
    [selectedMessages, setSelectedMessages] = useState<any>([]),
    [isComposeMessage, setIsComposeMessage] = useState<boolean>(false),
    [activeTabName, setActiveTabName] = useState<any>('All Messages'),
    [isLoadingInitialData, setIsLoadingInitialData] = useState<boolean>(true),
    previousIsFetchingMessagesData = usePrevious(isFetchingMessagesData),
    dispatch = useReduxDispatch();

  useEffect(() => {
    if (
      previousIsFetchingMessagesData &&
      !isFetchingMessagesData &&
      isLoadingInitialData
    ) {
      setIsLoadingInitialData(false);
    }
  }, [
    isFetchingMessagesData,
    isLoadingInitialData,
    previousIsFetchingMessagesData
  ]);

  useEffect(() => {
    setSelectedChat(null);
    setActiveTabName('All Messages');
    if (selectedClosure && getPermission(currentUser, 'view_unread_messages')) {
      dispatch(
        getMessagesData({
          closureId: selectedClosure?.id,
          unRead: false,
          limit: MessagesTableRowLimit,
          offset: 0
        })
      );
    }
  }, [dispatch, selectedClosure, currentUser]);

  useEffect(() => {
    if (selectedClosure) {
      setIsSelectAll(false);
      setSelectedUsers(null);
    }
  }, [selectedClosure]);

  useEffect(() => {
    dispatch(
      getMessagesData({
        closureId: selectedClosure?.id,
        unRead: activeTabName === 'unread Messages',
        limit: MessagesTableRowLimit,
        offset: 0
      })
    );
  }, [activeTabName]);

  useEffect(() => {
    if (newMessage) {
      let data = JSON.parse(JSON.stringify(messagesData));
      let isNewUser = true;
      data &&
        Object.keys(data)?.forEach((user: any) => {
          if (
            data[user]?.id === JSON.parse(newMessage.data)?.Meta?.reg_user_id
          ) {
            isNewUser = false;
            data[user].last_message = JSON.parse(
              newMessage.data
            )?.BODY?.message;
            data[user].unread_messages += 1;
            data[user].last_message_time = dayjs();
          }
        });
      if (isNewUser) {
        data[JSON.parse(newMessage.data)?.Meta?.phone_number] = {
          id: JSON.parse(newMessage.data)?.Meta?.reg_user_id,
          last_message: JSON.parse(newMessage.data)?.BODY?.message,
          unread_messages: 1,
          last_message_time: dayjs()
        };
      }
      data.total_unread += 1;
      data.total_users_count += 1;
      dispatch(
        messagesDataReceived({ messagesData: data, shouldAppend: false })
      );
      if (
        selectedChat &&
        selectedChat.id === JSON.parse(newMessage.data)?.Meta?.reg_user_id &&
        getPermission(currentUser, 'view_chatmessage')
      ) {
        dispatch(getChatHistory({ userId: selectedChat.id }));
      }
      dispatch(setNewMessage({ newMessage: null }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessage]);

  useEffect(() => {
    //Taking everything to default state when any user gets unregistered
    if (isActiveStatusChanged) {
      setActiveTabName('All Messages');
      setIsComposeMessage(false);
      setSelectedChat(null);
      setSelectedUsers(null);
      setIsSelectAll(false);
      setSelectedMessages([]);
    }
  }, [isActiveStatusChanged]);

  useEffect(() => {
    if (selectedChat && !isActiveStatusChanged) {
      messagesData &&
        Object.keys(messagesData)?.map((user: any) => {
          if (
            user !== 'total_unread' &&
            user !== 'total_users_count' &&
            messagesData[user].id === selectedChat.id
          ) {
            setSelectedChat(messagesData[user]);
          }
        });
    } else if (
      messagesData &&
      Object.keys(messagesData)?.length &&
      isActiveStatusChanged &&
      !selectedChat
    ) {
      dispatch(setActiveStatus({ isActiveStatusChanged: false }));
    }
  }, [messagesData, isActiveStatusChanged, selectedChat]);

  useEffect(() => {
    if (selectedUsers === null && isComposeMessage) {
      setIsComposeMessage(false);
    }
  }, [selectedUsers, isComposeMessage]);

  const getMessagesCount = useCallback(() => {
    let count = 0;
    messagesData &&
      Object.keys(messagesData)?.forEach((user: any) => {
        if (messagesData[user].is_active) count += 1;
      });
    return count;
  }, [messagesData]);

  useEffect(() => {
    setSelectedMessages([]);
  }, [selectedChat]);

  useEffect(() => {
    setIsSelectAll(false);
  }, [messagesData]);

  const handleSelectAllClick = () => {
    setSelectedMessages([]);
    if (isSelectAll) {
      setSelectedUsers(null);
      setIsSelectAll(false);
    } else {
      let users: any = {};
      messagesData &&
        Object.keys(messagesData)?.forEach((user: any) => {
          if (
            user !== 'total_unread' &&
            user !== 'total_users_count' &&
            messagesData[user].is_active
          ) {
            // if (!(isUnreadMessages && !messagesData[user].unread_messages))
            users[messagesData[user].id] = true;
          }
        });
      setIsSelectAll(Object.keys(users).length ? true : false);
      setSelectedUsers(users);
    }
  };

  const handleSelectClick = (user: any) => {
    let users = { ...selectedUsers };
    setSelectedMessages([]);

    if (selectedUsers?.[user.id]) {
      delete users[user.id];
      if (users && Object.keys(users).length !== getMessagesCount())
        setIsSelectAll(false);
    } else {
      users[user.id] = true;
      if (users && Object.keys(users).length === getMessagesCount())
        setIsSelectAll(true);
    }
    setSelectedUsers(users);
  };

  const handleComposeMessageClose = () => {
    setIsComposeMessage(false);
    setSelectedUsers(null);
    setIsSelectAll(false);
  };

  const getDateAndTime = (time: any) => {
    if (!dayjs(time).diff(dayjs(), 'd')) {
      return dayjs(time).format('hh:mm A');
    } else if (dayjs(time).diff(dayjs(), 'd') === 1) {
      return 'Yesterday';
    } else {
      return dayjs(time).format('MM/DD/YYYY');
    }
  };

  const getIndeterminateState = () => {
    let isIndeterminate = false;
    //if there are selected users and the selected user count is not equal to total number of users, then the checkbox goes to indeterminate state
    //if UnreadMessages tab is selected, then the selected user count is compared with number of users in that tab (getunreadmessagescount())
    if (
      selectedUsers &&
      Object.keys(selectedUsers)?.length &&
      Object.keys(selectedUsers).length !== getMessagesCount()
    )
      isIndeterminate = true;
    return isIndeterminate;
  };

  const getNextSetOfData = () => {
    dispatch(
      getMessagesData({
        closureId: selectedClosure?.id,
        unRead: activeTabName === 'unread Messages',
        limit: MessagesTableRowLimit,
        offset: messagesData && Object.keys(messagesData)?.length - 2,
        shouldAppend: true
      })
    );
  };

  const renderSectionBody = () => {
    return (
      <div className="h-100 w-100 d-flex flex-column">
        <div className="select-all-wrapper">
          {messagesData && Object.keys(messagesData)?.length > 2 && (
            <Button
              role="button"
              id={`select-all-button`}
              tabIndex={0}
              aria-label="select-all-button"
              onClick={handleSelectAllClick}
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  handleSelectAllClick();
                }
              }}
              disabled={!getMessagesCount()}
              {...btnStyles.tertiary}
            >
              <CheckBox
                selected={isSelectAll}
                onClick={handleSelectAllClick}
                onKeyDown={(e: any) => {
                  if (e.key === ' ') {
                    handleSelectAllClick();
                  }
                }}
                indeterminate={getIndeterminateState()}
                {...checkBoxStyles}
                className="me-2"
              />
              {isSelectAll ? STRINGS.CLEAR_ALL : STRINGS.SELECT_ALL}
            </Button>
          )}
        </div>
        {messagesData && Object.keys(messagesData)?.length > 2 ? (
          <div className="table-messages" id="table-messages-scroll">
            <InfiniteScroll
              dataLength={Object.keys(messagesData).length - 2}
              style={{ overflow: 'unset' }}
              next={getNextSetOfData}
              hasMore={
                Object.keys(messagesData).length - 2 <
                messagesData.total_users_count
              }
              loader={<PALoader />}
              scrollableTarget="table-messages-scroll"
            >
              {messagesData &&
                Object.keys(messagesData)?.map((user: any) => {
                  if (user === 'total_unread' || user === 'total_users_count')
                    return <></>;
                  return (
                    <div
                      tabIndex={0}
                      className={`${
                        selectedChat?.id === messagesData[user]?.id &&
                        'selected-message'
                      } ${
                        !messagesData[user].is_active && 'disabled'
                      } messages-wrapper`}
                      onClick={() => {
                        if (messagesData[user].is_active)
                          setSelectedChat(messagesData[user]);
                      }}
                      onKeyDown={(event: any) => {
                        if (
                          event.key === 'Enter' &&
                          messagesData[user].is_active
                        ) {
                          setSelectedChat(messagesData[user]);
                        }
                      }}
                    >
                      <div className="left-wrapper">
                        <CheckBox
                          tabIndex={0}
                          onKeyDown={(event: any) => {
                            if (event.key === ' ') {
                              handleSelectClick(messagesData[user]);
                            }
                          }}
                          selected={selectedUsers?.[messagesData[user]?.id]}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectClick(messagesData[user]);
                          }}
                          disabled={!messagesData[user]?.is_active}
                          {...checkBoxStyles}
                        />
                        <div
                          aria-label="chat-history"
                          className="align-items-center"
                        >
                          <div className="d-flex">
                            <h1
                              className={
                                messagesData[user].unread_messages
                                  ? 'unread-msg'
                                  : 'read-msg'
                              }
                            >
                              {user}
                            </h1>
                            {!messagesData[user]?.is_active && (
                              <p
                                className="disabled-msg"
                                onClick={() =>
                                  setSelectedChat(messagesData[user])
                                }
                              >
                                View History
                              </p>
                            )}
                          </div>
                          {messagesData[user].last_message && (
                            <p title={messagesData[user].last_message}>
                              {messagesData[user].last_message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="right-wrapper">
                        {messagesData[user].last_message_time && (
                          <p className="date-time-wrapper">
                            {getDateAndTime(
                              messagesData[user].last_message_time
                            )}
                          </p>
                        )}
                        {messagesData[user].unread_messages ? (
                          <Badge variant="info">
                            {messagesData[user].unread_messages}
                          </Badge>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
            </InfiniteScroll>
          </div>
        ) : (
          <>
            <NoDataFound
              src={NoManageUsersIcon}
              description={STRINGS.NO_MESSAGES}
              alt={'no-registered-users-img'}
              isAbsolute
              // subMsgDescription={
              //   STRINGS.YOUR_LIST_OF_USERS_ARE_EMPTY_BECAUSE_YOU_HAVENT_ADDED_ANY_USERS_YET
              // }
              imgWidth={60}
              imgHeight={60}
            />
          </>
        )}
      </div>
    );
  };

  const handleMarkAsRead = () => {
    dispatch(
      markSelectedMessagesAsRead({
        messageIds: selectedMessages,
        userId: selectedChat.id,
        fetchMessages: true,
        closureId: selectedClosure?.id,
        closureUnreadMessagesCount: closuresUnreadMessagesCount,
        isUnreadMessages: activeTabName === 'unread Messages'
      })
    );
    setSelectedMessages([]);
  };

  const handleClear = () => {
    setSelectedUsers(null);
    setSelectedMessages([]);
    setIsSelectAll(false);
  };

  const handleTabChange = (tab: string) => {
    setSelectedUsers(null);
    setIsSelectAll(false);
    setActiveTabName(tab);
  };

  return (
    <Card className="w-100">
      <TableWrapper id="table-group">
        <TableTitle
          title={STRINGS.MESSAGES}
          message={removeEventManagedByLabel(
            selectedClosure?.wea_short_msg_english
          )}
        >
          {getPermission(currentUser, 'outbound_message_frontend') && (
            <Button
              disabled={!selectedUsers || !Object.keys(selectedUsers)?.length}
              onClick={() => {
                setIsComposeMessage(true);
              }}
              {...btnStyles.primary}
            >
              <Icon
                icon={'message'}
                stroke={colorState.icon.default['primary-inverse']}
                width={'16px'}
                height={'16px'}
              />
              {STRINGS.COMPOSE_MESSAGE}
            </Button>
          )}
        </TableTitle>
        <div id="table-tabs">
          <div
            role="tablist"
            className="d-flex left-wrapper gap-2 flex-wrap"
            onClick={() => setSelectedChat(null)}
          >
            <TabItems
              role="tab"
              id={`all-messages-tab`}
              tabIndex={0}
              aria-label="all-messages-tab"
              onClick={() => {
                handleTabChange('All Messages');
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleTabChange('All Messages');
                }
              }}
              selectedtab={activeTabName === 'All Messages'}
            >
              {STRINGS.ALL_MESSAGES}
            </TabItems>
            <TabItems
              role="tab"
              id={`unread-messages-tab`}
              tabIndex={0}
              aria-label="unread-messages-tab"
              onClick={() => {
                handleTabChange('unread Messages');
              }}
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  handleTabChange('unread Messages');
                }
              }}
              selectedtab={activeTabName === 'unread Messages'}
            >
              <Badge
                style={{
                  color: colorState.text['primary-inverse'],
                  background:
                    activeTabName === 'unread Messages'
                      ? colorState.actions.primary['primary']
                      : colorState.actions.primary['primary-bg']
                }}
              >
                {messagesData?.total_unread}
              </Badge>
              {STRINGS.UNREAD_MESSAGES}
            </TabItems>
          </div>
          <div className="right-wrapper" role="tablist">
            {getPermission(currentUser, 'update_messages_states') && (
              <Button
                role="tab"
                id={`mark-as-read-button`}
                tabIndex={0}
                aria-label="mark-as-read-button"
                disabled={!selectedMessages.length}
                aria-disabled={!selectedMessages.length}
                onClick={handleMarkAsRead}
                {...btnStyles.tertiary}
              >
                {STRINGS.MARK_AS_READ}
              </Button>
            )}
            <TabItems
              role="tab"
              id={`clear-tab`}
              tabIndex={
                (!selectedUsers || !Object.keys(selectedUsers)?.length) &&
                !selectedMessages?.length
                  ? -1
                  : 0
              }
              aria-label="clear-tab"
              className="table-clear-tab"
              disabled={
                (!selectedUsers || !Object.keys(selectedUsers)?.length) &&
                !selectedMessages?.length
              }
              aria-disabled={
                (!selectedUsers || !Object.keys(selectedUsers)?.length) &&
                !selectedMessages?.length
              }
              onClick={handleClear}
            >
              <Icon
                icon={'eraser'}
                className="eraser-icon"
                stroke={colorState.icon.default.secondary}
                width={16}
                height={16}
                alt="eraser-icon"
              />
              {STRINGS.CLEAR_SELECTION}
            </TabItems>
          </div>
        </div>
        {isLoadingInitialData ? (
          <PALoader />
        ) : (
          <MessageStyles className="d-flex overflow-auto flex-grow-1 flex-column">
            {selectedChat || isComposeMessage ? (
              <div className="messages-grid">
                <section
                  className={
                    selectedChat &&
                    (activeTabName !== 'unread Messages' ||
                      selectedChat?.unread_messages)
                      ? 'left'
                      : 'middle'
                  }
                >
                  {renderSectionBody()}
                </section>
                {selectedChat &&
                (activeTabName !== 'unread Messages' ||
                  selectedChat?.unread_messages) ? (
                  <section className="middle">
                    <ChatBox
                      selectedChat={selectedChat}
                      selectedMessages={selectedMessages}
                      setSelectedMessages={setSelectedMessages}
                      setSelectedUsers={setSelectedUsers}
                      setIsSelectAll={setIsSelectAll}
                      isSelectAll={isSelectAll}
                      selectedUsers={selectedUsers}
                      activeTabName={activeTabName}
                    />
                  </section>
                ) : null}
                {isComposeMessage && (
                  <section
                    className="right"
                    tabIndex={0}
                    aria-label="right-section"
                  >
                    <ComposeMessage
                      onClose={() => {
                        setIsComposeMessage(false);
                      }}
                      selectedUsers={
                        selectedUsers && Object.keys(selectedUsers)
                      }
                      handleClose={handleComposeMessageClose}
                      fetchMessages={true}
                      closureId={selectedClosure?.id}
                      activeTabName={activeTabName}
                    />
                  </section>
                )}
              </div>
            ) : (
              renderSectionBody()
            )}
          </MessageStyles>
        )}
      </TableWrapper>
    </Card>
  );
};

export default Messages;
