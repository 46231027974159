import { FormProvider, useForm } from 'react-hook-form';
import { Button, RadioBox, Select } from '../../Universal/NovusDSImports';
import {
  btnStyles,
  radioBoxStyles,
  selectStyles
} from '../../Universal/NovusDSImports/variants';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { GenericOptionsInterface } from '../../CommonTypes';
import { FormSegment } from '../globalStyles';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import axios from 'axios';
import { api_prefix } from '../../Constants/constants';
import FileDownload from 'js-file-download';
import { ExportExcelParamsInterface } from '../../Store/actions/Resources';
import { showPopUp } from '../../CommonUtilities/CommonUtilities';
import { FunctionComponent, lazy, Suspense, useState } from 'react';
import { fetchMapData } from '../../Store/reducers/Resources';
import { format } from 'date-fns';
import { STRINGS } from '../../Constants/ConstantStrings';
import { RootState } from '../../store';
import PALoader from '../../SharedComponets/PALoader';

const ResourcesDateSelector = lazy(() => import('./ResourcesDateSelector'));

type IntentOptionsValueType = 'genarate_report' | 'view_map';

const intentOptions: GenericOptionsInterface<IntentOptionsValueType> = [
  {
    label: 'Generate Event Summary Reports',
    value: 'genarate_report'
  },
  {
    label: 'View Activation Locations Map',
    value: 'view_map'
  }
];

type TimespanFilterType = 'date_range' | 'lifetime';

const timespanFilterOptions: GenericOptionsInterface<TimespanFilterType> = [
  {
    label: 'Custom Date Range',
    value: 'date_range'
  },
  {
    label: 'Lifetime (All Events)',
    value: 'lifetime'
  }
];

type ReportTypeOptions = 'events_by_year' | 'events_by_month' | 'full_details';

const reportTypeOptions: GenericOptionsInterface<ReportTypeOptions> = [
  {
    label: 'Events by Year',
    value: 'events_by_year'
  },
  {
    label: 'Events by Month/Year',
    value: 'events_by_month'
  },
  {
    label: 'Events with Full Details',
    value: 'full_details'
  }
];

interface ResourcesFormPropsType {
  setShowMap: (value: boolean) => void;
  unselectMarker: () => void;
}

const ResourcesForm: FunctionComponent<ResourcesFormPropsType> = (props) => {
  const { setShowMap, unselectMarker } = props;
  const dispatch = useReduxDispatch();

  const getDefaultValues = () => {
    return {
      intentOption: undefined,
      timespanFilter: undefined,
      reportType: 'full_details',
      startDate: null,
      endDate: new Date()
    };
  };

  const isFetchingMapData = useReduxSelector(
    (state: RootState) => state.Resources.isFetchingMapData
  );
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

  const formSchema = yup.object({
    intentOption: yup
      .string()
      .oneOf(['genarate_report', 'view_map'])
      .required('An action is required'),
    timespanFilter: yup
      .string()
      .oneOf(['date_range', 'lifetime'])
      .required('Timespan is required'),
    reportType: yup.string().when('intentOption', (intentOption) => {
      if (intentOption[0] === 'genarate_report') {
        return yup
          .string()
          .oneOf(
            reportTypeOptions.map((option) => option.value),
            'Report Type is required'
          )
          .required('Report Type is required');
      } else {
        return yup.string();
      }
    }),
    startDate: yup.date().when('timespanFilter', (timespanFilter) => {
      if (timespanFilter[0] === 'date_range') {
        return yup.date().required('Start Date is required');
      } else {
        return yup.date().nullable();
      }
    }),
    endDate: yup.date().when(['timespanFilter'], (timespanFilter) => {
      if (timespanFilter[0] === 'date_range') {
        return yup
          .date()
          .when(
            ['timespanFilter', 'startDate', 'reportType'],
            ([timespanFilter, startDate, reportType]) => {
              if (timespanFilter === 'date_range' && startDate) {
                const date = new Date(startDate.getTime());
                // Substracting 1 day from start date because same date is allowed
                if (reportType === 'events_by_year') {
                  date.setMonth(0);
                  date.setDate(1);
                } else if (reportType === 'events_by_month') {
                  date.setDate(1);
                }
                return yup
                  .date()
                  .required('End Date is required')
                  .test(
                    'end-date',
                    'End Date must be after Start Date',
                    function (value) {
                      return value?.getTime() >= startDate.getTime();
                    }
                  );
              } else {
                return yup.date();
              }
            }
          );
      } else {
        return yup.date().nullable();
      }
    })
  });

  const generateExcelReport = (params: ExportExcelParamsInterface) => {
    if (!isFetchingData) {
      setIsFetchingData(true);

      axios
        .post(`${api_prefix}closures/get_excel_report/`, params, {
          responseType: 'blob'
        })
        .then((response) => {
          FileDownload(
            response.data,
            `${params.report_type.toLowerCase()}.xlsx`
          );
          setIsFetchingData(false);
        })
        .catch(() => {
          showPopUp('Something went wrong', 'error');
          setIsFetchingData(false);
        });
    }
  };

  const methods = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(),
    resolver: yupResolver<any>(formSchema)
  });

  const {
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors }
  } = methods;

  const onSubmit = (data) => {
    let params: any = {};
    unselectMarker();
    if (data.timespanFilter === 'date_range') {
      const startDate = new Date(data.startDate);
      const endDate = new Date(data.endDate);
      if (data.reportType === 'events_by_year') {
        startDate.setDate(1);
        startDate.setMonth(0);
        // We don't know the last day of month so we are chaning the month to next month and setting date to previous day
        endDate.setMonth(12);
        endDate.setDate(0);
      } else if (data.reportType === 'events_by_month') {
        startDate.setDate(1);
        // We don't know the last day of month so we are chaning the month to next month and setting date to previous day
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0);
      }
      params = {
        start_date: format(startDate, 'yyyy-MM-dd'),
        end_date: format(endDate, 'yyyy-MM-dd')
      };
    }
    if (data.intentOption === 'genarate_report') {
      params = {
        ...params,
        report_type:
          data.reportType === 'events_by_month'
            ? 'MONTHLY'
            : data.reportType === 'events_by_year'
            ? 'YEARLY'
            : 'FULL_DETAILS'
      };
      generateExcelReport(params);
    } else {
      dispatch(fetchMapData({ params: { ...params, view_on_map: true } }));
      setShowMap(true);
    }
  };

  return (
    <FormProvider {...methods}>
      <FormSegment onSubmit={handleSubmit(onSubmit)}>
        <section className="formset-fields">
          <div className="custom-radio-group">
            <h1>{STRINGS.CHOOSE_ANY_ONE}</h1>
            <div className="form-group flex-column">
              {intentOptions.map((option, index: number) => (
                <RadioBox
                  key={index}
                  id={`intentOption-${index}`}
                  text={option.label}
                  checked={watch('intentOption') === option.value}
                  onChange={() => {
                    setValue('intentOption', option.value);
                    if (option.value === 'view_map') {
                      methods.setValue('reportType', 'full_details');
                    }
                  }}
                  subtext={
                    <div className="error-text">
                      {!getValues('intentOption') &&
                        errors?.intentOption?.message?.toString()}
                    </div>
                  }
                  {...radioBoxStyles}
                />
              ))}
            </div>
          </div>
          {watch('intentOption') === 'genarate_report' && (
            <div className="form-group">
              <Select
                {...selectStyles}
                asterisk
                data-testid="reportType"
                name="reportType"
                displayName="Report Type"
                placeHolder="Select Option"
                value={reportTypeOptions.find(
                  (option) => option.value === watch('reportType')
                )}
                options={reportTypeOptions}
                onChange={(e) => {
                  setValue('reportType', e.value);
                }}
                hintText={errors?.reportType?.message?.toString()}
                error={errors?.reportType?.message}
              />
            </div>
          )}
          <div className="custom-radio-group">
            <h1>{STRINGS.CHOOSE_ANY_TIMESPAN}</h1>
            <div className="form-group flex-column">
              {timespanFilterOptions.map((option, index: number) => (
                <RadioBox
                  key={index}
                  id={`timespanFilter-${index}`}
                  text={option.label}
                  checked={watch('timespanFilter') === option.value}
                  onChange={() => {
                    setValue('timespanFilter', option.value);
                  }}
                  subtext={
                    <div className="error-text">
                      {!getValues('timespanFilter') &&
                        errors?.timespanFilter?.message?.toString()}
                    </div>
                  }
                  {...radioBoxStyles}
                />
              ))}
            </div>
          </div>
          <div>
            {watch('timespanFilter') === 'date_range' && (
              <div className="d-flex flex-column">
                <div className="form-group flex-column gap-0">
                  <Suspense fallback={<PALoader />}>
                    <ResourcesDateSelector
                      name="startDate"
                      labelPrefix="Start"
                    />
                  </Suspense>
                </div>
                <div className="form-group flex-column gap-0">
                  <Suspense fallback={<PALoader />}>
                    <ResourcesDateSelector name="endDate" labelPrefix="End" />
                  </Suspense>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="footer">
          <div className="d-flex gap-3 justify-content-end w-100">
            <Button
              data-testid="submit-button"
              {...btnStyles.primary}
              type="submit"
              disabled={isFetchingData || isFetchingMapData}
            >
              {isFetchingData || isFetchingMapData ? 'Loading...' : 'Save'}
            </Button>
          </div>
        </section>
      </FormSegment>
    </FormProvider>
  );
};

export default ResourcesForm;
