import { configureStore } from '@reduxjs/toolkit';
import { RecursivePartial } from './CommonTypes';
import { isDevInstance, isUatInstance, isTesting } from './settings';
import { RootState } from './store';
import rootReducer from './Store/reducers';

export const setupStore = (
  middlewares: Array<any>,
  preloadedState?: RecursivePartial<RootState>
) => {
  return configureStore({
    reducer: rootReducer,
    devTools:
      (isDevInstance || isUatInstance) && !isTesting ? { trace: true } : false,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middlewares),
    preloadedState
  });
};
