import { useEffect, useMemo, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { disableEnterPress } from '../../../CommonUtilities/CommonUtilities';
import { STRINGS } from '../../../Constants/ConstantStrings';
import {
  getUserPhoneNumber,
  manageLocationReceived,
  manageLocationRequest,
  userPhoneNumberReceived
} from '../../../Store/reducers/PublicView';
import { colorState } from '../../../Universal/Foundation';
import {
  Button,
  Icon,
  Input,
  RadioBox
} from '../../../Universal/NovusDSImports';
import {
  btnStyles,
  inputStyles,
  radioBoxStyles
} from '../../../Universal/NovusDSImports/variants';
import { RootState } from '../../../store';
import TermsAndConditions from '../TermsConditions';
import {
  FormPrimaryHeader,
  HighlightMessage,
  PublicViewContainerStyles,
  UserRegistrationForm
} from '../styles';

import { useReduxDispatch, useReduxSelector } from '../../../Store/reduxHooks';
import PALoader from '../../../SharedComponets/PALoader';

function ManageLocationForm() {
  const urlParams = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return Object.fromEntries(params.entries());
  }, [window.location.search]);

  const dispatch = useReduxDispatch(),
    history = useHistory(),
    [mobileNumber, setMobileNumber] = useState<string>(''),
    [submit, setSubmit] = useState<boolean>(false),
    [selectedUpdated, setSelectedUpdate] = useState<boolean>(true),
    [isTermsConditions, setIsTermsCondtions] = useState(false),
    message = useReduxSelector((state: RootState) => state.PublicView.message),
    isManageLocationUpdated = useReduxSelector(
      (state: RootState) => state.PublicView.isManageLocationUpdated
    ),
    isSubmitting = useReduxSelector(
      (state: RootState) => state.PublicView.isSubmitting
    ),
    isFetchingPhoneNumber = useReduxSelector(
      (state: RootState) => state.PublicView.isFetchingUserPhoneNumber
    ),
    userPhoneNumber = useReduxSelector(
      (state: RootState) => state.PublicView.registeredUserPhoneNumber
    );

  const countryCode = '+1';
  const termsConditions = () => {
    setIsTermsCondtions(true);
  };

  useEffect(() => {
    return () => {
      dispatch(userPhoneNumberReceived({ phoneNumber: null }));
    };
  }, []);

  useEffect(() => {
    if (!urlParams.closure_id) {
      history.push('/');
    }
  }, [history, urlParams.closure_id]);

  useEffect(() => {
    if (message && typeof message === 'string') {
      setTimeout(() => {
        const result = {
          isManageLocationUpdated: false,
          message: ''
        };
        dispatch(manageLocationReceived({ result }));
      }, 5000);
    }
  }, [dispatch, message]);

  useEffect(() => {
    if (urlParams?.user_uuid) {
      dispatch(
        getUserPhoneNumber({
          phoneNumberUUID: urlParams.user_uuid
        })
      );
    }
    if (urlParams?.phone_number) {
      setMobileNumber(urlParams.phone_number.slice(2));
    }
  }, [urlParams]);

  useEffect(() => {
    if (userPhoneNumber) {
      setMobileNumber(userPhoneNumber.slice(2));
    }
  }, [userPhoneNumber]);

  const submitWithLocation = (
    position: any,
    isLocationAcceptedFromWeb: boolean
  ) => {
    const manageLocationPayload = {
      location: {
        type: 'Point',
        coordinates: isLocationAcceptedFromWeb
          ? [position.coords.longitude, position.coords.latitude]
          : []
      },
      location_denied: !isLocationAcceptedFromWeb,
      closure_id: urlParams?.closure_id
    };
    manageLocationPayload['phone_number'] = countryCode + mobileNumber;
    dispatch(manageLocationRequest({ manageLocationPayload }));
    setMobileNumber('');
    setSubmit(false);
    setSelectedUpdate(true);
  };

  const onSubmit = () => {
    setSubmit(true);
    if (mobileNumber?.length === 10) {
      if (selectedUpdated) {
        window.navigator.geolocation?.getCurrentPosition(
          (position) => {
            submitWithLocation(position, true);
          },
          (error) => {
            if (error?.code === 1) {
              const result = {
                isManageLocationUpdated: false,
                message: STRINGS.LOCATION_PERMISSION_ERROR
              };
              dispatch(manageLocationReceived({ result }));
            } else {
              const result = {
                isManageLocationUpdated: false,
                message: STRINGS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN
              };
              dispatch(manageLocationReceived({ result }));
            }
          }
        );
      } else {
        submitWithLocation([], false);
      }
    }
  };

  const onCancel = (e) => {
    e.preventDefault();
    history.push('/');
  };

  const onBack = () => {
    setIsTermsCondtions(false);
  };

  return urlParams?.user_uuid && !userPhoneNumber && isFetchingPhoneNumber ? (
    <PALoader />
  ) : (
    <PublicViewContainerStyles>
      {!isTermsConditions && (
        <div
          className="d-flex overflow-auto flex-column flex-grow-1 h-100 w-100"
          data-testid="manageLocationPage"
        >
          <div
            className="container justify-content-between"
            onKeyDown={disableEnterPress}
          >
            <div className="gap-4 d-flex flex-column overflow-auto">
              <FormPrimaryHeader className="text-lg-nowrap">
                <div className="form-main-heading">
                  {STRINGS.UPDATE_MY_LOCATION}
                </div>
              </FormPrimaryHeader>
              {message && (
                <HighlightMessage
                  messageType={isManageLocationUpdated ? 'success' : 'error'}
                >
                  <Icon
                    icon={
                      isManageLocationUpdated ? 'check_circle' : 'alert_circle'
                    }
                    stroke={
                      isManageLocationUpdated
                        ? colorState.icon.success.primary
                        : colorState.icon.danger.secondary
                    }
                    className="me-3"
                  />
                  {message}
                </HighlightMessage>
              )}
              <UserRegistrationForm>
                <div className="form-body">
                  <div className="row">
                    <div className="text-nowrap col-4 col-sm-3 col-lg-2 pe-0">
                      <Input
                        label={STRINGS.COUNTRY_CODE}
                        name={STRINGS.COUNTRY_CODE}
                        placeholder="+1 (USA)"
                        isMargin={false}
                        {...inputStyles}
                        disabled
                        bgColor="white"
                      />
                    </div>
                    <div className="col-8 col-sm-9 col-lg-10 ps-2">
                      <Input
                        name={STRINGS.MOBILE_NUMBER}
                        label={STRINGS.MOBILE_NUMBER}
                        placeholder={STRINGS.ENTER_MOBILE_NUMBER}
                        value={mobileNumber}
                        disabled={urlParams?.user_uuid}
                        onChange={(e) => {
                          if (
                            (e.target.value && e.target.value.length < 11) ||
                            !e.target.value
                          ) {
                            const input = e.target.value;
                            // Validate input to allow only numbers and the '+' sign
                            const sanitizedInput = input.replace(
                              /[^0-9+]/g,
                              ''
                            );
                            setMobileNumber(sanitizedInput);
                          }
                        }}
                        isMargin={false}
                        {...inputStyles}
                        bgColor="white"
                        error={
                          submit &&
                          (!mobileNumber.length || mobileNumber.length < 10)
                        }
                        hintText={
                          submit &&
                          (!mobileNumber.length || mobileNumber.length < 10) &&
                          STRINGS.MOBILE_NUMBER_SHOULD_CONTAIN_10_DIGITS
                        }
                      />
                    </div>
                  </div>
                  <div className="d-flex gap-4 flex-column flex-md-row">
                    <div className="d-flex flex-nowrap">
                      <RadioBox
                        id={STRINGS.UPDATE_CURRENT_LOCATION}
                        disabled={false}
                        text={STRINGS.UPDATE_CURRENT_LOCATION}
                        checked={selectedUpdated}
                        onChange={() => {
                          setSelectedUpdate(true);
                        }}
                        {...radioBoxStyles}
                      />
                    </div>
                    <div className="d-flex flex-nowrap">
                      <RadioBox
                        id={STRINGS.OPT_OUT_LOCATION}
                        disabled={false}
                        text={STRINGS.OPT_OUT_LOCATION}
                        checked={!selectedUpdated}
                        onChange={() => {
                          setSelectedUpdate(false);
                        }}
                        {...radioBoxStyles}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-footer">
                  <Button
                    onClick={onCancel}
                    disabled={isSubmitting}
                    {...btnStyles.secondary}
                    className="flex-grow-2 flex-md-grow-0"
                  >
                    {STRINGS.CANCEL}
                  </Button>
                  <Button
                    type={STRINGS.BUTTON}
                    onClick={onSubmit}
                    disabled={isSubmitting}
                    {...btnStyles.primary}
                    className="flex-grow-1 flex-md-grow-0"
                  >
                    {isSubmitting ? STRINGS.SUBMITTING : STRINGS.SUBMIT}
                  </Button>
                </div>
              </UserRegistrationForm>
            </div>
            <div className="gap-3 d-flex flex-column align-items-center">
              <Button
                {...btnStyles.secondary}
                onClick={() => history.push('/')}
                disabled={isSubmitting}
                data-testid="goBackToClosuresStatusPageButton"
              >
                {STRINGS.GO_BACK_TO_CLOSURES_STATUS_PAGE}
              </Button>
              <span
                className="navigation-link"
                onClick={termsConditions}
                aria-label="Policy,Terms and Conditions"
                tabIndex={0}
                role="link"
                data-testid="termsAndConditionsPageButton"
              >
                {STRINGS.TERMS_AND_CONDITIONS_POLICY}
              </span>
            </div>
          </div>
        </div>
      )}
      {isTermsConditions && <TermsAndConditions onBack={onBack} />}
    </PublicViewContainerStyles>
  );
}

export default withRouter(ManageLocationForm);
