import {
  DrawingManager,
  GoogleMap,
  Marker,
  Polygon,
  InfoWindow,
  useLoadScript,
  Autocomplete as MapSearchBox,
  MarkerClusterer
} from '@react-google-maps/api';
import {
  clearPolygon,
  completePolygon,
  getBoundaryBox,
  getCentroid,
  getPolygonBounds,
  isPolygonInvalid,
  removeEventManagedByLabel,
  showError,
  showPopUp
} from '../../CommonUtilities/CommonUtilities';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  CursorIcon,
  RegisteredUser,
  SelectedRegisteredUser,
  SelectedTransportationIcon,
  TransportationIcon,
  VectorBlackIcon,
  VectorThemeIcon,
  mapCamera,
  locationIcon,
  mapConstruction,
  mapIncidents,
  mapRestFacilities,
  mapSpecialEvents,
  SelectedUser
} from '../../Universal/Assets';
import {
  camerasReceived,
  eventsReceived,
  getCameras,
  getEvents,
  getRestFacilities,
  restFacilitiesReceived
} from '../../Store/reducers/MapFilters';

import { LegendBox, ClosureMarker, SearchBoxWrapper } from './styles';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import dayjs from 'dayjs';
import {
  DrawControlButtonsWrapper,
  MapComponentWrapper,
  PopupWindow
} from '../globalStyles';
import { Button, Icon, Input, Switch } from '../../Universal/NovusDSImports';
import {
  btnStyles,
  inputStyles,
  switchStyles
} from '../../Universal/NovusDSImports/variants';
import { colorState } from '../../Universal/Foundation';
import LegendDropdownMenu from './FilterDropdownMenu';
import { STRINGS } from '../../Constants/ConstantStrings';
import { RootState } from '../../store';
import usePrevious from '../../Hooks/UsePrevious';
import { useClickOutside } from '../../Hooks/useClickOutside';
import PALoader from '../../SharedComponets/PALoader';
import GoogleMapsErrorBoundary from '../../GoogleMapsErrorBoundary';
import {
  GOOGLE_MAPS_API_KEY,
  MILEPOSTS_API,
  TRAFFIC_API
} from '../../settings';
import { MapFilterIntervalTime } from '../../Constants/constants';
import UserDetailsPopupModal from './UserDetailsPopupModal';
import CommonEventsTooltip from './LegendsTooltip/CommonEventsTooltip';
import RestFacilitiesPopUpWindow from './LegendsTooltip/RestFacilitiesTooltip';
import CameraTooltip from './LegendsTooltip/CameraTooltip';
import { TooltipComponent } from '../../SharedComponets/Tooltip';
// import GoogleMapsErrorBoundary from '../../GoogleMapsErrorBoundaryErrorBoundary';

const libraries: any = ['drawing', 'places'];

interface GoogleMapsComponentPropType {
  isDrawing: boolean;
  setDrawnPolygon: Function;
  isEdit: boolean;
  drawnPolygon: any;
  showSideBar: boolean;
  setIsDrawing: Function;
  isRegisteredUsers: boolean;
  mapCenter: { lat: number; lng: number };
  polygonRef: MutableRefObject<null | google.maps.Polygon>;
  showClearButton: boolean;
  setShowClearButton: Function;
  mapRef: MutableRefObject<google.maps.Map | null>;
  selectedMarker: number | null;
  setSelectedMarker: Function;
  selectedCard: any;
  isCloseEvent: boolean;
  registeredUsersLocation: any;
  setMapCenter: any;
  zoomLevel: number;
  isDropdownHeight?: any;
  setSelectedCard: any;
  selectedRegisteredUser: any;
  setselectedRegisteredUser: any;
  setZoomLevel: any;
  isCollapsableMap: any;
  setIsCollapsableMap: Function;
  isDrawMode: boolean;
  setIsDrawMode: Function;
  setAffectedAreaType: Function;
  affectedAreaType: string;
  selectedRows: any;
  setSelectedRows: Function;
  setActiveTabName: Function;
}

const GoogleMapsComponent = (props: GoogleMapsComponentPropType) => {
  const {
    isDrawing,
    setDrawnPolygon,
    drawnPolygon,
    showSideBar,
    setIsDrawing,
    isRegisteredUsers,
    mapCenter,
    polygonRef,
    showClearButton,
    setShowClearButton,
    mapRef,
    selectedMarker,
    setSelectedMarker,
    selectedCard,
    isCloseEvent,
    registeredUsersLocation,
    setMapCenter,
    zoomLevel,
    setZoomLevel,
    setSelectedCard,
    selectedRegisteredUser,
    setselectedRegisteredUser,
    isCollapsableMap,
    setIsCollapsableMap,
    setIsDrawMode,
    isDrawMode,
    affectedAreaType,
    setAffectedAreaType,
    selectedRows,
    setSelectedRows,
    setActiveTabName
  } = props;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libraries
  });

  const listenersRef = useRef<Array<google.maps.MapsEventListener>>([]),
    registeredUsersData = useReduxSelector(
      (state: RootState) => state.RegisteredUsers.registeredUsers
    ),
    selectedClosure = useReduxSelector(
      (state: RootState) => state.Common.selectedClosure
    ),
    [isSwitchSelected, setIsSwitchSelected] = useState<boolean>(false),
    [isLegendDropDown, setIsLegendDropdown] = useState<boolean>(false),
    [selectedUser, setSelectedUser] = useState<any>(null),
    [checkedItems, setCheckedItems] = useState<any>({
      cameras: false,
      mileposts: false,
      incidents: false,
      construction: false,
      specialEvents: false,
      restFacilities: false
    }),
    [mapViewBounds, setMapViewBounds] =
      useState<google.maps.LatLngBounds | null>(null),
    prevCheckedItems = usePrevious(checkedItems),
    closuresMarkersRef = useRef<{ [props: number]: google.maps.Marker }>({}),
    closureInfoWindowRef = useRef<google.maps.InfoWindow | null>(null),
    dispatch = useReduxDispatch(),
    cameras = useReduxSelector((state: RootState) => state.MapFilters.cameras),
    roadEvents = useReduxSelector(
      (state: RootState) => state.MapFilters.events
    ),
    currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    restFacilitiesData = useReduxSelector(
      (state: RootState) => state.MapFilters.restFacilities
    ),
    questionsclosuresData = useReduxSelector(
      (state: RootState) => state.Questions.closuresQuestionsData
    ),
    closuresData = useReduxSelector(
      (state: RootState) => state.Closures.closuresData
    ),
    selectedTab = useReduxSelector(
      (state: RootState) => state.Common.selectedTab
    ),
    isFetchingClosuresData = useReduxSelector(
      (state: RootState) => state.Closures.isFetchingClosureData
    ),
    events = useReduxSelector((state: RootState) => state.MapFilters.events);

  const [searchBox, setSearchBox] = useState<string>('');

  const [searchMarker, setSearchMarker] = useState<any>(null);

  const searchBoxRef = useRef<any>(null);

  const [selectedCommonEvent, setSelectedCommonEvent] = useState<any>(null);

  const onPlaceChanged = useCallback(() => {
    if (searchBoxRef.current) {
      const place = searchBoxRef.current.getPlace();
      if (place.geometry) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        setSearchBox(place.formatted_address);
        setMapCenter({ lat: latitude, lng: longitude });
        setZoomLevel(11);

        setSearchMarker({ lat: latitude, lng: longitude });
      }
    }
  }, []);

  const [isLoadingTiles, setIsLoadingTiles] = useState(false);
  const overlayLayerRefs = useRef<Record<string, google.maps.ImageMapType>>({});
  const overlayImageGetters = {
    mileposts: (x: number, y: number, z: number) =>
      `${MILEPOSTS_API}${x}/${y}/${z}`,
    traffic: (x: number, y: number, z: number) =>
      `${TRAFFIC_API}?x=${x}&y=${y}&z=${z}`
  };

  const reArrangeIfMilepostLayer = () => {
    const trafficLayerIndex = mapRef.current?.overlayMapTypes
      .getArray()
      ?.findIndex((layer) => layer?.name === 'traffic');
    if (trafficLayerIndex) {
      mapRef.current?.overlayMapTypes.clear();
      mapRef.current?.overlayMapTypes.push(
        overlayLayerRefs.current['mileposts']
      );
      mapRef.current?.overlayMapTypes.push(overlayLayerRefs.current['traffic']);
    }
  };

  const handleLayerToggle = (
    isChecked: boolean,
    layerKey: 'mileposts' | 'traffic'
  ) => {
    const layerIndex = mapRef.current?.overlayMapTypes
      .getArray()
      ?.findIndex((layer) => layer?.name === layerKey);
    if (isChecked && layerIndex === -1) {
      overlayLayerRefs.current[layerKey] = new google.maps.ImageMapType({
        getTileUrl: function (coord: any, zoom: any) {
          setIsLoadingTiles(true);
          return overlayImageGetters[layerKey](coord.x, coord.y, zoom);
        },
        name: layerKey
      });
      overlayLayerRefs.current[layerKey].addListener('tilesloaded', () => {
        setIsLoadingTiles(false);
      });
      if (mapRef.current?.overlayMapTypes?.getLength()) {
        reArrangeIfMilepostLayer();
      }
      mapRef.current?.overlayMapTypes.push(overlayLayerRefs.current[layerKey]);
      reArrangeIfMilepostLayer();
    } else if (typeof layerIndex === 'number' && layerIndex >= 0) {
      mapRef.current?.overlayMapTypes.removeAt(layerIndex);
    }
  };

  const handleFullScreenChange = () => {
    setSelectedUser(null);
    localStorage.setItem(
      'fullScreen',
      localStorage.getItem('fullScreen') === 'true' ? 'false' : 'true'
    );
  };

  useEffect(() => {
    let val: Array<string> = [];

    if (localStorage.getItem('legends')?.length) {
      val = JSON.parse(localStorage.getItem('legends') || '[]');

      const legendsObj = {
        cameras: val.includes('cameras'),
        mileposts: val.includes('mileposts'),
        incidents: val.includes('incidents'),
        construction: val.includes('construction'),
        specialEvents: val.includes('specialEvents'),
        restFacilities: val.includes('restFacilities')
      };

      setCheckedItems(legendsObj);
    } else {
      val = Object.keys(checkedItems).filter((key) => checkedItems[key]);

      localStorage.setItem('legends', JSON.stringify(val));
    }

    return () => {
      localStorage.removeItem('legends');
    };
  }, [checkedItems]);

  useEffect(() => {
    localStorage.setItem('fullScreen', 'false');
    window.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      localStorage.removeItem('fullScreen');
      window.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    Object.keys(overlayLayerRefs.current).forEach((layerKey) =>
      overlayLayerRefs.current[layerKey]?.setOpacity(isLoadingTiles ? 0 : 1)
    );
  }, [isLoadingTiles]);

  const handleSwitchToggle = (selected: boolean) => {
    if (mapRef.current) {
      handleLayerToggle(selected, 'traffic');
    }
    setIsSwitchSelected(selected);
  };
  const legendDropdown = () => {
      setIsLegendDropdown(!isLegendDropDown);
    },
    [selectedRestRoom, setSelectedRestRoom] = useState<any>(null);

  const [selectedCamera, setSelectedCamera] = useState<any>(null);
  const openStateElement = useRef(null);
  const excludedElement = useRef(null);

  useClickOutside(openStateElement, legendDropdown, [excludedElement]);

  const MarkerOpenStateRef = useRef(null);
  const MarkerExcludedRef = useRef(null);

  useClickOutside(
    MarkerOpenStateRef,
    () => closureInfoWindowRef.current?.close(),
    [MarkerExcludedRef]
  );

  useEffect(() => {
    closureInfoWindowRef.current = null;
    MarkerOpenStateRef.current = null;
    MarkerExcludedRef.current = null;
    const streetViewCloseElement: any = document.querySelector('.gm-iv-close');
    streetViewCloseElement?.click();
  }, [isRegisteredUsers]);

  const generateLegendBox = (color: string) => {
    return (
      <LegendBox className="legend-box" style={{ backgroundColor: color }} />
    );
  };

  const legendData = [
    {
      id: 1,
      legend_name: STRINGS.FREE_FLOWING,
      color: '#249D74'
    },
    {
      id: 2,
      legend_name: STRINGS.MODERATE_TRAFFIC,
      color: '#F4FF24'
    },
    {
      id: 3,
      legend_name: STRINGS.HEAVY_TRAFFIC,
      color: '#CC0004'
    },
    {
      id: 4,
      legend_name: STRINGS.STOP_AND_GO,
      color: '#4D0001'
    },
    {
      id: 5,
      legend_name: STRINGS.CLOSED,
      color: 'black'
    }
  ];

  const onMapLoad = useCallback(
    (map: google.maps.Map) => {
      mapRef.current = map;
    },
    [mapRef]
  );

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath: any = [];
      polygonRef.current
        .getPath()
        .getArray()
        .forEach((latLng) => {
          nextPath.push([latLng.lat(), latLng.lng()]);
        });
      const newCompletePolygon = completePolygon(nextPath);
      setDrawnPolygon(newCompletePolygon);
    }
  }, [polygonRef, setDrawnPolygon]);

  useEffect(() => {
    if (drawnPolygon && !isDrawing) {
      setMapCenter({
        lat: getCentroid(drawnPolygon)[1],
        lng: getCentroid(drawnPolygon)[0]
      });
    }
  }, [drawnPolygon, setMapCenter, isDrawing]);

  const stateBoundary = useReduxSelector(
    (state: RootState) => state.ClientInfo.state_boundary
  );

  const onPolygonLoad = useCallback(
    (polygon: google.maps.Polygon) => {
      if (polygonRef.current) {
        polygonRef.current.setMap(null);
      }
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit)
      );
      setMapViewBounds(getPolygonBounds(polygon));
    },
    [onEdit, polygonRef]
  );

  useEffect(() => {
    if ((selectedClosure && window.google) || isCloseEvent) {
      const bounds = new window.google.maps.LatLngBounds(null);
      selectedClosure?.geometry?.coordinates[0]?.forEach((point) => {
        bounds.extend({ lat: point[0], lng: point[1] });
      });
      setMapViewBounds(bounds);
    }
  }, [selectedClosure, isCloseEvent]);

  useEffect(() => {
    if (isLoaded && !closuresData?.results.length && stateBoundary) {
      const bounds = new window.google.maps.LatLngBounds(null);
      const boundary = getBoundaryBox(stateBoundary.coordinates);
      bounds.extend({ lat: boundary[1], lng: boundary[0] });
      bounds.extend({ lat: boundary[3], lng: boundary[0] });
      bounds.extend({ lat: boundary[3], lng: boundary[2] });
      bounds.extend({ lat: boundary[1], lng: boundary[2] });
      setMapViewBounds(bounds);
      setZoomLevel(8);
    }
  }, [closuresData, isLoaded, stateBoundary]);

  useEffect(() => {
    if (mapViewBounds) {
      mapRef.current?.fitBounds(mapViewBounds);
    }
  }, [mapRef, mapViewBounds]);

  const onDrawComplete = (polygon: google.maps.Polygon) => {
    const polygonCoordsArray: any = [];
    const coords = polygon?.getPath()?.getArray();
    clearPolygon(polygon);
    // clearPolygon(polygonRef.current);
    for (let i = 0; i < coords.length; i++) {
      polygonCoordsArray.push([coords[i].lat(), coords[i].lng()]);
    }
    if (stateBoundary?.coordinates) {
      const polygonInvalidError = isPolygonInvalid(
        polygonCoordsArray,
        false,
        stateBoundary?.coordinates
      );
      if (polygonInvalidError) {
        handleClear();
        showPopUp(polygonInvalidError, 'error');
        return;
      }
    } else {
      showError(STRINGS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
    }
    polygonCoordsArray.push(polygonCoordsArray[0]);
    setDrawnPolygon(polygonCoordsArray);
    polygonRef.current?.setMap(mapRef.current);
    setShowClearButton(true);
    setIsDrawing(false);
  };

  useEffect(() => {
    setShowClearButton(drawnPolygon ? true : false);
  }, [drawnPolygon, setShowClearButton]);

  const handleMapFilterClick = useCallback(() => {
    if (
      !prevCheckedItems ||
      (prevCheckedItems &&
        checkedItems['cameras'] !== prevCheckedItems['cameras'])
    ) {
      if (checkedItems['cameras']) {
        dispatch(camerasReceived({ cameras: null }));
        dispatch(getCameras({}));
        cameraIntervalId.current = setInterval(
          () => dispatch(getCameras({})),
          180000
        );
      } else {
        dispatch(camerasReceived({ cameras: null }));
        clearInterval(cameraIntervalId.current || '');
        cameraIntervalId.current = null;
      }
    }

    if (
      !prevCheckedItems ||
      (prevCheckedItems &&
        checkedItems['restFacilities'] !== prevCheckedItems['restFacilities'])
    ) {
      if (checkedItems['restFacilities']) {
        dispatch(restFacilitiesReceived({ restFacilities: null }));
        dispatch(getRestFacilities({}));
        restFacilitiesIntervalId.current = setInterval(
          () => dispatch(getRestFacilities({})),
          MapFilterIntervalTime
        );
      } else {
        dispatch(restFacilitiesReceived({ restFacilities: null }));
        clearInterval(restFacilitiesIntervalId.current || '');
        restFacilitiesIntervalId.current = null;
      }
    }

    if (
      !prevCheckedItems ||
      (prevCheckedItems &&
        (checkedItems['incidents'] !== prevCheckedItems['incidents'] ||
          checkedItems['construction'] !== prevCheckedItems['construction'] ||
          checkedItems['specialEvents'] !== prevCheckedItems['specialEvents']))
    ) {
      if (
        checkedItems['incidents'] ||
        checkedItems['construction'] ||
        checkedItems['specialEvents']
      ) {
        if (!events) {
          dispatch(eventsReceived({ events: null }));
          dispatch(getEvents({}));
          eventsIntervalId.current = setInterval(
            () => dispatch(getEvents({})),
            MapFilterIntervalTime
          );
        }
      } else {
        dispatch(eventsReceived({ events: null }));
        clearInterval(eventsIntervalId.current || '');
        eventsIntervalId.current = null;
      }
    }
  }, [checkedItems, events, prevCheckedItems]);

  const handleLegendsClear = () => {
    setCheckedItems({
      cameras: false,
      mileposts: false,
      incidents: false,
      construction: false,
      specialEvents: false,
      restFacilities: false
    });
    handleLayerToggle(false, 'mileposts');
  };

  useEffect(() => {
    handleMapFilterClick();
  }, [checkedItems, handleMapFilterClick]);

  useEffect(() => {
    if (selectedTab) {
      handleLegendsClear();
    }
  }, [selectedTab]);

  useEffect(() => {
    setSelectedUser(
      selectedRegisteredUser
        ? registeredUsersData?.results?.indexOf(selectedRegisteredUser)
        : null
    );
  }, [selectedRegisteredUser, registeredUsersData]);

  const handleClear = useCallback(() => {
    if (polygonRef.current) {
      clearPolygon(polygonRef.current);
      polygonRef.current = null;
      setDrawnPolygon(null);
      setShowClearButton(false);
    }
    if (showSideBar && affectedAreaType === 'draw_polygon') {
      setIsDrawing(true);
    }
  }, [
    polygonRef,
    setDrawnPolygon,
    setIsDrawing,
    setShowClearButton,
    showSideBar,
    affectedAreaType
  ]);

  const cameraIntervalId = useRef<null | NodeJS.Timeout>(null),
    restFacilitiesIntervalId = useRef<null | NodeJS.Timeout>(null),
    eventsIntervalId = useRef<null | NodeJS.Timeout>(null);

  const getPolygonPath = (coords: Array<[number, number]>) => {
    return coords?.map((point) => ({ lat: point[0], lng: point[1] }));
  };

  if (!isLoaded || isFetchingClosuresData) return <PALoader />;

  const popupWindow = (closure: any, user?: any) => {
    if (typeof closure === 'number') {
      closure = closuresData?.results.find(
        (closureData) => closureData.id === closure
      );
    }
    if (!closure) return <></>;

    return (
      <PopupWindow ref={MarkerOpenStateRef}>
        <div className="header">
          <h5>
            {isRegisteredUsers ? STRINGS.USER_DETAILS : STRINGS.CLOSURE_DETAILS}
          </h5>
          <Icon
            icon="close"
            width={'16px'}
            height={'16px'}
            onClick={() => {
              if (isRegisteredUsers) setSelectedUser(null);
              closureInfoWindowRef.current?.close();
            }}
            className="close-icon"
          >
            {STRINGS.CLOSE}
          </Icon>
        </div>
        <div className="body">
          <h6>{closure.wea_short_msg_english}</h6>
          <div className="row latitude-longitude-section mx-0">
            <div className="col-sm-6 pe-1 ps-0">
              <label>
                {isRegisteredUsers
                  ? STRINGS.LATITUDE
                  : STRINGS.CENTER_POINT_LATITUDE}
              </label>
              <p>
                {isRegisteredUsers
                  ? user?.location?.coordinates[0]
                  : closure?.centre_latitude}
              </p>
            </div>
            <div className="col-sm-6 ps-1 pe-0">
              <label>
                {isRegisteredUsers
                  ? STRINGS.LONGITUDE
                  : STRINGS.CENTER_POINT_LONGITUDE}
              </label>
              <p>
                {isRegisteredUsers
                  ? user?.location?.coordinates[1]
                  : closure.centre_longitude}
              </p>
            </div>
          </div>

          {isRegisteredUsers && (
            <div className="group mt-3">
              <label>{STRINGS.PHONE}</label>
              <p>{user?.phone_number}</p>
            </div>
          )}
          {!isRegisteredUsers && (
            <div className="group mt-3">
              <label>{STRINGS.CURRENT_CONDITION}</label>
              <p>
                {isRegisteredUsers
                  ? selectedClosure?.description
                  : closure?.description}
              </p>
            </div>
          )}

          {!isRegisteredUsers && (
            <div className="group">
              <label>{STRINGS.UPDATED_ON}</label>
              <p> {dayjs(closure?.modified).format('MM/DD/YYYY  hh:mm A')}</p>
            </div>
          )}

          {isRegisteredUsers && (
            <div className="group">
              <label>{STRINGS.LAST_CONTACTED_ON}</label>
              <p>
                {user?.last_contacted_time
                  ? dayjs(user?.last_contacted_time)?.format(
                      'MM/DD/YYYY  hh:mm A'
                    )
                  : 'N/A'}
              </p>
            </div>
          )}
          {isRegisteredUsers &&
            questionsclosuresData &&
            questionsclosuresData.map((question: any) => {
              return (
                <div className="group">
                  <label>{question.display_name}</label>
                  <p>
                    {user?.answers && user?.answers[question.id]
                      ? user.answers && user.answers[question.id]
                      : 'N/A'}
                  </p>
                </div>
              );
            })}
        </div>
      </PopupWindow>
    );
  };

  const onHandleClick = () => {
    setIsCollapsableMap(!isCollapsableMap);
  };

  const customStyles = {
    content: {
      minWidth: '300px',
      maxWidth: '400px',
      maxHeight: '50%'
    }
  };

  return isLoaded ? (
    <GoogleMapsErrorBoundary>
      {isRegisteredUsers && (
        <div
          className={`${
            isRegisteredUsers && !isCollapsableMap && 'mb-2'
          } map-collapsable-wrapper`}
        >
          <div className="header">User Map</div>
          <div className="collapse-icon">
            <div
              data-tooltip-id="toggle-collapse"
              className={
                isCollapsableMap ? 'collapse-down-icon' : 'collapse-up-icon'
              }
            >
              <Icon
                icon={`${isCollapsableMap ? 'chevron_down' : 'chevron_up'}`}
                onClick={onHandleClick}
                width={20}
                height={20}
              />
            </div>
            <TooltipComponent
              id="toggle-collapse"
              place="left"
              tooltipBgColor={colorState.surface.brand.primary}
              arrowColor={colorState.surface.brand.primary}
            >
              {isCollapsableMap ? STRINGS.COLLAPSE : STRINGS.EXPAND}
            </TooltipComponent>
          </div>
        </div>
      )}
      <MapComponentWrapper
        id="map-wrapper"
        className={`${
          isRegisteredUsers
            ? isCollapsableMap
              ? 'register-users-map'
              : 'd-none'
            : ''
        }`}
      >
        <GoogleMap
          center={mapCenter}
          onLoad={onMapLoad}
          zoom={zoomLevel}
          onUnmount={() => {
            clearInterval(cameraIntervalId.current || '');
            clearInterval(restFacilitiesIntervalId.current || '');
            clearInterval(eventsIntervalId.current || '');
          }}
          mapContainerStyle={{
            width: '100%',
            height: '100%',
            borderRadius: !isRegisteredUsers ? 8 : ''
          }}
          onClick={(event: google.maps.MapMouseEvent) => {
            event.stop();
          }}
          options={{
            maxZoom: 18,
            mapTypeControl: true,
            mapTypeControlOptions: {
              position: window.google.maps.ControlPosition.BOTTOM_LEFT, // Set position of the control
              mapTypeIds: ['roadmap', 'satellite', 'terrain', 'hybrid']
            },
            fullscreenControl: true,
            // fullscreenControlOptions: {
            //   position: !isRegisteredUsers
            //     ? window.google.maps.ControlPosition.BOTTOM_RIGHT
            //     : window.google.maps.ControlPosition.TOP_RIGHT
            // },
            streetViewControl: !isRegisteredUsers
            // restriction: {
            //   strictBounds: false,
            //   latLngBounds: PA_BOUNDARY
            // }
          }}
        >
          {!isRegisteredUsers && (
            <div className="map-search-box">
              <SearchBoxWrapper>
                <MapSearchBox
                  onLoad={(searchBox) => (searchBoxRef.current = searchBox)}
                  onPlaceChanged={onPlaceChanged}
                >
                  <Input
                    type="text"
                    placeholder="Search for a location"
                    value={searchBox}
                    hideLabel
                    onRightIconActionClick={() => {
                      setSearchBox('');
                      setSearchMarker(null);
                    }}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        setSearchMarker(null);
                        setSearchBox('');
                      } else {
                        setSearchBox(e.target.value);
                      }
                    }}
                    iconLeft={
                      <Icon
                        icon={'search'}
                        stroke={colorState.icon.default.secondary}
                      />
                    }
                    iconRight={
                      searchBox && (
                        <Icon
                          icon={'close'}
                          stroke={colorState.icon.default.secondary}
                        />
                      )
                    }
                    {...inputStyles}
                  />
                </MapSearchBox>
              </SearchBoxWrapper>
            </div>
          )}

          {searchMarker && !showClearButton && (
            <Marker position={searchMarker} icon={locationIcon} />
          )}

          {isDrawing && !showClearButton && (
            <DrawingManager
              drawingMode={
                isDrawMode
                  ? window.google.maps.drawing.OverlayType.POLYGON
                  : null
              }
              onPolygonComplete={onDrawComplete}
              options={{
                drawingControl: false,
                drawingControlOptions: {
                  position: window.google.maps.ControlPosition.TOP_LEFT,
                  drawingModes: [window.google.maps.drawing.OverlayType.POLYGON]
                }
              }}
            />
          )}
          {isDrawing && !showClearButton && (
            <DrawControlButtonsWrapper isDrawMode={isDrawMode}>
              <div
                onClick={() => {
                  if (isDrawMode) {
                    setIsDrawMode(false);
                    setAffectedAreaType('');
                  }
                }}
                onKeyDown={(event: any) => {
                  if (event.key === 'Enter') {
                    setIsDrawMode(false);
                    setAffectedAreaType('');
                  }
                }}
                aria-label="cursor-icon"
                className="cursor-btn p-2 pe-1"
                tabIndex={0}
              >
                <img src={CursorIcon} alt={'cursor-icon'} />
              </div>
              <div
                {...btnStyles.secondary}
                onClick={() => {
                  setIsDrawMode(true);
                  setAffectedAreaType('draw_polygon');
                }}
                onKeyDown={(event: any) => {
                  if (event.key === 'Enter') {
                    setIsDrawMode(true);
                    setAffectedAreaType('draw_polygon');
                  }
                }}
                aria-label="draw-polygon"
                className={`${isDrawMode && 'draw-polygon-btn'} p-2`}
                tabIndex={0}
              >
                <img
                  src={isDrawMode ? VectorThemeIcon : VectorBlackIcon}
                  alt="vector-black-icon"
                />
              </div>
            </DrawControlButtonsWrapper>
          )}
          {showClearButton &&
            !isRegisteredUsers &&
            selectedTab !== 'eventDetails' && (
              <div
                onClick={handleClear}
                className="polygon-clear-button"
                tabIndex={0}
              >
                {STRINGS.CLEAR}
              </div>
            )}

          {checkedItems['cameras'] && (
            <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
              {(clusterer) => (
                <div>
                  {cameras?.map((data: any, index: number) => (
                    <Marker
                      key={index}
                      clusterer={clusterer}
                      onClick={() => {
                        if (selectedCamera && selectedCamera.Id === data.Id) {
                          setSelectedCamera(null);
                        } else {
                          setSelectedCamera(data);
                        }
                      }}
                      position={{ lat: data.latitude, lng: data.longitude }}
                      icon={mapCamera}
                    />
                  ))}
                </div>
              )}
            </MarkerClusterer>
          )}

          {selectedCamera && (
            <CameraTooltip
              camera={selectedCamera}
              setSelectedCamera={setSelectedCamera}
              markerOpenRef={MarkerOpenStateRef}
              setMapCenter={setMapCenter}
              setZoomLevel={setZoomLevel}
            />
          )}

          {checkedItems['restFacilities'] && (
            <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
              {(clusterer) => (
                <div>
                  {restFacilitiesData?.map((data: any, index: number) => {
                    return (
                      <Marker
                        key={index}
                        clusterer={clusterer}
                        onClick={() => {
                          if (
                            selectedRestRoom &&
                            selectedRestRoom.Id === data.Id
                          ) {
                            setSelectedRestRoom(null);
                          } else {
                            setSelectedRestRoom(data);
                          }
                        }}
                        position={{ lat: data.Latitude, lng: data.Longitude }}
                        icon={mapRestFacilities}
                      />
                    );
                  })}
                </div>
              )}
            </MarkerClusterer>
          )}

          {selectedRestRoom && (
            <RestFacilitiesPopUpWindow
              markerOpenRef={MarkerOpenStateRef}
              restRoom={selectedRestRoom}
              setSelectedRestRoom={setSelectedRestRoom}
              setMapCenter={setMapCenter}
              setZoomLevel={setZoomLevel}
            />
          )}

          {
            <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
              {(clusterer) => (
                <div>
                  {roadEvents?.map((event: any, index: number) => {
                    if (
                      (event.EventType === 'accidentsAndIncidents' &&
                        checkedItems['incidents']) ||
                      (event.EventType === 'roadwork' &&
                        checkedItems['construction']) ||
                      (event.EventType === 'specialEvents' &&
                        checkedItems['specialEvents'])
                    ) {
                      return (
                        <Marker
                          key={index}
                          clusterer={clusterer}
                          position={{
                            lat: event.Latitude,
                            lng: event.Longitude
                          }}
                          onClick={() => {
                            if (
                              selectedCommonEvent &&
                              selectedCommonEvent.ID === event.ID
                            ) {
                              setSelectedCommonEvent(null);
                            } else {
                              setSelectedCommonEvent(event);
                            }
                          }}
                          icon={
                            event.EventType === 'accidentsAndIncidents'
                              ? mapIncidents
                              : event.EventType === 'roadwork'
                              ? mapConstruction
                              : mapSpecialEvents
                          }
                        />
                      );
                    } else return <></>;
                  })}
                </div>
              )}
            </MarkerClusterer>
          }

          {selectedCommonEvent && (
            <CommonEventsTooltip
              markerOpenRef={MarkerOpenStateRef}
              commonEvent={selectedCommonEvent}
              setSelectedCommonEvent={setSelectedCommonEvent}
              setMapCenter={setMapCenter}
              setZoomLevel={setZoomLevel}
            />
          )}

          {!isRegisteredUsers &&
            !showSideBar &&
            !isDrawing &&
            !isCloseEvent &&
            closuresData?.results.length &&
            closuresData?.results.map((closure: any, index: number) => {
              if (
                !currentUser?.user_permission_set.role_names.includes(
                  'Admin'
                ) &&
                !currentUser?.user_permission_set.role_names.includes(
                  'Read Only Admin'
                ) &&
                currentUser?.user_permission_set.role_names.includes(
                  'IPAWS Admin'
                ) &&
                closure.alert_status === 'not_submitted'
              )
                return <></>;
              return (
                <ClosureMarker key={index} tabIndex={0}>
                  <Marker
                    title={removeEventManagedByLabel(
                      closure.wea_short_msg_english
                    )}
                    position={{
                      lat: getCentroid(closure.geometry.coordinates[0])[1],
                      lng: getCentroid(closure.geometry.coordinates[0])[0]
                    }}
                    icon={
                      selectedCard?.id === closure.id
                        ? SelectedTransportationIcon
                        : TransportationIcon
                    }
                    onLoad={(marker) => {
                      closuresMarkersRef.current[closure?.id] = marker;
                    }}
                    animation={window.google.maps.Animation.DROP}
                    onClick={() => {
                      if (selectedMarker === closure?.id) {
                        closureInfoWindowRef.current?.close();
                        setSelectedMarker(null);
                        return;
                      }
                      setSelectedCard(closure);
                      setSelectedMarker(closure?.id);
                      closureInfoWindowRef.current?.open(
                        mapRef.current,
                        closuresMarkersRef.current[closure?.id]
                      );
                    }}
                  />
                </ClosureMarker>
              );
            })}
          <InfoWindow
            onCloseClick={() => setSelectedMarker(null)}
            position={{ lat: 0, lng: 0 }}
            onLoad={(infoWindow) => {
              closureInfoWindowRef.current = infoWindow;
              infoWindow.close();
            }}
          >
            {popupWindow(selectedMarker)}
          </InfoWindow>

          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={60}
            maxZoom={5}
          >
            {(clusterer) => (
              <div>
                {isRegisteredUsers &&
                  registeredUsersData?.results?.map(
                    (user: any, index: number) => {
                      if (
                        user.is_active &&
                        user.location &&
                        user.location.coordinates?.length
                      ) {
                        return (
                          <Marker
                            // ref={MarkerExcludedRef}
                            key={user.id}
                            clusterer={clusterer}
                            position={{
                              lat: user.location.coordinates[0],
                              lng: user.location.coordinates[1]
                            }}
                            onClick={() => {
                              setselectedRegisteredUser(user);
                              setSelectedUser(index);
                              if (!(selectedRows && selectedRows[index])) {
                                setActiveTabName(null);
                                const data = { ...selectedRows };
                                data[index] = true;
                                setSelectedRows(data);
                              }
                            }}
                            icon={
                              selectedRegisteredUser?.id === user.id
                                ? SelectedRegisteredUser
                                : selectedRows && selectedRows[index]
                                ? SelectedUser
                                : RegisteredUser
                            }
                          >
                            {localStorage.getItem('fullScreen') !== 'true' ? (
                              <UserDetailsPopupModal
                                isOpen={selectedUser === index}
                                selectedClosure={selectedClosure}
                                userDetails={user}
                                setSelectedUser={setSelectedUser}
                                style={customStyles}
                                isBodyPaddingNone
                              />
                            ) : (
                              selectedUser === index && (
                                <InfoWindow
                                  onCloseClick={() => setSelectedUser(null)}
                                  position={{
                                    lat: user.location.coordinates[0],
                                    lng: user.location.coordinates[1]
                                  }}
                                >
                                  {popupWindow(selectedClosure, user)}
                                </InfoWindow>
                              )
                            )}
                          </Marker>
                        );
                      }
                      return <></>;
                    }
                  )}
              </div>
            )}
          </MarkerClusterer>

          {isCloseEvent &&
            registeredUsersLocation &&
            registeredUsersLocation?.map((user, index: number) => {
              return (
                <>
                  <Marker
                    key={index}
                    position={{
                      lat: user.location[1],
                      lng: user.location[0]
                    }}
                    icon={RegisteredUser}
                  ></Marker>
                </>
              );
            })}

          {isCloseEvent && (
            <Polygon
              path={getPolygonPath(selectedClosure?.geometry?.coordinates[0])}
              options={{
                strokeWeight: 2,
                fillColor: 'red',
                fillOpacity: 0.2,
                strokeColor: 'black',
                zIndex: 400
              }}
            />
          )}

          {drawnPolygon && (
            <Polygon
              onLoad={onPolygonLoad}
              path={getPolygonPath(drawnPolygon)}
              options={{
                fillColor: 'red',
                fillOpacity: 0.2,
                strokeWeight: 3,
                strokeColor: 'red',
                clickable: false,
                zIndex: 400,
                editable: props.isEdit
              }}
              onMouseUp={onEdit}
            />
          )}
          {/* Polygon to highlight PA state */}
          {/* <Polygon
          path={convertGeoJSONToLatLng(PAStateBoundary)}
          options={{ strokeWeight: 4, fillOpacity: 0 }}
        /> */}

          {!isRegisteredUsers && (
            <ul className="custom-legend-wrapper">
              <li>
                {STRINGS.TRAFFIC}
                <Switch
                  selected={isSwitchSelected}
                  onClick={handleSwitchToggle}
                  {...switchStyles}
                />
              </li>
              {legendData.map((item: any, index: number) => {
                return (
                  <li key={index}>
                    {item.legend_name === 'Closed' ? (
                      <hr className="dashed"></hr>
                    ) : (
                      <span>{generateLegendBox(item.color)}</span>
                    )}
                    <span>{item.legend_name}</span>
                  </li>
                );
              })}
            </ul>
          )}
          {!isRegisteredUsers && (
            <div className="filter-btn" ref={excludedElement}>
              <Button {...btnStyles.secondary} onClick={legendDropdown}>
                <Icon
                  icon="filters"
                  className="mr-3"
                  stroke={`${colorState.icon.brand.primary}`}
                ></Icon>
                {STRINGS.LEGEND}
              </Button>
              {isLegendDropDown && (
                <div ref={openStateElement}>
                  <LegendDropdownMenu
                    isDropdownHeight={props.isDropdownHeight}
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    setIsLegendDropDown={setIsLegendDropdown}
                    handleLayerToggle={handleLayerToggle}
                    handleClear={handleLegendsClear}
                  />
                </div>
              )}
            </div>
          )}
        </GoogleMap>
      </MapComponentWrapper>
    </GoogleMapsErrorBoundary>
  ) : (
    <PALoader />
  );
};
export default GoogleMapsComponent;
