import { createSlice } from '@reduxjs/toolkit';
import {
  FetchMessagesData,
  Messages,
  MessagesDataReceived,
  setActiveStatusAction,
  setNewMessageAction
} from '../actions/Messages';

const initialState: Messages = {
  messagesData: null,
  isFetchingMessagesData: false,
  newMessage: null,
  isActiveStatusChanged: false
};

const MessagesSlice = createSlice({
  name: 'Messages',
  initialState,
  reducers: {
    getMessagesData: (state, _action: FetchMessagesData) => {
      state.isFetchingMessagesData = true;
    },
    messagesDataReceived: (state, action: MessagesDataReceived) => {
      state.isFetchingMessagesData = false;
      state.newMessage = null;
      if (action.payload.shouldAppend) {
        action.payload.messagesData = {
          ...state.messagesData,
          ...action.payload.messagesData
        };
      }
      state.messagesData = action.payload.messagesData;
    },
    setNewMessage: (state, action: setNewMessageAction) => {
      state.newMessage = action.payload.newMessage;
    },
    setActiveStatus: (state, action: setActiveStatusAction) => {
      state.isActiveStatusChanged = action.payload.isActiveStatusChanged;
    }
  }
});

export const {
  getMessagesData,
  messagesDataReceived,
  setNewMessage,
  setActiveStatus
} = MessagesSlice.actions;
export default MessagesSlice.reducer;
