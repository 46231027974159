import { FC } from 'react';
import { Select, SingleDatePicker } from '../../Universal/NovusDSImports';
import {
  selectStyles,
  singleDatePickerStyles
} from '../../Universal/NovusDSImports/variants';
import { Controller, useFormContext } from 'react-hook-form';
import {
  getYearDropdownOptions,
  monthsListDropdownOptions
} from '../../Constants/constants';

interface ResourcesDateSelectorProps {
  name: string;
  labelPrefix: string;
}

const ResourcesDateSelector: FC<ResourcesDateSelectorProps> = (props) => {
  const { name, labelPrefix } = props;
  const {
    control,
    watch,
    getValues,
    trigger,
    formState: { errors, isSubmitted }
  } = useFormContext();

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return watch('reportType') === 'full_details' ||
            watch('intentOption') === 'view_map' ? (
            <SingleDatePicker
              {...field}
              label={`${labelPrefix} Date`}
              selected={field.value}
              onChange={(e) => {
                const newDate = new Date(e);
                newDate.setHours(name === 'startDate' ? 0 : 22, 0, 0, 0);
                field.onChange(newDate);
                if (isSubmitted && name === 'startDate') {
                  trigger('endDate');
                }
              }}
              {...singleDatePickerStyles}
            />
          ) : (
            <div>
              {watch('reportType') === 'events_by_month' && (
                <Select
                  {...field}
                  asterisk
                  options={monthsListDropdownOptions}
                  displayName={`${labelPrefix} Month`}
                  placeholder="Please Select Month"
                  // value={monthsListDropdownOptions[field.value?.getMonth()]}
                  value="Hola"
                  onChange={(e) => {
                    const newDate = getValues(name) || new Date();
                    newDate.setMonth(parseInt(e.value));
                    newDate.setHours(name === 'startDate' ? 0 : 22, 0, 0, 0);
                    field.onChange(newDate);
                    if (isSubmitted && name === 'startDate') {
                      trigger('endDate');
                    }
                  }}
                  {...selectStyles}
                />
              )}
              <Select
                {...field}
                asterisk
                options={getYearDropdownOptions()}
                displayName={`${labelPrefix} Year`}
                placeholder="Please Select Year"
                value={getYearDropdownOptions().find(
                  (year) => year.value === field.value?.getFullYear()
                )}
                onChange={(e) => {
                  const newDate = getValues(name) || new Date();
                  newDate.setYear(e.value);
                  newDate.setHours(name === 'startDate' ? 0 : 22, 0, 0, 0);
                  field.onChange(newDate);
                  if (isSubmitted && name === 'startDate') {
                    trigger('endDate');
                  }
                }}
                {...selectStyles}
              />
            </div>
          );
        }}
      />
      <div className="error-text">{errors?.[name]?.message?.toString()}</div>
    </div>
  );
};

export default ResourcesDateSelector;
