// Hooks
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';

// Utilities
import {
  getPermission,
  removeEventManagedByLabel
} from '../../CommonUtilities/CommonUtilities';
import { STRINGS } from '../../Constants/ConstantStrings';
import {
  addClosureQuestion,
  addEditCategory,
  getclosureCategories,
  notifyNewQuestions,
  requestQuestionsLibraryData,
  showNewlyAddedQuestionPopup
} from '../../Store/reducers/ClosureQuestions';

// Components
import Card from '../../SharedComponets/Card';
import TableTitle from '../../SharedComponets/TableTitle';
import { Button, Icon, Select } from '../../Universal/NovusDSImports';
import AddEditClosureQuestionModal from './AddEditClosureQuestionModal';
import { EventQuestionLibraryTable } from './EventQuestionLibraryTable';
import { EventQuestionTable } from './EventQuestionTable';

// Styles
import { unSelectSelectedRows } from '../../Store/reducers/Common';
import { colorState } from '../../Universal/Foundation';
import {
  btnStyles,
  modalStyles,
  selectStyles
} from '../../Universal/NovusDSImports/variants';
import { TabItems, TableWrapper } from '../globalStyles';

// Types
import ConfirmationPopupModal from '../../SharedComponets/ConfirmationPopupModal';
import { RootState } from '../../store';
import AddNewCategory from './AddNewCategory';
import CategoryListTable from './CategoryListTable';
import { addClosure } from '../../Store/reducers/Closures';

type SelectedEventType = 'event' | 'library' | 'categoryList';

const Questions = (props) => {
  return (
    <Card className="w-100 d-flex">
      <EventQuestions {...props} />
    </Card>
  );
};

export const EventQuestions = (props: any) => {
  const { isQuestionLibrary, setIsRegisteredUsers } = props;
  const dispatch = useReduxDispatch(),
    questionslibraryData = useSelector(
      (state: RootState) => state.Questions.questionslibraryData
    ),
    currentUser = useReduxSelector(
      (State: RootState) => State.Common.currentUserDetails
    ),
    categories = useSelector((state: any) => state.Questions.categories),
    isShowNewlyAddedQuestionsToUsers = useSelector(
      (state: RootState) => state.Questions.isShowNewlyAddedQuestionsToUsers
    ),
    newQuestionId = useSelector(
      (state: RootState) => state.Questions.newQuestionId
    ),
    isMobileScreen = useReduxSelector(
      (state: RootState) => state.Common.isMobileScreen
    ),
    selectedClosure = useReduxSelector(
      (state: RootState) => state.Common.selectedClosure
    ),
    [isConfirmationModalOpen, setIsConfirmationModalOpen] =
      useState<boolean>(false),
    [selectedCategory, setSelectedCategory] = useState<any>(),
    [categoriesList, setCategoriesList] = useState<any>(),
    [activeTabName, setActiveTabName] = useState<SelectedEventType>('event'),
    [categoryList, setCategoryList] = useState<boolean>(false),
    [isEdit, setIsEdit] = useState<boolean>(false),
    [isOpenModal, setIsOpenModal] = useState<boolean>(false),
    [addQuestionInCurrentCategory, setAddQuestionInCurrentCategory] =
      useState<boolean>(false),
    [addNewCategorySubmit, setAddNewCategorySubmit] = useState<boolean>(false),
    [isModalOpen, setIsModalOpen] = useState<boolean>(false),
    [categoryEditData, setCategoryEditData] = useState<any>(),
    [isCategoryEdit, setIsCategoryEdit] = useState<boolean>(false),
    [categoryData, setCategoryData] = useState<string>(),
    [editData, setEditData] = useState<any>(),
    [updatedTableData, setUpdatedTableData] = useState<any>(),
    [selectedRows, setSelectedRows] = useState<any>({});

  useEffect(() => {
    if (!selectedClosure) {
      setActiveTabName('library');
    }
    if (getPermission(currentUser, 'view_fieldslibrary') && !categories?.length)
      dispatch(getclosureCategories({ limit: 'all' }));
  }, [dispatch, currentUser, selectedClosure]);

  useEffect(() => {
    if (isShowNewlyAddedQuestionsToUsers) {
      setIsConfirmationModalOpen(true);
    } else {
      clearSelectedRows();
    }
  }, [dispatch, isShowNewlyAddedQuestionsToUsers]);

  // useEffect(() => {
  //   if (selectedCategory && getPermission(currentUser, 'view_field')) {
  //     dispatch(
  //       requestQuestionsLibraryData({
  //         categoryId: selectedCategory.id,
  //         limit: questionsLibraryTableRowLimit,
  //         offset:0
  //       })
  //     );
  //   }
  // }, [dispatch, selectedCategory, currentUser]);

  const clearSelectedRows = useCallback(() => {
    if (selectedRows && Object.keys(selectedRows).length) {
      setSelectedRows({});
      dispatch(unSelectSelectedRows({ shouldUnselect: true }));
    }
  }, [dispatch, selectedRows]);

  useEffect(() => {
    if (categories?.length) {
      const categoryIdList: Array<any> = [];
      categories?.forEach((category: any) => {
        categoryIdList.push(category.id);
      });
      setCategoriesList([
        { name: 'All', id: `${categoryIdList}`, value: 'All', label: 'All' },
        ...categories
      ]);
    }
  }, [categories]);

  useEffect(() => {
    if (categoriesList?.length) {
      setSelectedCategory(categoriesList[0]);
    }
  }, [categoriesList]);

  const tabClickHandler = (tabName: SelectedEventType) => {
    setCategoryList(false);
    clearSelectedRows();
    setActiveTabName(tabName);
  };

  const renderTables = () => {
    switch (activeTabName) {
      case 'event':
        return (
          <EventQuestionTable
            selectedCategory={selectedCategory}
            activeTabName={activeTabName}
            setIsEdit={setIsEdit}
            setEditData={setEditData}
            setIsOpenModal={setIsOpenModal}
            selectedClosure={selectedClosure}
            addQuestion={addQuestion}
            isQuestionLibrary={isQuestionLibrary}
            setIsRegisteredUsers={setIsRegisteredUsers}
            updatedTableData={updatedTableData}
            setUpdatedTableData={setUpdatedTableData}
          />
        );
      case 'library':
        if (categoryList) {
          return (
            <CategoryListTable
              setSelectedRows={setSelectedRows}
              setIsModalOpen={setIsModalOpen}
              setIsCategoryEdit={setIsCategoryEdit}
              setCategoryEditData={setCategoryEditData}
              categoryEditData={categoryEditData}
              isCategoryEdit={isCategoryEdit}
              setCategoryData={setCategoryData}
              addCategory={addCategory}
              setAddNewCategorySubmit={setAddNewCategorySubmit}
            />
          );
        }
        return (
          <EventQuestionLibraryTable
            selectedCategory={selectedCategory}
            activeTabName={activeTabName}
            setIsEdit={setIsEdit}
            setEditData={setEditData}
            setIsOpenModal={setIsOpenModal}
            setSelectedRows={setSelectedRows}
            selectedClosure={selectedClosure}
            addQuestion={addQuestion}
            isQuestionLibrary={!selectedClosure}
            selectedRows={selectedRows}
          />
        );
      default:
        return '';
    }
  };

  const newCategorySubmit = () => {
    setAddNewCategorySubmit(true);
    if (!isCategoryEdit) {
      if (categoryData) {
        setIsModalOpen(false);
        setCategoryData('');
        const payload = {
          name: categoryData,
          isEdit: false
        };
        dispatch(addEditCategory(payload));
      }
    } else {
      if (categoryData) {
        setIsCategoryEdit(false);
        setCategoryEditData(null);
        setIsModalOpen(false);
        const payload = {
          name: categoryData,
          id: categoryEditData?.id,
          isEdit: true
        };
        dispatch(addEditCategory(payload));
      }
    }
  };

  const customStyles = {
    content: {
      minWidth: '30%',
      minHeight: '20vh'
    },
    overlay: {
      backgroundColor: 'rgba(114, 114, 114, 0.294)'
    }
  };

  const renderCategoryFooter = () => {
    return (
      <div className="gap-3 d-flex">
        <Button {...btnStyles.secondary} onClick={() => setIsModalOpen(false)}>
          {STRINGS.CANCEL}
        </Button>
        <Button {...btnStyles.primary} onClick={newCategorySubmit}>
          {isCategoryEdit ? STRINGS.UPDATE : STRINGS.SAVE}
        </Button>
      </div>
    );
  };

  const addCategory = (isAddNewCategory) => {
    setAddNewCategorySubmit(false);
    setCategoryData('');
    setIsCategoryEdit(false);
    setCategoryEditData(null);
    setIsModalOpen(isAddNewCategory);
  };

  const addQuestion = (isCategory: boolean) => {
    setIsOpenModal(true);
    setAddQuestionInCurrentCategory(isCategory);
  };

  const handleClose = () => {
    setIsOpenModal(false);
    setIsEdit(false);
    setEditData(null);
    if (addQuestionInCurrentCategory) setAddQuestionInCurrentCategory(false);
  };

  const getQuestionsIdsArray = () => {
    const idArray: any = [];
    updatedTableData?.map((row: any) => {
      idArray.push(row.original.id);
    });
    return idArray;
  };

  const getIsRearranged = () => {
    let isRearranged = false;
    getQuestionsIdsArray()?.forEach((id: number, index: number) => {
      if (id !== selectedClosure?.sort_order[index]) isRearranged = true;
    });
    return isRearranged;
  };

  const handleSaveClick = () => {
    const payload = {
      id: selectedClosure?.id,
      sort_order: getQuestionsIdsArray()
    };
    dispatch(addClosure({ isEdit: true, params: payload }));
  };

  const addToEvent = () => {
    setActiveTabName('event');
    const params: any = { ...selectedClosure };
    const newQuestionIds: any = [];
    Object.keys(selectedRows).forEach((key: any) => {
      newQuestionIds.push(questionslibraryData?.results?.[key].id);
    });
    params['questions'] = [...newQuestionIds];
    params['save_for_future'] = false;
    params['status_id'] = selectedClosure?.status.id;
    params['alert_status'] = selectedClosure?.alert_status;
    dispatch(
      addClosureQuestion({
        params,
        closureId: selectedClosure?.id,
        activeTab: 'event',
        isFromLibraryToClosure: true,
        isUpdate: false
      })
    );
  };
  const AddQuestionStyles = {
    content: {
      position: 'absolute',
      minWidth: isMobileScreen ? '95%' : '436px',
      maxWidth: isMobileScreen ? '95%' : '436px',
      boxShadow: '0px 10px 22px rgba(45,77,108,0.25)',
      margin: '10px',
      right: isMobileScreen ? 'unset' : '4px',
      borderRadius: '16px',
      height: '100%',
      maxHeight: '98%'
    }
  };

  const [disableCopyButton, setDisableCopyButton] = useState<boolean>(true);

  const customConfirmationStyles = {
    content: {
      minWidth: '20%',
      minHeight: '10vh'
    },
    overlay: {
      backgroundColor: 'rgba(114, 114, 114, 0.294)'
    }
  };

  const handleCloseConfirm = () => {
    setIsConfirmationModalOpen(false);
    let questionsList: any = [];
    if (!newQuestionId?.length) {
      Object.keys(selectedRows).forEach((key: any) => {
        questionsList.push(questionslibraryData?.[key].id);
      });
    } else {
      questionsList = [...newQuestionId];
    }
    const payload = {
      closureId: selectedClosure.id,
      questions_list: questionsList
    };
    dispatch(notifyNewQuestions(payload));
    dispatch(
      showNewlyAddedQuestionPopup({
        isShowNewlyAddedQuestionsToUsers: false,
        newQuestionId: null
      })
    );
    clearSelectedRows();
  };

  const renderFooter = () => {
    return (
      <div className="gap-3 d-flex">
        <Button
          onClick={() => {
            setIsConfirmationModalOpen(false);
            dispatch(
              showNewlyAddedQuestionPopup({
                isShowNewlyAddedQuestionsToUsers: false,
                newQuestionId: null
              })
            );
          }}
          {...btnStyles.tertiary}
        >
          {STRINGS.CANCEL}
        </Button>
        <Button onClick={handleCloseConfirm} {...btnStyles.danger}>
          {STRINGS.YES_SEND}
        </Button>
      </div>
    );
  };

  useEffect(() => {
    setDisableCopyButton(isSelectedQuestionExist());
  }, [selectedRows, setDisableCopyButton]);

  const compareObjs = (data: any, questionslibraryData: any, val: any) =>
    data.values.abbr_name === questionslibraryData[val].abbr_name &&
    data.values.display_name === questionslibraryData[val].display_name &&
    data.values.field_type === questionslibraryData[val].field_type;

  const isSelectedQuestionExist = () => {
    if (
      Object.keys(selectedRows).length &&
      questionslibraryData &&
      Object.keys(questionslibraryData)?.length
    ) {
      return Object.keys(selectedRows).some((key1) =>
        updatedTableData.some((obj2) =>
          compareObjs(obj2, questionslibraryData?.results, key1)
        )
      );
    } else {
      return true;
    }
  };

  return (
    <Card className="w-100 d-flex">
      <TableWrapper id="table-group">
        <TableTitle
          title={
            isQuestionLibrary ? STRINGS.QUESTION_LIBRARY : STRINGS.QUESTIONS
          }
          message={removeEventManagedByLabel(
            selectedClosure?.wea_short_msg_english
          )}
        >
          {(isQuestionLibrary && getPermission(currentUser, 'add_field')) ||
            (!isQuestionLibrary &&
              getPermission(currentUser, 'add_new_closure_question') && (
                <Button
                  onClick={() =>
                    categoryList ? addCategory(true) : addQuestion(false)
                  }
                  title={
                    categoryList
                      ? STRINGS.ADD_NEW_CATEGORY
                      : STRINGS.ADD_NEW_QUESTION
                  }
                  {...btnStyles.primary}
                >
                  <Icon
                    icon={'help'}
                    fill={colorState.icon.default['primary-inverse']}
                    stroke={'none'}
                  />
                  {categoryList
                    ? STRINGS.ADD_NEW_CATEGORY
                    : STRINGS.ADD_NEW_QUESTION}
                </Button>
              ))}
        </TableTitle>
        <div className="gap-2" id="table-tabs">
          {selectedClosure && (
            <div className="left-wrapper gap-2" role="tablist">
              <TabItems
                role="tab"
                id={'tab-event'}
                tabIndex={0}
                onClick={() => tabClickHandler('event')}
                onKeyDown={(event: any) => {
                  if (event.key === 'Enter') {
                    tabClickHandler('event');
                  }
                }}
                selectedtab={activeTabName === 'event'}
              >
                {STRINGS.THIS_EVENT_QUESTIONS}
              </TabItems>

              <TabItems
                role="tab"
                id={'tab-library'}
                tabIndex={0}
                onClick={() => tabClickHandler('library')}
                onKeyDown={(event: any) => {
                  if (event.key === 'Enter') {
                    tabClickHandler('library');
                  }
                }}
                selectedtab={activeTabName === 'library'}
              >
                {STRINGS.QUESTION_LIBRARY}
              </TabItems>
            </div>
          )}

          {activeTabName === 'library' && (
            <>
              <div className="left-wrapper gap-2" role="tablist">
                {!selectedClosure && (
                  <>
                    <TabItems
                      role="tab"
                      id={'tab-library'}
                      tabIndex={0}
                      onClick={() => tabClickHandler('library')}
                      onKeyDown={(event: any) => {
                        if (event.key === 'Enter') {
                          setCategoryList(true);
                          tabClickHandler('library');
                        }
                      }}
                      selectedtab={activeTabName === 'library' && !categoryList}
                    >
                      {STRINGS.QUESTION_LIBRARY}
                    </TabItems>
                    <TabItems
                      role="tab"
                      id={'tab-library'}
                      tabIndex={0}
                      onClick={() => {
                        setCategoryList(true);
                      }}
                      onKeyDown={(event: any) => {
                        if (event.key === 'Enter') {
                          setCategoryList(true);
                        }
                      }}
                      selectedtab={categoryList}
                    >
                      {'Category List'}
                    </TabItems>
                  </>
                )}
              </div>
              <div className="right-wrapper gap-2">
                {!categoryList && (
                  <>
                    <h3>{STRINGS.SHOW_QUESTION_IN} : </h3>
                    <div className="custom-select-width">
                      <Select
                        displayName={''}
                        placeHolder={STRINGS.SELECT_CATEGORY}
                        options={categoriesList}
                        value={selectedCategory}
                        onChange={setSelectedCategory}
                        asterisk={false}
                        {...selectStyles}
                      />
                    </div>
                    {selectedClosure &&
                      getPermission(
                        currentUser,
                        'add_new_closure_question'
                      ) && (
                        <Button
                          disabled={disableCopyButton}
                          onClick={() => addToEvent()}
                          {...btnStyles.secondary}
                        >
                          {STRINGS.COPY_TO_THIS_EVENT}
                        </Button>
                      )}
                  </>
                )}
              </div>
            </>
          )}
          {activeTabName === 'event' && (
            <Button
              {...btnStyles.primary}
              onClick={handleSaveClick}
              disabled={!getIsRearranged()}
            >
              {STRINGS.SAVE}
            </Button>
          )}
        </div>

        <div id="table">{renderTables()}</div>
        <AddEditClosureQuestionModal
          isOpen={isOpenModal}
          isEdit={isEdit}
          showHeader={true}
          onClose={handleClose}
          postModalClose={handleClose}
          categories={categories}
          selectedCategory={selectedCategory}
          title={
            isEdit
              ? STRINGS.EDIT
              : activeTabName === 'event'
              ? STRINGS.ADD_QUESTION_TO_CLOSURE
              : addQuestionInCurrentCategory
              ? `Add Question to ${selectedCategory.label}`
              : STRINGS.ADD_QUESTION_TO_LIBRARY
          }
          isBodyPaddingNone={true}
          activeTab={activeTabName}
          editData={editData}
          selectedClosure={selectedClosure}
          setSelectedCategory={setSelectedCategory}
          addQuestionInCurrentCategory={addQuestionInCurrentCategory}
          style={AddQuestionStyles}
          {...modalStyles}
        />
        <ConfirmationPopupModal
          isOpen={isConfirmationModalOpen}
          showHeader={false}
          description={STRINGS.DO_YOU_WANT_SEND_NEWLY_ADDED_QUESTION}
          showFooter={renderFooter()}
          style={customConfirmationStyles}
          postModalClose={handleCloseConfirm}
          closeModal={() => handleCloseConfirm()}
          showBorderTop={false}
          isBodyPaddingNone={true}
          customFooter
        />
        <AddNewCategory
          isOpen={isModalOpen}
          title={isCategoryEdit ? STRINGS.EDIT_CATEGORY : STRINGS.ADD_CATEGORY}
          showHeader={true}
          showFooter={renderCategoryFooter()}
          style={customStyles}
          postModalClose={() => setIsModalOpen(false)}
          closeModal={() => setIsModalOpen(false)}
          showBorderTop={true}
          categoryData={categoryData}
          addNewCategorySubmit={addNewCategorySubmit}
          setCategoryData={setCategoryData}
          isBodyPaddingNone={false}
          categoryEditData={categoryEditData}
          isCategoryEdit={isCategoryEdit}
          {...modalStyles}
        />
      </TableWrapper>
    </Card>
  );
};

export default Questions;
