import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFormattedDateAndTime } from '../../CommonUtilities/CommonUtilities';
import { STRINGS } from '../../Constants/ConstantStrings';
import Card from '../../SharedComponets/Card';
import ImageNotAvailable from '../../SharedComponets/ImageNotAvailable/ImageNotAvailable';
import NoDataFound from '../../SharedComponets/NoDataFound';
import {
  getPublicClosuresData,
  registerSubmittedSuccessfully,
  selectedRegisterClosure
} from '../../Store/reducers/PublicView';
import { useReduxSelector } from '../../Store/reduxHooks';
import { NoClosuresData } from '../../Universal/Assets';
import { colorState } from '../../Universal/Foundation';
import { Button, Icon, Lozenge } from '../../Universal/NovusDSImports';
import { btnStyles } from '../../Universal/NovusDSImports/variants';
import { BASE_URL } from '../../settings';
import { RootState } from '../../store';
import {
  HighlightMessage,
  PublicMainContainer,
  PublicViewContainerStyles
} from './styles';
import PALoader from '../../SharedComponets/PALoader';
import { useTextTruncation } from '../../Hooks/useTextTruncation';
import TermsAndConditions from './TermsConditions';
import { isProdInstance } from '../../settings';

const PublicView: React.FC = () => {
  const dispatch = useDispatch(),
    history = useHistory(),
    [showTermsAndConditions, setShowTermsAndConditions] =
      useState<boolean>(false),
    // [cardsData, setCardsData] = useState<cardDataType>(),
    publicClosuresData = useReduxSelector(
      (state: RootState) => state.PublicView.publicClosuresData
    ),
    isfetchingPublicClosuresData = useReduxSelector(
      (state: RootState) => state.PublicView.isfetchingPublicClosuresData
    ),
    isNewQuestionsSubmit = useReduxSelector(
      (state: RootState) => state.PublicView.isNewQuestionsSubmit
    ),
    isRegisterSuccesful = useReduxSelector(
      (state: RootState) => state.PublicView.isRegisterSuccesful
    );

  const setAxiosDefaults = () => {
    axios.defaults.baseURL = BASE_URL;
    axios.defaults.headers.patch['Content-Type'] =
      'application/json; charset=utf-8';
    axios.defaults.headers.put['Content-Type'] =
      'application/json; charset=utf-8';
    axios.defaults.headers.post['Content-Type'] =
      'application/json; charset=utf-8';
  };

  useEffect(() => {
    setAxiosDefaults();
    dispatch(getPublicClosuresData({}));
  }, [dispatch]);

  useEffect(() => {
    if (isRegisterSuccesful) {
      setTimeout(() => {
        dispatch(
          registerSubmittedSuccessfully({
            isRegisterSuccesful: false,
            isNewQuestionsSubmit: false
          })
        );
      }, 5000);
    }
  }, [dispatch, isRegisterSuccesful]);

  const onNavigate = (closureData: any, pageName) => {
    dispatch(selectedRegisterClosure(closureData));
    switch (pageName) {
      case 'manageLocation':
        history.push(`/ManageLocation/?closure_id=${closureData.id}`);
        break;
      case 'registration':
        history.push(`/Registration?closure_id=${closureData.id}`);
        break;
      case 'messageHistory':
        history.push(`/MessageHistory?closure_id=${closureData.id}`);
    }
  };
  const { isExpanded, isTruncated, messageRef, readMore, toggleExpand } =
    useTextTruncation();

  const mainMessageText = `${
    isProdInstance ? '' : '(Training System) '
  }The following ${
    publicClosuresData?.length
  } emergency closures have been activated. Click “Register for this Closure” if you are within the shadow boundary of a closure. Registration will advise Pennsylvania authorities of your location (if you opt-in to provide it) and allow them to provide you with updates as they are available. Please refresh this page regularly for updates.`;
  const onBack = () => {
    setShowTermsAndConditions(false);
  };
  const onTermsAndConditionsClick = () => {
    setShowTermsAndConditions(true);
  };
  return (
    <PublicViewContainerStyles>
      {!showTermsAndConditions && (
        <>
          <div className="d-flex overflow-auto flex-column flex-grow-1 h-100 w-100">
            <PublicMainContainer>
              {isRegisterSuccesful && (
                <HighlightMessage messageType={'success'}>
                  <Icon
                    icon={'check_circle'}
                    stroke={colorState.icon.success.primary}
                    className="me-2"
                  />
                  {isNewQuestionsSubmit
                    ? STRINGS.UPDATED_QUESTION_ANSWERED_SUCCESSFULLY
                    : STRINGS.REGISTRATION_COMPLETED +
                      STRINGS.THANKYOU_YOUR_INFORMATION_HAS_BEEN_SUBMITTED +
                      STRINGS.IF_YOU_HAVE_AN_IMMEDIATE}
                </HighlightMessage>
              )}
              {publicClosuresData?.length ? (
                <>
                  <HighlightMessage
                    isExpanded={isExpanded}
                    aria-label="content"
                    tabIndex={0}
                  >
                    <span
                      className={`truncated-text ${
                        isExpanded && isTruncated ? 'expanded-text' : ''
                      }`}
                      ref={messageRef}
                    >
                      {mainMessageText}
                    </span>
                    {readMore && (
                      <span
                        className="toggle-expand"
                        onClick={toggleExpand}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            toggleExpand();
                          }
                        }}
                        role="textbox"
                        aria-label={readMore}
                        tabIndex={0}
                      >
                        {readMore}
                      </span>
                    )}
                  </HighlightMessage>
                  <div className="row">
                    {publicClosuresData &&
                      publicClosuresData.map((card, index) => {
                        // Concatenate date and time with a space separator
                        const formattedDateTime = getFormattedDateAndTime(
                          card?.modified
                        );
                        return (
                          <div
                            className="col-12 col-sm-6 col-lg-4 mb-4"
                            key={index}
                          >
                            <Card key={index} className="closure-cards">
                              <div className="map-section">
                                {card?.closure_image ? (
                                  <img
                                    src={BASE_URL + card?.closure_image}
                                    alt="Closure"
                                    loading="lazy"
                                  />
                                ) : (
                                  <ImageNotAvailable />
                                )}
                              </div>
                              <div className="middle-section">
                                <div>
                                  <Lozenge
                                    variant="lowemphasis"
                                    type="danger"
                                    text="Closure"
                                  />
                                  <div className="incident-name">
                                    {card.wea_short_msg_english}
                                  </div>
                                </div>
                                <div className="description-section">
                                  <div>
                                    <p className="heading">Last Updated on:</p>
                                    <p className="sub-text">
                                      {formattedDateTime}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="heading">Description:</p>
                                    <p
                                      className="sub-text"
                                      title={card.description}
                                    >
                                      {card.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex flex-column gap-4">
                                <Button
                                  className="w-100"
                                  onClick={() =>
                                    onNavigate(card, 'messageHistory')
                                  }
                                  data-testid="messageHistory"
                                  {...btnStyles.tertiary}
                                >
                                  {STRINGS.MESSAGE_HISTORY}
                                </Button>
                                <Button
                                  {...btnStyles.secondary}
                                  className="w-100"
                                  data-testid="manageLocation"
                                  onClick={() =>
                                    onNavigate(card, 'manageLocation')
                                  }
                                >
                                  {STRINGS.MANAGE_LOCATION}
                                </Button>
                                <Button
                                  {...btnStyles.primary}
                                  className="w-100"
                                  data-testid="registerForClosure"
                                  onClick={() =>
                                    onNavigate(card, 'registration')
                                  }
                                >
                                  {STRINGS.REGISTER_FOR_THIS_CLOSURE}
                                </Button>
                              </div>
                            </Card>
                          </div>
                        );
                      })}
                  </div>
                </>
              ) : (
                <div className="d-flex flex-1 h-100 justify-content-center">
                  {isfetchingPublicClosuresData ? (
                    <PALoader />
                  ) : (
                    <NoDataFound
                      src={NoClosuresData}
                      description={STRINGS.NO_ACTIVE_CLOSURES}
                    />
                  )}
                </div>
              )}
              <div className="gap-3 d-flex flex-column align-items-center">
                <span
                  className="navigation-link"
                  onClick={onTermsAndConditionsClick}
                  aria-label="Policy,Terms and Conditions"
                  tabIndex={0}
                  role="link"
                  data-testid="termsAndConditions"
                >
                  {STRINGS.TERMS_AND_CONDITIONS_POLICY}
                </span>
              </div>
            </PublicMainContainer>
          </div>
        </>
      )}
      {showTermsAndConditions && <TermsAndConditions onBack={onBack} />}
    </PublicViewContainerStyles>
  );
};

export default PublicView;
