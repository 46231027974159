import { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearCookiesAndLocalstorage } from '../../CommonUtilities/CommonUtilities';
import { STRINGS } from '../../Constants/ConstantStrings';
import Grid from '../../SharedComponets/Grid';
import PasswordStrengthValidation from '../../SharedComponets/PasswordStrengthValidation';
import {
  passwordUpdate,
  resetPasswordStoreValues
} from '../../Store/reducers/ForgotPassword';
import {
  ArcadisFooterLogoImg,
  LoginBakgroundImg,
  LogoImg
} from '../../Universal/Assets';
import { colorState } from '../../Universal/Foundation';
import { Button, Icon, Input } from '../../Universal/NovusDSImports';
import {
  btnStyles,
  inputStyles
} from '../../Universal/NovusDSImports/variants';
import {
  BacgroundImageView,
  ErrorMessage,
  FlexCenter,
  FormContainer,
  GridContainer,
  PageContainer
} from '../Login/styles';
import { RootState } from '../../store';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormSegment } from '../globalStyles';
import PALoader from '../../SharedComponets/PALoader';

export default function CreateNewPassword() {
  const urlParams = new URLSearchParams(window.location.search),
    history = useHistory(),
    dispatch = useDispatch(),
    userUID = urlParams.get('UserUuid'),
    userToken = urlParams.get('Token'),
    [newPasswordVisibility, setNewPasswordVisibility] =
      useState<boolean>(false),
    [confirmPasswordVisibility, setConfirmPasswordVisibility] =
      useState<boolean>(false),
    [validationError, setValidationError] = useState<boolean | string>(false),
    isPasswordUpdateSuccess = useSelector(
      (state: RootState) => state.ForgotPassword.isPasswordUpdateSuccess
    ),
    onPasswordUpdateMessage = useSelector(
      (state: RootState) => state.ForgotPassword.onPasswordUpdateMessage
    );

  const resetPasswordSchema = yup.object().shape({
    confirmPassword: yup.string().required(STRINGS.YUP.PASSWORD_IS_REQUIRED),
    newPassword: yup.string().required(STRINGS.YUP.PASSWORD_IS_REQUIRED)
  });

  const getDefaultValues = () => ({
    newPassword: '',
    confirmPassword: ''
  });

  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(resetPasswordSchema)
  });

  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { isValid, isDirty, errors }
  } = methods;

  const watchNewPassword = watch('newPassword');
  const watchConfirmPassword = watch('confirmPassword');

  useEffect(() => {
    if (localStorage.getItem('isLoggedIn')) {
      clearCookiesAndLocalstorage();
    }
  }, []);

  useEffect(() => {
    if (watchConfirmPassword.length && !watchNewPassword.length) {
      setError('newPassword', { message: STRINGS.NEW_PASSWORD_REQUIRED });
    } else {
      clearErrors('newPassword');
    }
  }, [watchConfirmPassword, watchNewPassword]);

  useEffect(() => {
    if (isPasswordUpdateSuccess) {
      setTimeout(() => {
        history.push('/Login');
        dispatch(resetPasswordStoreValues());
      }, 5000);
    }
  }, [dispatch, history, isPasswordUpdateSuccess]);

  const onSubmit = (data: any) => {
    const characterTypeChecks = /[^A-Za-z\d#$%@.,!^]/;
    if (validationError) {
      setValidationError(false);
    }
    if (watchNewPassword !== watchConfirmPassword) {
      setValidationError(true);
    } else if (characterTypeChecks.test(watchNewPassword)) {
      setValidationError(STRINGS.PLEASE_USE_LEGAL_SYMBOLS);
    } else {
      const validateWithRegex =
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[#$%@.,!^])[A-Za-z\d#$%@.,!^]{16,}$/;
      if (
        validateWithRegex.test(watchNewPassword) &&
        userUID &&
        userToken &&
        watchNewPassword
      ) {
        const payload = {
          password: watchNewPassword,
          user: userUID,
          token: userToken
        };
        dispatch(passwordUpdate(payload));
      }
    }
  };

  return (
    <>
      <PageContainer>
        <BacgroundImageView>
          <img src={LoginBakgroundImg} alt="" loading="lazy" />
        </BacgroundImageView>
        <GridContainer className="d-flex flex-column justify-content-between">
          <div className="outer-login-grid">
            <Grid className="login-grid">
              <div className="d-flex flex-column justify-content-between h-100">
                <img src={LogoImg} alt="logo" id="logo-img" loading="lazy" />
                {/* <> */}
                <Suspense fallback={<PALoader />}>
                  {isPasswordUpdateSuccess ? (
                    <FormContainer>
                      <FlexCenter className="flex-column my-5">
                        <Icon
                          icon="check_circle"
                          stroke={`${colorState.icon.success.primary}`}
                          width={90}
                          height={74}
                        />
                        <p className="mt-4 text-center">
                          {onPasswordUpdateMessage}
                        </p>
                      </FlexCenter>
                    </FormContainer>
                  ) : (
                    <FormSegment onSubmit={handleSubmit(onSubmit)}>
                      <FormContainer>
                        <h1>{STRINGS.CREATE_NEW_PASSWORD}</h1>
                        <div className="mb-2">
                          <Controller
                            name={'newPassword'}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Input
                                id="newPassword"
                                data-testid="new_password"
                                value={value}
                                label={STRINGS.NEW_PASSWORD}
                                type={
                                  newPasswordVisibility ? 'text' : 'password'
                                }
                                onPaste={(e) => e.preventDefault()}
                                placeholder={STRINGS.ENTER_NEW_PASSWORD}
                                showPasswordIndicator={false}
                                iconLeft={false}
                                onChange={onChange}
                                {...inputStyles}
                                onRightIconActionClick={() =>
                                  setNewPasswordVisibility(
                                    !newPasswordVisibility
                                  )
                                }
                                iconRight={
                                  newPasswordVisibility ? (
                                    <Icon
                                      data-testid={'newPasswordIcon'}
                                      icon={'eye'}
                                      fill="none"
                                      stroke={`${colorState.icon.default.secondary}`}
                                    />
                                  ) : (
                                    <Icon
                                      data-testid={'newPasswordCloseIcon'}
                                      icon={'eye_off'}
                                      fill="none"
                                      stroke={`${colorState.icon.default.secondary}`}
                                    />
                                  )
                                }
                              />
                            )}
                          />
                        </div>
                        <PasswordStrengthValidation
                          password={watchNewPassword}
                          isDirty={isDirty}
                        />
                        <div className="mb-4">
                          <Controller
                            name={'confirmPassword'}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Input
                                id="confirmPassword"
                                data-testid="confirm_password"
                                value={value}
                                label={STRINGS.CONFIRM_PASSWORD}
                                type={
                                  confirmPasswordVisibility
                                    ? 'text'
                                    : 'password'
                                }
                                onPaste={(e) => e.preventDefault()}
                                placeholder={STRINGS.ENTER_CONFIRM_PASSWORD}
                                showPasswordIndicator={false}
                                iconLeft={false}
                                onChange={onChange}
                                {...inputStyles}
                                onRightIconActionClick={() =>
                                  setConfirmPasswordVisibility(
                                    !confirmPasswordVisibility
                                  )
                                }
                                iconRight={
                                  confirmPasswordVisibility ? (
                                    <Icon
                                      data-testid="confirmPasswordIcon"
                                      icon={'eye'}
                                      fill="none"
                                      stroke={`${colorState.icon.default.secondary}`}
                                    />
                                  ) : (
                                    <Icon
                                      data-testid="confirmPasswordCloseIcon"
                                      icon={'eye_off'}
                                      fill="none"
                                      stroke={`${colorState.icon.default.secondary}`}
                                    />
                                  )
                                }
                              />
                            )}
                          />
                        </div>
                        {validationError && (
                          <ErrorMessage>
                            {typeof validationError === 'string'
                              ? validationError
                              : `${STRINGS.MUST_SAME}.`}
                          </ErrorMessage>
                        )}

                        <ErrorMessage>
                          {errors?.newPassword?.message || ''}
                        </ErrorMessage>
                        <FlexCenter className="flex-column mt-5">
                          <Button
                            data-testid="submit_button"
                            className={'w-100 mb-4'}
                            type="submit"
                            {...btnStyles.primary}
                            disabled={!isValid}
                          >
                            {STRINGS.CHANGE_PASSWORD}
                          </Button>

                          <Button
                            data-testid="login_button"
                            onClick={() => history.push('/Login')}
                            {...btnStyles.tertiary}
                            className="w-100"
                            type="button"
                          >
                            <Icon
                              icon="arrow_narrow_left"
                              stroke={`${colorState.icon.brand.primary}`}
                            />
                            <span className="ms-1">{STRINGS.GO_TO_LOGIN}</span>
                          </Button>
                        </FlexCenter>
                      </FormContainer>
                    </FormSegment>
                  )}
                </Suspense>
                {/* </> */}
              </div>
            </Grid>
          </div>
          <div className="footer" id="footer">
            <div className="left flex-wrap">
              <div className="d-flex flex-wrap">
                <div className="d-flex divider">
                  <span>{STRINGS.ALERT_NOW_IS_A_PRODUCT_OF} </span>
                  <img
                    src={ArcadisFooterLogoImg}
                    alt="arcadis-logo"
                    className="ms-1"
                    loading="lazy"
                  />
                </div>
                <span className="ms-3">&copy; 2024 All Rights Reserved</span>
              </div>
            </div>
            <div className="right">
              <span>{STRINGS.FOR_MORE_DETAILS_VISIT} : </span>
              <a href="https://traveliq.co/" target="_blank" rel="noreferrer">
                <span className="link-text">&nbsp;www.traveliq.co</span>
              </a>
            </div>
          </div>
        </GridContainer>
      </PageContainer>
    </>
  );
}
