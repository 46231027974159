import { yupResolver } from '@hookform/resolvers/yup';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  getPermission,
  showPopUp
} from '../../CommonUtilities/CommonUtilities';
import { STRINGS } from '../../Constants/ConstantStrings';
import {
  composeMessageRequest,
  deleteLifeSafetyMessage,
  getLifeSafetyMessages
} from '../../Store/reducers/RegisteredUsers';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import { colorState } from '../../Universal/Foundation';
import {
  Button,
  CheckBox,
  Icon,
  Select,
  TextArea
} from '../../Universal/NovusDSImports';
import {
  btnStyles,
  checkBoxStyles,
  inputStyles,
  modalStyles,
  selectStyles
} from '../../Universal/NovusDSImports/variants';
import { FormSegment, TextNote } from '../globalStyles';
import { ComposeMessageStyles } from './RegisteredUsers/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import PALoader from '../../SharedComponets/PALoader';
import { TablePopupStyles } from '../GenericTableComponent/styles';
import AddOrEditSafetyMessage from './AddOrEditSafetyMessage';
import { TooltipComponent } from '../../SharedComponets/Tooltip';

const Card = lazy(() => import('../../SharedComponets/Card'));
const RightSidePanel = lazy(
  () => import('../../SharedComponets/RightSidePanel')
);
const ConfirmationPopupModal = lazy(
  () => import('../../SharedComponets/ConfirmationPopupModal')
);

const CopyIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7895 15.4737H14.6316C15.5617 15.4737 16.3158 14.7196 16.3158 13.7895V3.68421C16.3158 2.75405 15.5617 2 14.6316 2H7.89473C6.96457 2 6.21052 2.75405 6.21052 3.68421V4.52632M5.36841 18H12.1053C13.0354 18 13.7895 17.246 13.7895 16.3158V6.21053C13.7895 5.28036 13.0354 4.52632 12.1053 4.52632H5.36841C4.43825 4.52632 3.6842 5.28036 3.6842 6.21053V16.3158C3.6842 17.246 4.43825 18 5.36841 18Z"
      stroke="#374151"
      stroke-width="1.5"
    />
  </svg>
);
// we don't have this Icon in Design System as of now so we are uisng this svg here bcz we need a color change for hover. when we use it in img we will be unable to add a hover effect to this.
// once it is available in design system we can replace it with the Icon component.

const ComposeMessage = (props: any) => {
  const {
    onClose,
    selectedUsers,
    handleClose,
    fetchMessages,
    closureId,
    activeTabName
  } = props;
  const dispatch = useReduxDispatch(),
    [isAdded, setIsAdded] = useState<boolean>(),
    [isMarked, setIsMarked] = useState<boolean>(),
    [isSafetyMessagesTooltipOpen, setIsSafetyMessagesTooltipOpen] =
      useState<boolean>(false),
    [tooltipOpenId, setTooltipOpenId] = useState<any>(),
    [isAddOrEditModalOpen, setIsAddOrEditModalOpen] = useState<boolean>(false),
    [isEdit, setIsEdit] = useState<boolean>(false),
    [openConfirmationPopup, setOpenConfirmationPopup] =
      useState<boolean>(false),
    [formData, setFormData] = useState<any>(),
    [selectedMessage, setSelectedMessage] = useState<any>(null),
    safetyMessagesData = useSelector(
      (state: RootState) => state.RegisteredUsers.safetyMessagesData
    ),
    isMobileScreen = useReduxSelector(
      (state: RootState) => state.Common.isMobileScreen
    ),
    currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    [safetyMessages, setSafetyMessages] = useState<any>();

  const handleToolTip = (id: any) => {
    setTooltipOpenId(id);
    setIsSafetyMessagesTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setIsSafetyMessagesTooltipOpen(false);
    setTooltipOpenId(null);
  };

  const MessageTypeOptions = [
    {
      label: STRINGS.PUBLIC_SAFETY_ANNOUNCEMENT,
      value: 'public safety announcements'
    },
    {
      label: STRINGS.SITUATIONAL_AWARNESS_UPDATES,
      value: 'situational awareness updates'
    }
  ];

  const patternsToRemove = [
    '511 PennDOT & PTC:',
    '511 Penndot:',
    '511 PTC:',
    'Call 911 for emergencies.'
  ];

  const composeMessageSchema = yup.object().shape({
    add_to_website: yup.boolean(),
    saftey_tip: yup.boolean(),
    text_message_body: yup.string().required('This field is required'),
    voice_message_body: yup.string().required('This field is required'),
    message_type: yup.object().nullable()
  });

  const getDefaultValues = () => {
    const values: any = {
      add_to_website: false,
      saftey_tip: false,
      text_message_body: '',
      voice_message_body: '',
      message_type: MessageTypeOptions[0]
    };
    return values;
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    trigger
  } = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(composeMessageSchema)
  });

  const handleCopy = (textToCopy: string) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        if ((getValues().text_message_body + textToCopy).length <= 120) {
          setValue(
            'text_message_body',
            getValues().text_message_body + textToCopy
          );
          setValue(
            'voice_message_body',
            getValues().voice_message_body + textToCopy
          );
        }
      })
      .catch(() => {
        showPopUp(STRINGS.UNABLE_TO_COPY_TEXT, 'error');
      });
  };

  const handleDelete = (id: any) => {
    dispatch(deleteLifeSafetyMessage({ id: id }));
  };

  const handleEdit = (message: any) => {
    setIsEdit(true);
    setIsAddOrEditModalOpen(true);
    setSelectedMessage(message);
  };

  const postAddOrEditModalClose = () => {
    setIsAddOrEditModalOpen(false);
    if (isEdit) {
      setIsEdit(false);
      setSelectedMessage(null);
    }
  };

  const onConfirmationPopupClick = (data: any) => {
    if (selectedUsers?.length) {
      setOpenConfirmationPopup(true);
      setFormData(data);
    } else {
      showPopUp(STRINGS.PLEASE_SELECT_ATLEAST_ONE_USER, 'warning');
    }
  };

  function removePatterns(str, patterns) {
    let result = str;
    patterns.forEach((pattern) => {
      const regex = new RegExp(pattern, 'g');
      result = result.replace(regex, '');
    });
    return result.trim();
  }

  const onSubmit = () => {
    const data = formData;
    setOpenConfirmationPopup(false);
    const composeMessagePayload = {
      add_to_website: data.add_to_website,
      saftey_tip: data.saftey_tip,
      text_message_body: data.text_message_body,
      voice_message_body: data.voice_message_body,
      registered_users: selectedUsers
    };
    if (isAdded) {
      composeMessagePayload['message_type'] = data.message_type.value;
    }
    if (fetchMessages) {
      composeMessagePayload['fetchMessages'] = fetchMessages;
    }
    composeMessagePayload['closure'] = closureId;
    dispatch(
      composeMessageRequest({
        composeMessagePayload,
        isUnreadMessages: activeTabName === 'unread Messages' || false
      })
    );
    handleClose();
  };

  const onInvalid = () => {};

  const postModalClose = () => {
    setOpenConfirmationPopup(false);
  };

  const renderConfirmationFooter = () => {
    return (
      <div className="gap-3 d-flex">
        <Button
          onClick={() => setOpenConfirmationPopup(false)}
          {...btnStyles.default}
        >
          {STRINGS.CANCEL}
        </Button>
        <Button onClick={onSubmit} {...btnStyles.danger}>
          {STRINGS.YES}
        </Button>
      </div>
    );
  };

  const customConfirmationStyles = {
    content: {
      minWidth: '20%',
      minHeight: '10vh'
    },
    overlay: {
      backgroundColor: 'rgba(114, 114, 114, 0.294)'
    }
  };

  useEffect(() => {
    if (getPermission(currentUser, 'view_safetymessages'))
      dispatch(getLifeSafetyMessages({ closureId: closureId }));
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (safetyMessagesData) {
      const newSafetMessagesData = safetyMessagesData.map((message: any) => {
        const newMessage = removePatterns(message.message, patternsToRemove);
        return {
          message_body: newMessage,
          id: message.id
        };
      });
      setSafetyMessages([...newSafetMessagesData]);
    }
  }, [safetyMessagesData]);

  const AddOrEditSafetyMessages = {
    overlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      minWidth: isMobileScreen ? '95%' : '30%',
      maxWidth: isMobileScreen ? '95%' : '60%',
      maxHeight: '60%'
    }
  };
  const handleScroll = () => {
    setIsSafetyMessagesTooltipOpen(false);
    setTooltipOpenId(null);
  };

  useEffect(() => {
    window.addEventListener('wheel', handleScroll);
    window.addEventListener('mousedown', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
      window.addEventListener('mousedown', handleScroll);
    };
  }, []);

  const SectionBody = () => {
    return (
      <FormSegment onSubmit={handleSubmit(onConfirmationPopupClick, onInvalid)}>
        <ComposeMessageStyles className="formset-fields">
          <TextNote>
            <span>{STRINGS.NOTE}: </span>
            {STRINGS.THE_WORDS_CALL}
          </TextNote>
          <div className="d-flex flex-column gap-2">
            <div className="messages-card-title">
              <h2>{STRINGS.LIFE_SAFETY_MESSAGES}</h2>
              <Button
                {...btnStyles.tertiary}
                onClick={(e: any) => {
                  e.preventDefault();
                  setIsAddOrEditModalOpen(true);
                }}
              >
                <Icon
                  icon={'add'}
                  width="20px"
                  height="20px"
                  stroke={colorState.icon.brand.primary}
                />
                {STRINGS.ADD_MESSAGE}
              </Button>
            </div>
            <Card className="messages-card">
              {safetyMessages?.map((item) => {
                return (
                  <section>
                    <p className="view-details-section">
                      <>{item.message_body}</>
                      <div
                        role="button"
                        tabIndex={0}
                        aria-label="view-details"
                        className="option-icon"
                        onClick={() => handleToolTip(item.id)}
                        data-tooltip-id={item.id}
                      >
                        <Icon
                          icon={'dots_vertical'}
                          width="20px"
                          height="20px"
                        />
                      </div>
                      <TooltipComponent
                        tooltipBgColor={colorState.surface.default.secondary}
                        noPadding
                        noArrow
                        closeOnScroll
                        id={item.id}
                        openOnClick
                        isOpen={
                          item.id === tooltipOpenId &&
                          isSafetyMessagesTooltipOpen
                        }
                        place="bottom-end"
                      >
                        <TablePopupStyles>
                          <ul tabIndex={0}>
                            <li
                              tabIndex={0}
                              role="button"
                              onClick={() => {
                                handleCopy(item.message_body);
                                handleToolTip(item.id);
                                handleTooltipClose();
                              }}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  handleCopy(item.message_body);
                                  handleToolTip(item.id);
                                  handleTooltipClose();
                                }
                              }}
                              aria-label="copy"
                              className="d-flex"
                            >
                              <div className="me-3">{CopyIcon}</div>
                              {STRINGS.COPY}
                            </li>
                            <li
                              tabIndex={0}
                              role="button"
                              onClick={() => {
                                handleEdit(item);
                                handleToolTip(item.id);
                              }}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  handleEdit(item);
                                  handleToolTip(item.id);
                                }
                              }}
                              aria-label="edit-option"
                            >
                              <Icon
                                icon={'edit_02'}
                                height={20}
                                width={20}
                                stroke={colorState.icon.default.primary}
                                className="edit-icon me-3"
                              />
                              {STRINGS.EDIT}
                            </li>
                            <li
                              tabIndex={0}
                              role="button"
                              onClick={() => {
                                handleDelete(item.id);
                                handleToolTip(item.id);
                                handleTooltipClose();
                              }}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  handleDelete(item.id);
                                  handleToolTip(item.id);
                                  handleTooltipClose();
                                }
                              }}
                              aria-label="delete-option"
                            >
                              <Icon
                                icon={'trash_01'}
                                height={20}
                                width={20}
                                stroke={colorState.icon.default.primary}
                                className="delete-icon me-3"
                              />
                              {STRINGS.DELETE}
                            </li>
                          </ul>
                        </TablePopupStyles>
                      </TooltipComponent>
                    </p>
                  </section>
                );
              })}
            </Card>
          </div>
          <div className="form-group">
            <Controller
              name={'text_message_body'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextArea
                  name={'text_message_body'}
                  hintText={
                    <p className="d-flex justify-content-end mb-0">
                      {120 - getValues('text_message_body')?.length}{' '}
                      {STRINGS.CHARACTERS}
                    </p>
                  }
                  value={value}
                  asterisk={true}
                  onChange={(e) => {
                    if (
                      (e.target.value && e.target.value.length <= 120) ||
                      e.nativeEvent.inputType === 'deleteContentBackward'
                    ) {
                      setValue('voice_message_body', e.target.value);
                      trigger('voice_message_body');
                      return onChange(e);
                    }
                  }}
                  label={STRINGS.TEXT_MESSAGE}
                  placeholder={STRINGS.WRITE_TEXT_MESSAGE}
                  {...inputStyles}
                  isMargin={false}
                  error={errors && errors.text_message_body}
                />
              )}
            />
          </div>
          <div className="form-group">
            <Controller
              name={'voice_message_body'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextArea
                  name={'voice_message_body'}
                  hintText={
                    <p className="d-flex justify-content-end mb-0">
                      {120 - getValues('voice_message_body')?.length}{' '}
                      {STRINGS.CHARACTERS}
                    </p>
                  }
                  value={value}
                  asterisk={true}
                  onChange={(e) => {
                    if (
                      (e.target.value && e.target.value.length <= 120) ||
                      e.nativeEvent.inputType === 'deleteContentBackward'
                    ) {
                      return onChange(e);
                    }
                  }}
                  label={STRINGS.MESSAGE_FOR_PHONE_SYSTEM}
                  placeholder={STRINGS.WRITE_TEXT_MESSAGE}
                  {...inputStyles}
                  isMargin={false}
                  error={errors && errors.voice_message_body}
                />
              )}
            />
          </div>
          <div className="custom-checkbox-group">
            <Controller
              name={'add_to_website'}
              control={control}
              render={() => (
                <CheckBox
                  selected={getValues().add_to_website}
                  onClick={() => {
                    setValue('add_to_website', !getValues().add_to_website);
                    setIsAdded(!isAdded);
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === ' ') {
                      setValue('add_to_website', !getValues().add_to_website);
                      setIsAdded(!isAdded);
                    }
                  }}
                  {...checkBoxStyles}
                  error={errors && errors.add_to_website}
                />
              )}
            />
            <label>{STRINGS.ADD_TO_WEBSITE}</label>
          </div>
          <div className="custom-checkbox-group">
            <Controller
              name={'saftey_tip'}
              control={control}
              rules={{ required: true }}
              render={() => (
                <CheckBox
                  id="checkbox"
                  selected={getValues().saftey_tip}
                  onClick={() => {
                    setValue('saftey_tip', !getValues().saftey_tip);
                    setIsMarked(!isMarked);
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === ' ') {
                      setValue('saftey_tip', !getValues().saftey_tip);
                      setIsMarked(!isMarked);
                    }
                  }}
                  {...checkBoxStyles}
                  error={errors && errors.saftey_tip}
                />
              )}
            />
            <label>{STRINGS.MARK_MESSAGE}</label>
          </div>
          {isAdded && (
            <Controller
              name={'message_type'}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  displayName={'Message Type'}
                  options={MessageTypeOptions}
                  value={value}
                  onChange={onChange}
                  error={errors?.message_type}
                  {...selectStyles}
                />
              )}
            />
          )}
        </ComposeMessageStyles>
        <section className="footer gap-3">
          <Button
            {...btnStyles.secondary}
            disabled={openConfirmationPopup}
            onClick={onClose}
          >
            {STRINGS.CANCEL}
          </Button>
          <Button
            {...btnStyles.primary}
            type={STRINGS.SUBMIT}
            className="w-100"
            disabled={openConfirmationPopup}
          >
            {STRINGS.SEND}
            <Icon
              className={'ms-2'}
              icon={'send_03'}
              width={'16px'}
              height={'16px'}
              stroke={colorState.icon.default['primary-inverse']}
            />
          </Button>
        </section>
      </FormSegment>
    );
  };
  return (
    <>
      <Suspense fallback={<PALoader />}>
        <RightSidePanel
          fetchMessagesStyles={fetchMessages}
          header={STRINGS.COMPOSE_MESSAGE}
          body={SectionBody()}
          onClose={onClose}
        />
      </Suspense>
      <Suspense fallback={<PALoader />}>
        <ConfirmationPopupModal
          isOpen={openConfirmationPopup}
          showHeader={false}
          description={STRINGS.ARE_YOU_SURE_WANT_TO_SEND_MESSAGE}
          showFooter={renderConfirmationFooter()}
          style={customConfirmationStyles}
          postModalClose={postModalClose}
          closeModal={() => postModalClose()}
          showBorderTop={false}
          isBodyPaddingNone={true}
          customFooter={true}
        />
      </Suspense>
      <Suspense fallback={<PALoader />}>
        <AddOrEditSafetyMessage
          isBodyPaddingNone={true}
          isOpen={isAddOrEditModalOpen}
          postModalClose={postAddOrEditModalClose}
          title={`${isEdit ? STRINGS.EDIT : STRINGS.CREATE} ${
            STRINGS.LIFE_SAFETY_MESSAGES
          }`}
          showHeader={true}
          isEdit={isEdit}
          selectedMessage={selectedMessage}
          style={AddOrEditSafetyMessages}
          {...modalStyles}
        />
      </Suspense>
    </>
  );
};

export default ComposeMessage;
