import styled from 'styled-components';
import {
  colorState,
  device,
  radius,
  spacing,
  typography
} from '../../Universal/Foundation';
import { primaryNavWidth } from '../globalStyles';

export interface SideNavProps {
  isSideNav?: boolean;
}

export interface LegendDropdownProps {
  isDropdownHeight?: boolean;
}

const legendDropdownWidth = '320';

export const GridView = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;

    h1 {
      color: ${colorState.text.primary};
      font-weight: ${typography.weight.semibold};
      font-size: ${typography.size.t1}px;
      margin: 0;
      cursor: default;
    }
  }
`;

export const SearchBoxWrapper = styled.div`
  margin-left: 16px;
  max-width: 260px;
  max-height: 40px;
  padding: 4px 6px;

  input {
    top: 12px;
    border-radius: 46px !important;
    background-color: ${colorState.surface.default.secondary} !important;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-top: 20px;
  }
`;

export const LegendBox = styled.div`
  width: 20px;
  height: 8px;
`;

export const LegendDropdownMenuWrapper = styled.section<LegendDropdownProps>`
  background-color: white;
  position: absolute;
  right: 0.5%;
  top: 40px;
  border-radius: ${radius.radius_16}px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  gap: ${spacing.spacing_4}px;
  width: ${legendDropdownWidth}px;
  height: auto;
  padding: ${spacing.spacing_8}px ${spacing.spacing_0};

  .dropdown-menu-header {
    display: flex;
    justify-content: space-between;
    padding: ${spacing.spacing_8}px 20px;
    align-items: center;
    gap: ${spacing.spacing_4}px;
    font-size: ${typography.size.tm};
    font-weight: ${typography.weight.semibold};
    cursor: default;
  }

  .dropdown-body-section {
    padding: 0 12px;
    gap: ${spacing.spacing_16}px;
    display: flex;
    flex-direction: column;
    max-height: ${(props) => (props.isDropdownHeight ? '150px' : '350px')};
    overflow-y: auto;

    @media ${device.laptopXL} {
      max-height: 200px;
    }

    h3 {
      font-size: ${typography.size.ts};
      font-weight: ${typography.weight.semibold};
      padding-left: ${spacing.spacing_8}px;
      padding-right: ${spacing.spacing_8}px;
      cursor: default;
    }

    ul {
      padding-left: 0;

      li {
        display: flex;
        gap: ${spacing.spacing_12}px;
        align-items: center;
        padding: ${spacing.spacing_8}px;
        margin-bottom: 0.2rem;

        label {
          font-size: ${typography.size.ts};
          font-weight: ${typography.weight.medium};
        }

        &:hover {
          background-color: ${colorState.surface.brand.tertiary};
          border-radius: ${radius.radius_8}px;
          color: ${colorState.text.brand.secondary};
        }

        &:focus-visible {
          outline: none;
          border-radius: 8px;
          border: solid 1px ${colorState.border.brand.primary};
        }
      }
      .selected-list {
        background-color: ${colorState.surface.brand.tertiary};
        border-radius: ${radius.radius_8}px;
      }
    }
  }
`;

export const CollapseIconContainer = styled.div<SideNavProps>`
  .collapse-icon {
    position: absolute;
    top: ${(props: any) => (props.isSideNav ? '20px' : '16px')};
    left: ${(props: any) =>
      props.isSideNav ? `${primaryNavWidth - 30}px` : '0'};
    z-index: 1;
    background: white;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 0;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: ${(props) =>
        !props.isSideNav && colorState.surface.brand.primary};
      svg path {
        stroke: ${colorState.icon.default['primary-inverse']};
      }
    }

    &:focus-visible {
      border-radius: 8px;
      border: solid 1px ${colorState.border.brand.primary};
      outline: none;
    }

    @media ${device.laptopL} {
      left: ${(props: any) =>
        props.isSideNav ? `${primaryNavWidth - 100}px` : '0'};
    }

    @media ${device.mobileL} {
      left: ${(props: any) => (props.isSideNav ? `0` : '0')};
      display: none;
    }
  }

  &:hover {
    .collapse-left-icon {
      border-radius: 100%;
    }

    .collapse-right-icon,
    .collapse-left-icon {
      background-color: ${colorState.surface.brand.primary};
      transition: all 0.3s ease;
    }
  }
`;

export const ClosureMarker = styled.div`
  div[title='animated-marker'] {
    transition: all 0.25s ease;
  }
`;

export const StatusTooltip = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  label {
    font-weight: ${typography.weight.medium};
    font-size: ${typography.size.tm};
    color: ${colorState.text.secondary};
  }

  .asterik {
    color: ${colorState.text.danger.primary};
  }

  .tooltip-content {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 12px;

    .content {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .content-heading {
        font-weight: ${typography.weight.semibold};
      }

      .content-description {
        font-weight: ${typography.weight.regular};
      }
    }
  }
`;

export const CustomTextArea = styled.div`
  label {
    font-weight: ${typography.weight.medium};
    font-size: ${typography.size.ts};
    color: ${colorState.text.secondary};

    span {
      white-space: nowrap;
      font-size: ${typography.size.ts};
    }
  }
`;
