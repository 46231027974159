export const clearUIforScreenshot = () => {
  const a = document.querySelector('.filter-btn');
  const b = document.querySelector('.gm-fullscreen-control');
  const c = document.querySelectorAll('.gmnoprint');
  const d = document.querySelector('.custom-legend-wrapper');
  const e = document.querySelector('.gm-style-mtc-bbw');
  const f = document.querySelector('.polygon-clear-button');
  const g = document.querySelector('.map-search-box');

  if (a) {
    a.classList.add('hide');
  }

  if (b) {
    b.classList.add('hide');
  }

  if (c.length > 0) {
    c.forEach((element) => {
      element.classList.add('hide');
    });
  }

  if (d) {
    d.classList.add('hide');
  }

  if (e) {
    e.classList.add('hide');
  }

  if (f) {
    f.classList.add('hide');
  }
  if (g) {
    g.classList.add('hide');
  }
};

export const restoreUIforScreenshot = () => {
  const a = document.querySelector('.filter-btn');
  const b = document.querySelector('.gm-fullscreen-control');
  const c = document.querySelectorAll('.gmnoprint');
  const d = document.querySelector('.custom-legend-wrapper');
  const e = document.querySelector('.gm-style-mtc-bbw');
  const f = document.querySelector('.polygon-clear-button');
  const g = document.querySelector('.map-search-box');

  if (a) {
    a.classList.remove('hide');
  }

  if (b) {
    b.classList.remove('hide');
  }

  if (c.length > 0) {
    c.forEach((element) => {
      element.classList.remove('hide');
    });
  }

  if (d) {
    d.classList.remove('hide');
  }

  if (e) {
    e.classList.remove('hide');
  }

  if (f) {
    f.classList.remove('hide');
  }

  if (g) {
    g.classList.remove('hide');
  }
};
