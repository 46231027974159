import { createSlice } from '@reduxjs/toolkit';
import {
  GetPublicClosuresRequest,
  GetRegisteredUsersPhoneNumberType,
  PublicViewTypes,
  RegisteredUsersPhoneNumberReceivedType,
  SubmittedType,
  getClosureQuestionsReceived,
  getClosureQuestionsRequest,
  getPublicClosuresReceived,
  manageLocationReceivedType,
  manageLocationRequestType,
  registerFormResultReceived,
  registerFormSubmit,
  selectedClosureDataType
} from '../actions/PublicView';

const initialState: PublicViewTypes = {
  publicClosuresData: null,
  selectedRegisterClosureData: null,
  selectedClosureQuestions: null,
  isRegisterSuccesful: false,
  isRegisterUnsuccessful: false,
  message: '',
  isManageLocationUpdated: false,
  messageHistoryData: null,
  isfetchingPublicClosuresData: false,
  isFetchingMessageHistoryData: false,
  isFetchingClosureQuestions: false,
  isSubmitting: false,
  registeredUserPhoneNumber: null,
  isFetchingUserPhoneNumber: false,
  isNewQuestionsSubmit: false
};

const PublicView = createSlice({
  name: 'PublicView',
  initialState,
  reducers: {
    getPublicClosuresDataReceived: (
      state,
      action: getPublicClosuresReceived
    ) => {
      state.publicClosuresData = action.payload.data;
      state.isfetchingPublicClosuresData = false;
    },
    getPublicClosuresData: (state, _action: GetPublicClosuresRequest) => {
      state.isfetchingPublicClosuresData = true;
    },
    selectedRegisterClosure: (state, action: selectedClosureDataType) => {
      state.selectedRegisterClosureData = action.payload;
      state.isRegisterSuccesful = false;
    },
    getClosureQuestions: (state, _action: getClosureQuestionsRequest) => {
      state.isFetchingClosureQuestions = true;
    },
    closureQuestionsReceived: (state, action: getClosureQuestionsReceived) => {
      state.selectedClosureQuestions = action.payload.data;
      state.isFetchingClosureQuestions = false;
    },
    registerSubmit: (state, _action: registerFormSubmit) => {
      state.isSubmitting = true;
    },
    registerSubmittedSuccessfully: (
      state,
      action: registerFormResultReceived
    ) => {
      state.isRegisterSuccesful = action.payload.isRegisterSuccesful;
      state.isSubmitting = false;
      state.isNewQuestionsSubmit = action.payload.isNewQuestionsSubmit;
    },
    manageLocationRequest: (state, _action: manageLocationRequestType) => {
      state.isSubmitting = true;
    },
    manageLocationReceived: (state, action: manageLocationReceivedType) => {
      state.message = action.payload.result.message;
      state.isManageLocationUpdated =
        action.payload.result.isManageLocationUpdated;
      state.isSubmitting = false;
    },
    messageHistory: (state, _action: getClosureQuestionsRequest) => {
      state.isFetchingMessageHistoryData = true;
      state.messageHistoryData = [];
    },
    messageHistoryDataReceived: (state, action: getPublicClosuresReceived) => {
      state.messageHistoryData = action.payload.data;
      state.isFetchingMessageHistoryData = false;
    },
    getUserPhoneNumber: (state, _action: GetRegisteredUsersPhoneNumberType) => {
      state.isFetchingUserPhoneNumber = true;
    },
    userPhoneNumberReceived: (
      state,
      action: RegisteredUsersPhoneNumberReceivedType
    ) => {
      state.isFetchingUserPhoneNumber = false;
      state.registeredUserPhoneNumber = action.payload.phoneNumber;
    },
    resetValues: (state) => {
      state.publicClosuresData = null;
      state.selectedClosureQuestions = null;
      state.isRegisterSuccesful = false;
      state.message = '';
      state.isFetchingMessageHistoryData = false;
    },
    submitted: (state, action: SubmittedType) => {
      state.isSubmitting = false;
      if (action.payload.isRegisterUnsuccessful) {
        state.isRegisterUnsuccessful = action.payload.isRegisterUnsuccessful;
      }
    }
  }
});

export const {
  getPublicClosuresData,
  getPublicClosuresDataReceived,
  selectedRegisterClosure,
  getClosureQuestions,
  closureQuestionsReceived,
  registerSubmit,
  registerSubmittedSuccessfully,
  manageLocationRequest,
  manageLocationReceived,
  messageHistory,
  messageHistoryDataReceived,
  resetValues,
  submitted,
  getUserPhoneNumber,
  userPhoneNumberReceived
} = PublicView.actions;
export default PublicView.reducer;
