import React, { useEffect } from 'react';
import { Button, Input } from '../../Universal/NovusDSImports';
import RightSidePanel from '../../SharedComponets/RightSidePanel';
import { AddEditShapeType } from './shapes.types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import * as yup from 'yup';
import {
  completePolygon,
  getCentroid,
  isPolygonInvalid,
  showError,
  showPopUp
} from '../../CommonUtilities/CommonUtilities';
import { addNewShapeData, editShapeData } from '../../Store/reducers/Shapes';
import { STRINGS } from '../../Constants/ConstantStrings';
import { FormSegment } from '../globalStyles';
import {
  btnStyles,
  inputStyles
} from '../../Universal/NovusDSImports/variants';
import { RootState } from '../../store';

const shapeSchema = yup.object({
  name: yup.string().trim().required(STRINGS.NAME_IS_REQUIRED),
  latitude: yup.string().required(STRINGS.LATITUDE_IS_REQUIRED),
  longitude: yup.string().required(STRINGS.LONGITUDE_IS_REQUIRED)
});

const AddEditShapeModal: React.FC<AddEditShapeType> = ({
  showAddEditForm,
  editModeType,
  setEditModeType,
  editModeData,
  setEditModeData,
  onClose,
  onPostClose,
  drawnPolygon,
  title,
  searchFilterValue
}) => {
  const {
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      latitude: '',
      longitude: ''
    },
    resolver: yupResolver(shapeSchema)
  });

  const isSubmitting = useReduxSelector(
    (state: RootState) => state.Shapes.isSubmittingShape
  );

  const dispatch = useReduxDispatch();

  const stateBoundary = useReduxSelector(
    (state: RootState) => state.ClientInfo.state_boundary
  );

  const onSubmit = (data) => {
    const polygon = completePolygon(drawnPolygon);

    if (editModeType === 'Add') {
      const newData = {
        polygon_name: data.name,
        geometry: {
          type: 'Polygon',
          coordinates: [polygon.map((coords) => [coords[1], coords[0]])]
        }
      };
      dispatch(
        addNewShapeData({
          data: newData,
          params: {
            ordering: '-modified, id',
            limit: 'all',
            search: searchFilterValue
          }
        })
      );
    } else {
      const { id } = editModeData;

      const newData = {
        polygon_name: data.name,
        geometry: {
          type: 'Polygon',
          coordinates: [polygon.map((coords) => [coords[1], coords[0]])]
        }
      };

      if (stateBoundary?.coordinates) {
        const polygonInvalidError = isPolygonInvalid(
          drawnPolygon,
          true,
          stateBoundary?.coordinates
        );
        if (polygonInvalidError) {
          showPopUp(polygonInvalidError, 'error');
          return;
        }
      } else {
        showError(STRINGS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
      }

      dispatch(
        editShapeData({
          data: newData,
          polygonId: id,
          params: {
            ordering: '-modified, id',
            limit: 'all',
            search: searchFilterValue
          }
        })
      );
    }

    onPostClose();
  };

  useEffect(() => {
    if (drawnPolygon) {
      const value = getCentroid(drawnPolygon);
      setValue('latitude', value[1]);
      setValue('longitude', value[0]);
    } else {
      setValue('latitude', '');
      setValue('longitude', '');
    }
  }, [drawnPolygon, setValue]);

  useEffect(() => {
    if (editModeData && editModeType !== 'Add') {
      setValue('name', editModeData.polygon_name);
    }
  }, [editModeData, editModeType, setValue]);

  const section = () => {
    return (
      <FormSegment
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        data-testid="addEditShapeForm"
      >
        <section className="formset-fields">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                label={STRINGS.NAME}
                placeholder={STRINGS.NAME}
                disabled={editModeType === 'View'}
                error={errors && errors.name}
                hintText={errors && errors.name && errors.name.message}
                {...field}
                {...inputStyles}
              />
            )}
          />
          <Controller
            name="latitude"
            control={control}
            render={({ field }) => (
              <Input
                label={STRINGS.CENTER_POINT_LATITUDE}
                placeholder={STRINGS.CENTER_POINT_LATITUDE}
                disabled={true}
                {...field}
                error={errors && !watch('latitude') && errors.latitude}
                hintText={
                  errors &&
                  errors.latitude &&
                  !watch('latitude') &&
                  errors.latitude.message
                }
                {...inputStyles}
              />
            )}
          />
          <Controller
            name="longitude"
            control={control}
            render={({ field }) => (
              <Input
                label={STRINGS.CENTER_POINT_LONGITUDE}
                placeholder={STRINGS.CENTER_POINT_LONGITUDE}
                disabled={true}
                {...field}
                error={errors && !watch('longitude') && errors.longitude}
                hintText={
                  errors &&
                  errors.longitude &&
                  !watch('longitude') &&
                  errors.longitude.message
                }
                {...inputStyles}
              />
            )}
          />
        </section>
        <section className="footer d-flex justify-content-end gap-2">
          <Button
            disabled={editModeType === 'View' || isSubmitting}
            onClick={onPostClose}
            {...btnStyles.secondary}
          >
            {STRINGS.CANCEL}
          </Button>
          <Button
            disabled={editModeType === 'View' || isSubmitting}
            data-testid="addEditShapeSubmit"
            {...btnStyles.primary}
          >
            {editModeType === 'Add'
              ? isSubmitting
                ? STRINGS.ADDING
                : STRINGS.ADD
              : isSubmitting
              ? STRINGS.SAVING
              : STRINGS.SAVE}
          </Button>
        </section>
      </FormSegment>
    );
  };
  return <RightSidePanel header={title} body={section()} onClose={onClose} />;
};

export default AddEditShapeModal;
