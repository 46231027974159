/**
 * This component allows authenticated user with permissions to access the protected Routes.
 */
import Cookies from 'js-cookie';
import { lazy, Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { clearCookiesAndLocalstorage } from '../CommonUtilities/CommonUtilities';

interface PageProps {
  page: string;
  path: string;
}

function AuthorisedRoute(props: PageProps) {
  const { page, path } = props;
  const Component = lazy(() => import(`.././Components/${page}/index.tsx`));
  if (
    path !== '/Login' &&
    (!Cookies.get('access') ||
      !Cookies.get('refresh') ||
      !Cookies.get('isValidated'))
  ) {
    clearCookiesAndLocalstorage(false);
    return <Redirect to="/Login" />;
  }
  return (
    <Suspense fallback={<></>}>
      <Route path={path} component={Component} />
    </Suspense>
  );
}
export default AuthorisedRoute;
