import { lazy, Suspense, useState } from 'react';
import Card from '../../SharedComponets/Card';
import LeftSidePanel from '../../SharedComponets/LeftSidePanel';
import { GridView } from '../Closures/styles';
import NoDataFound from '../../SharedComponets/NoDataFound';
import { NoClosuresData } from '../../Universal/Assets';
import { STRINGS } from '../../Constants/ConstantStrings';
import PALoader from '../../SharedComponets/PALoader';

const ResourcesMap = lazy(() => import('./ResourcesMap'));
const ResourcesForm = lazy(() => import('./ResourcesForm'));

export default function Resources(): JSX.Element {
  const [showMap, setShowMap] = useState<boolean>(false),
    [selectedClosure, setSelectedClosure] = useState<any>(null);
  return (
    <div className={'w-100 grid-view'}>
      <section className="left" tabIndex={0} aria-label="Left Section">
        <Card className="w-100 d-flex overflow-auto flex-grow-1" tab-index={0}>
          <LeftSidePanel
            header={'Resources'}
            icon={'filters'}
            isFilterButton={false}
            isMobileCollapsible={true}
            body={
              <Suspense fallback={<PALoader />}>
                <ResourcesForm
                  setShowMap={setShowMap}
                  unselectMarker={() => setSelectedClosure(null)}
                />
              </Suspense>
            }
          />
        </Card>
      </section>
      <section className="middle" tabIndex={0} aria-label="Middle Section">
        <Card className="d-flex flex-column">
          <GridView id="grid-view" className="flex-grow-1">
            <div className="header pb-0">
              <h1 className="mb-0">Map</h1>
            </div>
          </GridView>
          {showMap ? (
            <Suspense fallback={<PALoader />}>
              <ResourcesMap
                data-testid="resources-map"
                selectedClosure={selectedClosure}
                setSelectedClosure={setSelectedClosure}
              />
            </Suspense>
          ) : (
            <div className="d-flex w-100 h-100 flex-grow-1 justify-content-center">
              <NoDataFound
                description={STRINGS.SELECT_ONE_OF_THE_GIVEN_CHOICES}
                src={NoClosuresData}
                alt="select-one-option"
              />
            </div>
          )}
        </Card>
      </section>
    </div>
  );
}
