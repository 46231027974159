import {
  PAStateBoundary,
  clearJwtCookie,
  clearJwtLocalStorage,
  maxVerticesInClosure,
  minVerticesInClosure
} from '../Constants/constants';
import * as yup from 'yup';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import { colorState } from '../Universal/Foundation/colors';
import {
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon
} from '../Universal/Assets';
import { point, polygon } from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { STRINGS } from '../Constants/ConstantStrings';
import isPolygonIntersecting from './isPolygonIntersecting';
import { jwtDecode } from 'jwt-decode';
import { isPublicView, isTesting } from '../settings';
import ReactGA from 'react-ga4';
import { store } from '../store';
import { setTableRowLimit } from '../Store/reducers/Common';
import bbox from '@turf/bbox';
import { distance } from '@turf/distance';

export const safeDecodeJWTToken = <T = any>(token: string): T | null => {
  try {
    return jwtDecode(token);
  } catch (error) {
    clearCookiesAndLocalstorage();
    return null;
  }
};

export const getPermission = (currentUser: any, permission: string) => {
  // give all permissions while unit testing
  return (
    isTesting ||
    currentUser?.user_permission_set?.permissions?.find(
      (item: any) => item.codename === permission
    )
  );
};

export const emailValidation = (value: string): boolean => {
  const validateWithEmailRegex =
    /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  //   prop.setInvalidEmail(validateWithEmailRegex.test(value));
  return validateWithEmailRegex.test(value);
};

export const passwordValidation = (value: string): boolean => {
  const validateWithPasswordRegex =
    /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  return validateWithPasswordRegex.test(value);
};

export function clearCookiesAndLocalstorage(isRedirectToLogin = true) {
  clearJwtCookie();
  clearJwtLocalStorage();
  if (!isPublicView && isRedirectToLogin) {
    window.location.href = '/Login';
  }
}

const customIcons = {
  success: `<img src=${SuccessIcon} alt="successIcon" style="font-size: 14px" fetchpriority="high" loading="eager" />`,
  error: `<img src=${ErrorIcon} alt="errorIcon" style="font-size: 14px" fetchpriority="high" loading="eager" />`,
  warning: `<img src=${WarningIcon} alt="warningIcon" style="font-size: 14px" fetchpriority="high" loading="eager" />`,
  info: `<img src=${InfoIcon} alt="infoIcon" style="font-size: 14px" fetchpriority="high" loading="eager" />`
  // undefined: `<i class="fas fa-info-circle" style="color: ${theme.primary.color}"/>`,
};

const Colors = {
  success: `${colorState.surface.success.primary}`,
  error: `${colorState.surface.danger.primary}`,
  warning: `${colorState.surface.warning.primary}`,
  info: `${colorState.surface.info.primary}`
};

export const dropDownOptions = [
  { label: 'English', value: 'english' },
  { label: 'French', value: 'french' }
];

export const weaPrefillTexts = {
  penndot: '511 PennDOT- ',
  ptc: '511 PTC- ',
  both: '511 PennDOT & PTC- '
};

export const areaOfPolygon = (polygon: any, len: number) => {
  let s = 0.0;
  for (let i = 0; i < len; i++) {
    s += polygon[i][0] * polygon[i + 1][1] - polygon[i + 1][0] * polygon[i][1];
  }
  return 0.5 * s;
};

export const getCentroid = (polygonCoordinates: Array<Array<number>>) => {
  if (polygonCoordinates) {
    let xCoordinates: any = 0;
    let yCoordinates: any = 0;
    const len = polygonCoordinates.length;
    for (let i = 0; i < len - 1; i++) {
      xCoordinates +=
        (polygonCoordinates[i][0] + polygonCoordinates[i + 1][0]) *
        (polygonCoordinates[i][0] * polygonCoordinates[i + 1][1] -
          polygonCoordinates[i + 1][0] * polygonCoordinates[i][1]);
      yCoordinates +=
        (polygonCoordinates[i][1] + polygonCoordinates[i + 1][1]) *
        (polygonCoordinates[i][0] * polygonCoordinates[i + 1][1] -
          polygonCoordinates[i + 1][0] * polygonCoordinates[i][1]);
    }
    const area = areaOfPolygon(polygonCoordinates, len - 1);
    xCoordinates /= area * 6;
    yCoordinates /= area * 6;
    return [yCoordinates, xCoordinates];
  }
  return [NaN, NaN];
};

export const getBoundaryBox = (poly: Array<[]>) => {
  const polygonPoints = polygon(poly);
  const boundary = bbox(polygonPoints);

  return boundary;
};

export function validateYupSchema(
  type: string,
  name: string,
  required = true,
  params: any = {}
): any {
  function func(data: any): typeof yup {
    return required ? data.required(`${name} is required`) : data;
  }

  function validate() {
    let minNumber = type === 'password' ? 8 : Number.MIN_SAFE_INTEGER,
      maxNumber = type === 'password' ? 16 : Number.MAX_SAFE_INTEGER;
    if (params.numberRange) {
      minNumber = params.numberRange.minNumber;
      maxNumber = params.numberRange.maxNumber;
    }
    const numberValidation =
      params &&
      yup
        .number()
        .transform((transformValue, originalValue) =>
          originalValue === '' ? null : transformValue
        )
        .nullable()
        .min(minNumber, `${name} must be greater than ${minNumber - 1}`)
        .max(maxNumber, `${name} must be less than or equal to ${maxNumber}`)
        .typeError(`${name} must be a number`);
    switch (type) {
      // when the input field can accept both integer and decimal values
      case 'number':
        return func(numberValidation);
      // when the input field can accept only integer values
      case 'integer':
        return func(numberValidation.integer(`${name} must be an integer`));
      case 'alphaNumericText':
        return func(
          yup
            .string()
            .trim()
            .matches(/^[-_ a-zA-Z0-9]+$/, {
              message: 'Please enter alphabets and numbers only',
              excludeEmptyString: true
            })
        );
      case 'onlyAlphabets':
        return func(
          yup.string().matches(/^[a-zA-z ]+$/, {
            message: 'Please enter alphabets only',
            excludeEmptyString: true
          })
        );
      case 'text':
        return func(
          yup
            .string()
            .trim()
            .max(
              maxNumber,
              `${name} can only have maximum ${maxNumber} characters`
            )
        );
      case 'textArea':
        return func(yup.string());
      // case 'oldPassword':
      //     return yup.string().required(`${i18n.t(STRINGS.YUP.OLD_PASSWORD_IS_REQUIRED)}`);
      // case 'password':
      //     return yup
      //         .string()
      //         .required(`${i18n.t(STRINGS.YUP.PASSWORD_IS_REQUIRED)}`)
      //         .min(
      //             minNumber,
      //             `${
      //                 params.passwordMinText
      //                     ? params.passwordMinText
      //                     : 'Password should have minimum'
      //             } ${minNumber} ${
      //                 params.passwordCharacterText
      //                     ? params.passwordCharacterText
      //                     : 'characters'
      //             }`,
      //         )
      //         .max(maxNumber, `Password can only have maximum ${maxNumber} characters`)
      //         .matches(/(?=.*[a-z])/, 'Password should have atleast one lower case letter')
      //         .matches(/(?=.*[A-Z])/, 'Password should have atleast one upper case letter')
      //         .matches(/(?=.*\d)/, 'Password should have atleast one number')
      //         .matches(/(.*\W.*)/, 'Password must have one special character');
      case 'pinPassword':
        return yup
          .string()
          .required('Pin is required')
          .matches(
            /^[0-9]*$/,
            params.pinDigitsOnlyText
              ? params.pinDigitsOnlyText
              : 'Please enter digits only'
          )
          .min(
            6,
            params.pinMinDigitsText
              ? params.pinMinDigitsText
              : 'Pin should have minimum 6 digits'
          );
      // case 'email':
      //     return func(
      //         yup.string().matches(/^\w+([-.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, {
      //             message: i18n.t('Please enter a valid email address'),
      //             excludeEmptyString: true,
      //         }),
      //     );
      case 'date':
      case 'time':
      case 'dateTimePicker':
      case 'datePicker':
      case 'timePicker':
        return func(yup.date().nullable());
      case 'select':
        return func(yup.object().nullable());
      case 'phone':
        return func(
          yup
            .string()
            .matches(
              /^\+?([0-9]{2})\)?[-. ]?([0-9]{10})$/,
              'Should be phone (+91...)'
            )
            .nullable()
        );
      case 'startDate':
        return func(yup.date().nullable());
      case 'checkbox':
        return func(yup.bool());
      case 'array':
        if (params.arrayMinNumber) {
          return func(
            yup.array().min(params.arrayMinNumber, `${name} is required`)
          );
        }
        return func(yup.array());
      case 'file':
        return yup.mixed().test({
          message: (item) => {
            if (required && !item.value.length) {
              return `${name} is required`;
            } else if (
              params.fileParams &&
              params.fileParams.size &&
              item.value.length &&
              item.value[0].size > params.fileParams.size.fileSize
            ) {
              return params.fileParams.size.message;
            } else if (
              params.fileParams &&
              item.value.length &&
              params.fileParams.format &&
              !params.fileParams.format.fileType.includes(item.value[0].type)
            ) {
              return params.fileParams.format.message;
            } else {
              return false;
            }
          },
          test: (value: any) => {
            if (
              (required && !value.length) ||
              (params.fileParams &&
                params.fileParams.size &&
                value.length &&
                value[0].size > params.fileParams.size.fileSize) ||
              (params.fileParams &&
                params.fileParams.format &&
                value.length &&
                !params.fileParams.format.fileType.includes(value[0].type))
            ) {
              return false;
            } else {
              return true;
            }
          }
        });
      case 'url':
        return func(
          yup
            .string()
            .matches(
              /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
              {
                message: `${name} must be in correct format`,
                excludeEmptyString: true
              }
            )
        );
      case 'radio':
        return func(yup.string().nullable());
      case 'disclaimer':
        return func(yup.bool().oneOf([true], 'Accept terms and conditions'));
      case 'multiSelect':
        return func(
          yup
            .array()
            .min(
              1,
              `${
                params.multiSelectMinText
                  ? params.multiSelectMinText
                  : 'Select atleast one item'
              }`
            )
        );
      case 'eventId':
        return func(
          yup
            .string()
            .required('EventId is required.')
            .test(
              'matchString',
              'Space, Comma, < and & are not allowed',
              (value) => {
                return ![' ', ',', '<', '&'].some((symbol) =>
                  value?.includes(symbol)
                );
              }
            )
            .min(6, 'EventId must be greater than 6 digits.')
            .max(15, 'EventId must be smaller than 15 digits.')
        );

      case 'weaMessage':
        return func(
          yup
            .string()
            .test(
              'matchString',
              'These symbols: {, [, \\, ], }, :, <, >, ^, ~, | are not allowed',
              (value) => {
                return ![
                  '{',
                  '[',
                  '\\',
                  ']',
                  '}',
                  '<',
                  '>',
                  '^',
                  '~',
                  '|',
                  ':'
                ].some((symbol) => value?.includes(symbol));
              }
            )
            .test(
              'removeEventManagedByLabel',
              `${name} is required`,
              (value) => {
                return required
                  ? removeEventManagedByLabel(value || '')?.trim()?.length
                    ? true
                    : false
                  : true;
              }
            )
            .max(
              params.maxNumber,
              `${name} can only have maximum ${params.maxNumber} characters`
            )
        );

      case 'weaMessageEnglish':
        return func(
          yup
            .string()
            .test(
              'matchString',
              'These symbols: {, [, \\, ], }, :, <, >, ^, ~, | are not allowed',
              (value) => {
                return ![
                  '{',
                  '[',
                  '\\',
                  ']',
                  '}',
                  '<',
                  '>',
                  '^',
                  '~',
                  '|',
                  ':'
                ].some((symbol) => value?.includes(symbol));
              }
            )
            .test(
              'matchString',
              'Only english characters are allowed',
              (value) => {
                if (value) {
                  return /^[a-zA-Z0-9 !@#$%&*()_+=`';,./?-]*$/.test(value);
                }
              }
            )
            .test(
              'removeEventManagedByLabel',
              `${name} is required`,
              (value) => {
                return required
                  ? removeEventManagedByLabel(value || '')?.trim()?.length
                    ? true
                    : false
                  : true;
              }
            )
            .max(
              params.maxNumber,
              `${name} can only have maximum ${params.maxNumber} characters`
            )
        );

      // case 'editor':
      //     return func(
      //         yup
      //             .string()
      //             .required(`${name} is required`)
      //             .max(5000, `${i18n.t(STRINGS.YUP.LEGAL_DOCUMENT_MUST_BE_LESS_THAN)}`)
      //             .test(`editorEmpty`, `${name} is required`, (item: any) => {
      //                 // When we type something and erase it, quill interprets it as <p><br></p>
      //                 return !['<p></p>', '<p><br></p>'].includes(item);
      //             }),
      //     );
      default:
        break;
    }
    return func(yup.string());
  }

  return validate();
}

export const handleDragStart = (e: any, rowData: any, setDraggingItem: any) => {
  setDraggingItem(rowData);
  e.dataTransfer?.setData('text/plain', '');
};

export const handleDrop = (
  draggingItem: any,
  tableData: any,
  targetItem: any
) => {
  if (!draggingItem) return;
  const data = Object.values(tableData);
  const currentIndex = data.indexOf(draggingItem);
  const newIndex = data.indexOf(targetItem);
  if (currentIndex !== -1 && newIndex !== -1) {
    //removing the questionfield from the array
    data.splice(currentIndex, 1);
    //inserting the removed questionfield at newindex and so all the remaining indexes adjust themselves
    data.splice(newIndex, 0, draggingItem);
  }
  //resetting the sort_oder based on index
  return data;
};

export const showPopUp = (
  text: string,
  type: SweetAlertIcon = 'info',
  popupParams?: {
    title?: string;
    action?: any;
    isActionWithButton?: boolean;
    buttonText?: string;
    isCloseAutomatically?: boolean;
  },
  isAllowedOutsideClick: boolean = true
): Promise<SweetAlertResult | void> => {
  const customHtml =
    (popupParams && popupParams.title ? `<h3>${popupParams.title}</h3>` : '') +
    (text ? text : '');
  const windowNavigator: any = window.navigator;
  const data = {
    html: customHtml,
    background: `${colorState.surface.default['primary-inverse']}`,
    showCloseButton: true,
    showConfirmButton: popupParams && popupParams.isActionWithButton,
    allowOutsideClick: isAllowedOutsideClick,
    confirmButtonText:
      popupParams && popupParams.isActionWithButton
        ? popupParams.buttonText
        : '',
    // type: type || 'info',
    customClass: {
      container: 'swal2-bottom',
      popup: `${popupParams && popupParams.isActionWithButton && 'w-auto'}`,
      content: `popup-content ${
        popupParams && popupParams.isActionWithButton && 'btn-alert-popup'
      }`,
      confirmButton: 'button-wrapper',
      icon: 'border-0 m-0 h-auto w-auto'
    },

    onOpen: (popup) => {
      // Apply inline styles to the popup
      popup.style.borderBottom = `3px solid ${Colors[String(type)]}`;
      const contentTitle = document.getElementById('swal2-content');

      if (contentTitle) {
        contentTitle.setAttribute('title', text);
      }
    },
    buttonsStyling: false,
    icon: type || 'info',
    iconHtml: customIcons[String(type)],
    backdrop:
      popupParams && popupParams.isActionWithButton
        ? true
        : !windowNavigator.brave
  };
  if (popupParams?.isCloseAutomatically) {
    data['timer'] = 3000;
  }
  if (popupParams && popupParams.action) {
    return Swal.fire(data).then(() => {
      popupParams.action();
    });
  }
  if (
    text &&
    text !== 'Given token not valid for any token type' &&
    text !== 'User not found'
  ) {
    return Swal.fire(data);
  }
  return Promise.resolve();
};

export function showError(error: any, action: any = null) {
  if (
    error?.response?.status !== 401 &&
    error?.response?.status < 500 &&
    error?.response?.data
  ) {
    let errorMessage: any = '';
    Object.keys(error.response.data).forEach((errorKey: any) => {
      if (isNaN(errorKey) && errorKey !== 'message')
        errorMessage += `${errorKey}: ${error.response.data[errorKey]}`;
      else errorMessage += `${error.response.data[errorKey]}`;
    });
    errorMessage?.length
      ? showPopUp(errorMessage, 'error', { action: action })
      : showPopUp(
          error.response.data &&
            (error.response.data.detail || error.response.data),
          'error',
          { action: action }
        );
  } else if (
    error?.response?.status === 401 &&
    error?.response?.data?.detail ===
      'You have entered an invalid Email ID or password'
  ) {
    showPopUp(error?.response?.data?.detail, 'error');
  } else if (
    error?.response?.data?.detail !==
      'Given token not valid for any token type' &&
    error?.response?.data?.detail !== 'User not found'
  ) {
    showPopUp('Something went wrong', 'error');
  }
}

export const debounceFunction = (
  timerToSaveDetails: React.MutableRefObject<NodeJS.Timeout | null>,
  delayTime: number,
  functionToExecute: () => void
) => {
  if (timerToSaveDetails.current) {
    clearTimeout(timerToSaveDetails.current);
  }
  const timer = setTimeout(() => {
    functionToExecute();
  }, delayTime);
  timerToSaveDetails.current = timer;
};

export const disableEnterPress = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};

export const getFormattedDateAndTimeDynamic = (requestedDate: any) => {
  const date = new Date(requestedDate);
  const today = new Date();

  // Check if the date is today
  if (date.toDateString() === today.toDateString()) {
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
  } else {
    // Check if the date is yesterday
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    } else {
      // Format date for other dates
      const options: any = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      };

      return date.toLocaleDateString('en-US', options);
    }
  }
};

export const getFormattedDateAndTime = (requestedDate: any) => {
  const dateTime = new Date(requestedDate);

  // Extract date components
  const day = dateTime.getDate();
  const month = dateTime.getMonth() + 1; // Months are zero-based, so we add 1
  const year = dateTime.getFullYear();

  // Extract time components
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();

  // Format date in desired format (mm/dd/yyyy)
  const formattedDate = `${month.toString().padStart(2, '0')}/${day
    .toString()
    .padStart(2, '0')}/${year}`;

  // Format time in desired format (hh:mm)
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;

  // Concatenate date and time with a space separator
  return `${formattedDate} ${formattedTime}`;
};

export const convertGeoJSONToLatLng = (geoJSON: any) =>
  geoJSON?.features?.[0]?.geometry?.coordinates[0].map(
    (coords: Array<[number, number]>) => ({ lng: coords[0], lat: coords[1] })
  );

export const convertArrayToLatLng = (array: Array<any>) => {
  return array.map((coords: any) => ({ lng: coords[0], lat: coords[1] }));
};

// GeoJSON has format as [lng, lat] but google maps api uses [lat, lng]
export const convertGeoJSONToCoords = (geoJSON: any): Array<[number, number]> =>
  geoJSON?.features?.[0]?.geometry?.coordinates[0].map(
    (coords: Array<[number, number]>) => [coords[1], coords[0]]
  );

/**
 * Clears the given polygon by removing it from the map.
 * @param polygon - The polygon to be cleared.
 */
export const clearPolygon = (polygon: google.maps.Polygon | null) =>
  polygon?.setMap(null);

/**
 * Calculates the bounds of a polygon on a Google Map.
 * @param polygon - The Google Maps polygon object.
 * @returns The bounds of the polygon.
 */
export const getPolygonBounds = (polygon: google.maps.Polygon) => {
  const bounds = new google.maps.LatLngBounds();
  const paths = polygon.getPaths();
  let path;
  for (let i = 0; i < paths.getLength(); i++) {
    path = paths.getAt(i);
    for (let ii = 0; ii < path.getLength(); ii++) {
      bounds.extend(path.getAt(ii));
    }
  }
  return bounds;
};

/**
 * Sets the view of the map to fit the given polygon bounds.
 * @param map - The Google Maps instance.
 * @param polygon - The Google Maps polygon object.
 */
export const setViewToPolygon = (
  map: google.maps.Map,
  polygon: google.maps.Polygon
) => {
  const bounds = getPolygonBounds(polygon);
  map.fitBounds(bounds);
};

/**
 * Checks if a polygon is within the boundaries of Pennsylvania.
 * @param closurePolygon The polygon to check.
 * @returns True if the polygon is within Pennsylvania, false otherwise.
 */
export const isPolygonInPA = (
  closurePolygon: Array<[number, number]>,
  boundary: Array<[]>
) => {
  const newBoundary = boundary[0].map((cords) => {
    return [cords[1], cords[0]];
  });

  return closurePolygon.every((PAPoint) =>
    booleanPointInPolygon(point(PAPoint), polygon([newBoundary]))
  );
};
/**
 *
 * @param {Array<[number, number]>} polygonCoords Array of coordinates for polygon
 * @returns {boolean | string} isPolydon invalid
 */
export const isPolygonInvalid = (
  polygonCoords: Array<[number, number]>,
  isPolygonComplete: boolean = false,
  boundary: Array<[]>
):
  | false
  | typeof STRINGS.MAX_VERTICES_VALIDATION
  | typeof STRINGS.MIN_VERTICES_VALIDATION
  | typeof STRINGS.WITHIN_STATE_LINES_VERTICES
  | typeof STRINGS.POLYGON_SHAPE_VALIDATION => {
  if (polygonCoords.length > maxVerticesInClosure) {
    return STRINGS.MAX_VERTICES_VALIDATION;
  } else if (polygonCoords.length < minVerticesInClosure) {
    return STRINGS.MIN_VERTICES_VALIDATION;
  } else if (arePointsTooClose(polygonCoords)) {
    return 'Points in the polygon are too close to each other';
  } else if (!isPolygonIntersecting(polygonCoords, isPolygonComplete)) {
    return STRINGS.POLYGON_SHAPE_VALIDATION;
  } else if (!isPolygonInPA(polygonCoords, boundary)) {
    return STRINGS.WITHIN_STATE_LINES_VERTICES;
  }
  return false;
};

export const completePolygon = (polygon: Array<[number, number]>) => {
  if (
    polygon[0][0] !== polygon[polygon.length - 1][0] &&
    polygon[0][1] !== polygon[polygon.length - 1][1]
  ) {
    polygon.push(polygon[0]);
  }

  return polygon;
};

export const getPermissionForTab = (tab: string, userDetails: any) => {
  switch (tab) {
    case '/ClosureEvent':
      return getPermission(userDetails, 'view_closureticket');
    case '/QuestionLibrary':
      return getPermission(userDetails, 'view_field');
    case '/ShapesLibrary':
      return getPermission(userDetails, 'view_savedpolygons');
    case '/ManageUsers':
      return getPermission(userDetails, 'view_applicationuser');
    case '/AfterActionReports':
      return getPermission(userDetails, 'download_after_action_report');
    case '/Resources':
      return getPermission(userDetails, 'download_excel_report');
    case '/AdminGuide':
      return true;
    case '/ManageCertificate':
      return getPermission(userDetails, 'add_ipaws_certificate');
    default:
      return false;
  }
};

const colors = [
  colorState.text.brand.primary,
  colorState.text['text-cursor-black'],
  colorState.text.success.primary,
  colorState.text.warning.primary
];

export const hashString = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

export const getUserColor = (fullname: string) => {
  const hash = hashString(fullname);
  const index = Math.abs(hash) % colors.length;
  return colors[index];
};

export const getUserInitials = (fullname: string): string => {
  const words = fullname.split(' ');
  const firstName = words[0].charAt(0);
  const lastName = words.length > 1 ? words[words.length - 1].charAt(0) : '';
  return firstName + lastName;
};

/**
 * Tracks unique user view by browser
 */
export const trackUserView = () => {
  try {
    if (!sessionStorage.getItem('userSession')) {
      ReactGA.event({
        category: 'User',
        action: 'Viewed'
      });
      sessionStorage.setItem('userSession', 'true');
    }
  } catch (e) {
    console.error('Error with Google Analytics');
  }
};

/**
 * Tracks user registration
 */
export const trackUserRegistration = () => {
  try {
    ReactGA.event({
      category: 'User',
      action: 'Registered'
    });
  } catch (e) {
    console.error('Error with Google Analytics');
  }
};

export const removeEventManagedByLabel = (value: string) => {
  if (value?.startsWith('511 PennDOT-')) {
    value = value.replaceAll('511 PennDOT- ', '');
  } else if (value?.startsWith('511 PTC-')) {
    value = value.replaceAll('511 PTC- ', '');
  } else if (value?.startsWith('511 PennDOT & PTC-')) {
    value = value.replaceAll('511 PennDOT & PTC- ', '');
  }
  return value;
};

export const weaTextMessageMap = {
  penndot: '511 PennDOT- ',
  ptc: '511 PTC- ',
  both: '511 PennDOT & PTC- ',
  '': ''
};

export const restrictedSymbol = (symbol: Array<string>, value: string) => {
  symbol.forEach((sym) => {
    if (value[value.length - 1] === sym) {
      value = value.replaceAll(sym, '');
    }
  });

  return value;
};

export const genarateColorFromString = (str: string) => {
  let hash = 0;
  for (let i = 0; i < 3; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};

export const generateHueFromString = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash += str.charCodeAt(i);
  }

  const hue = hash % 360;
  return `hsl(${hue}, 100%, 50%)`;
};

export function getContrast(hexColor: string): string {
  if (hexColor.slice(0, 1) === '#') {
    hexColor = hexColor.slice(1);
  }
  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 155 ? 'black' : 'white';
}

export const removeParamFromURL = (urlParam: string) => {
  const redirect = new URL(window.location.href);
  if (redirect.searchParams.has(urlParam)) {
    redirect.searchParams.delete(urlParam);
  }
  history.replaceState(history.state, '', redirect.href);
};

export const getURLParams = () => {
  const params = new URLSearchParams(window.location.search);
  return Object.fromEntries(params.entries());
};

/**
 * Calculates and sets row limitbased on the dimensions of given element.
 * @param elementId - The ID of the element to consider the dimensions of.
 * @param calculationOptions.minRowHeight - The minimum height of a single row.
 * @param calculationOptions.extraRows - The number of extra rows to add to the calculated limit.
 * @param currentRowLimit - The current row limit, if any.
 */

export const setTableRowLimitFromElementId = (
  elementId: string,
  calculationOptions: {
    minRowHeight: number;
    extraRows: number;
  } = {
    minRowHeight: 67,
    extraRows: 35
  },
  currentRowLimit?: number
) => {
  const { minRowHeight, extraRows } = calculationOptions;
  const selectedElement: any = document.getElementById(elementId);
  if (selectedElement) {
    const limit = Math.floor(
      selectedElement.clientHeight / minRowHeight + extraRows
    );
    if (!currentRowLimit || currentRowLimit === limit) {
      store.dispatch(setTableRowLimit({ tableRowLimit: limit }));
    }
  }
};

export const getOrderingParam = (
  sortOrder: Array<{ id: string; desc: boolean }>
): string => {
  return sortOrder.length
    ? sortOrder
        ?.map((order) => `${order.desc ? '-' : ''}${order.id}`)
        .join(',') + ',id'
    : '';
};

export const getFlippedGeoJSONForGoogleMaps = (
  coordsArray: Array<[number, number]> | [number, number],
  type: 'polygon' | 'point'
) => {
  return {
    type: 'Polygon',
    coordinates:
      type === 'polygon'
        ? [coordsArray.map((coords) => [coords[1], coords[0]])]
        : [coordsArray[1], coordsArray[0]]
  };
};

export const formatTitleAttribute = (value: any) => {
  if (typeof value === 'object' && value !== null) {
    let val = '';

    for (const key of value) {
      val += `${key?.name} \n`;
    }
    return val;
  }

  return value;
};

export const arePointsTooClose = (
  polygonArray: Array<[number, number]>
): boolean => {
  for (let i = 0; i < polygonArray.length - 1; i++) {
    const from = point(polygonArray[i]);
    const to = point(polygonArray[i + 1]);

    const distanceBetweenPoints = distance(from, to);

    if (distanceBetweenPoints < 0.0023) {
      return true;
    }
  }
  return false;
};
