import { useCallback, useEffect, useState } from 'react';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import {
  Button,
  Icon,
  Input,
  Select,
  TextArea
} from '../../Universal/NovusDSImports';
import { STRINGS } from '../../Constants/ConstantStrings';
import RightSidePanel from '../../SharedComponets/RightSidePanel';
import dayjs from 'dayjs';
import IPAWSRequestsHistory from './IPAWSRequestsHistory';
import {
  btnStyles,
  inputStyles,
  modalStyles,
  selectStyles
} from '../../Universal/NovusDSImports/variants';
import ApproveOrDisapproveForm from './ApproveOrDisapproveForm';
import ConfirmationPopupModal from '../../SharedComponets/ConfirmationPopupModal';
import {
  getCOGDetails,
  getIPAWSOptionsData,
  requestCancelAlert,
  updateCAPValues
} from '../../Store/reducers/IPAWS';
import { RootState } from '../../store';
import { EventDetailStyles } from './styles';
import { colorState } from '../../Universal/Foundation';
import AdminRequestHistory from './AdminRequestHistory';
import {
  getPermission,
  validateYupSchema,
  weaPrefillTexts
} from '../../CommonUtilities/CommonUtilities';
import axios from 'axios';
import { api_prefix } from '../../Constants/constants';
import FileDownload from 'js-file-download';
import { isProdInstance, isUatInstance } from '../../settings';
import * as yup from 'yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormSegment } from '../globalStyles';

const weaMessageAppendingUrl = isProdInstance
  ? 'https://paconnect.511pa.com/'
  : isUatInstance
  ? 'https://paconnect-t.511pa.com/'
  : 'https://paconnect.dev.ibidigital.com/';

const IPAWSEventDetails = (props: any) => {
  const { onClose } = props;
  const [showIPAWSRequestsHistory, setShowIPAWSRequestsHistory] =
      useState<boolean>(false),
    [showAdminRequestsHistory, setShowAdminRequestsHistory] =
      useState<boolean>(false),
    [isModalOpen, setIsModalOpen] = useState<boolean>(false),
    [isApprove, setIsApprove] = useState<boolean>(false),
    [isConfirmationModalOpen, setIsConfirmationModalOpen] =
      useState<boolean>(false),
    [isEditMode, setIsEditMode] = useState<boolean>(false),
    isRequestInProgress = useReduxSelector(
      (state: RootState) => state.IPAWS.isRequestInProgress
    ),
    currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    eventNames = useReduxSelector(
      (state: RootState) => state.Closures.eventNames
    ),
    selectedClosure = useReduxSelector(
      (state: RootState) => state.Common.selectedClosure
    ),
    isFetchingIPAWSOptionsData = useReduxSelector(
      (state: RootState) => state.IPAWS.isFetchingIPAWSOptionsData
    ),
    IPAWSOptionsData = useReduxSelector(
      (state: RootState) => state.IPAWS.IPAWSOptionsData
    ),
    cogDetails = useReduxSelector((state: RootState) => state.IPAWS.cogDetails),
    dispatch = useReduxDispatch();
  const isSubmittingIPAWS = useReduxSelector(
    (state) => state.IPAWS.isSubmittingIPAWS
  );

  const alertStatusOptions = {
    not_submitted: 'Not Submitted',
    pending_review: 'Pending Review',
    approved: 'Approved',
    disapproved: 'Disapproved',
    cancelled: 'Cancelled'
  };

  const IPAWSOptionTypes = {
    'Event Category': 'event_type',
    Event: 'event',
    Urgency: 'urgency',
    Severity: 'severity',
    Certainty: 'certainity',
    'WEA Handling': 'wea_heandling'
  };

  const customConfirmationStyles = {
    content: {
      minWidth: '20%',
      minHeight: '10vh'
    },
    overlay: {
      backgroundColor: 'rgba(114, 114, 114, 0.294)'
    }
  };

  const customAdminHistoryStyles = {
    content: {
      maxWidth: '50%',
      maxHeight: '60%'
    },
    overlay: {
      backgroundColor: 'rgba(114, 114, 114, 0.294)'
    }
  };

  const customStyles = {
    content: {
      maxWidth: '60%',
      maxHeight: '60%'
    }
  };

  const EditableFields = [
    STRINGS.EVENT_CATEGORY,
    STRINGS.EVENT,
    STRINGS.URGENCY,
    STRINGS.SEVERITY,
    STRINGS.CERTAINITY,
    STRINGS.WEA_HANDLING
  ];

  const TextAreaFields = [
    STRINGS.CMAM_WEA_TEXT,
    STRINGS.CMAM_WEA_LONG_TEXT,
    STRINGS.CMAM_SPANISH_WEA_TEXT,
    STRINGS.CMAM_SPANISH_WEA_LONG_TEXT
  ];

  useEffect(() => {
    dispatch(getCOGDetails({}));
    dispatch(getIPAWSOptionsData({}));
  }, []);

  const getEventTypeOptions = useCallback(() => {
    const options: any = [];
    eventNames?.forEach((option: any) => {
      options.push({ label: option.name, value: option.id });
    });
    return options;
  }, [eventNames]);

  const getEventType = (id: number, isOption: boolean) => {
    let option: any = null;
    getEventTypeOptions()?.forEach((event: any) => {
      if (event.value === id) {
        option = event;
      }
    });
    return isOption ? option : option?.label;
  };

  const getOptions = useCallback(
    (type: string) => {
      const options: any = [];
      const optionsRawData =
        type === 'WEA Handling'
          ? IPAWSOptionsData?.['wea_heandling'] &&
            Object.keys(IPAWSOptionsData?.['wea_heandling'])
          : IPAWSOptionsData?.[IPAWSOptionTypes[type]];
      optionsRawData?.forEach((option: any, index: number) => {
        options.push({ label: option, value: index });
      });
      return options;
    },
    [IPAWSOptionsData]
  );

  const getSelectedOption = (type: string) => {
    let option: any = {};
    getOptions(type)?.forEach((item: any) => {
      if (item.label === selectedClosure[IPAWSOptionTypes[type]]) {
        option = item;
      }
    });
    return option;
  };

  const getDefaultValues = useCallback(() => {
    const dict = {
      event_type: getEventType(selectedClosure?.event_type, true),
      event: getSelectedOption(STRINGS.EVENT),
      urgency: getSelectedOption(STRINGS.URGENCY),
      certainity: getSelectedOption(STRINGS.CERTAINITY),
      severity: getSelectedOption(STRINGS.SEVERITY),
      wea_heandling: getSelectedOption(STRINGS.WEA_HANDLING)
    };

    return dict;
  }, [IPAWSOptionsData, getSelectedOption, selectedClosure]);

  const schema = yup.object().shape({
    event_type: validateYupSchema('select', STRINGS.YUP.EVENT_CATEGORY, true),
    event: validateYupSchema('select', STRINGS.YUP.EVENT, true),
    urgency: validateYupSchema('select', STRINGS.YUP.URGENCY, true),
    severity: validateYupSchema('select', STRINGS.YUP.SEVERITY, true),
    certainity: validateYupSchema('select', STRINGS.YUP.CERTAINITY, true),
    wea_heandling: validateYupSchema('select', STRINGS.YUP.WEA_HANDLING, true)
  });

  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(schema)
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty }
  } = methods;

  useEffect(() => {
    if (selectedClosure) {
      setIsEditMode(false);
    }
  }, [reset, selectedClosure]);

  useEffect(() => {
    if (isEditMode) reset(getDefaultValues());
  }, [isEditMode]);

  const onSubmit = (data: any) => {
    const payload = { id: selectedClosure?.id };
    Object.keys(data).forEach((param: any) => {
      if (param === 'event_type') payload[param] = data[param]?.value;
      else payload[param] = data[param]?.label;
    });
    dispatch(updateCAPValues({ params: payload }));
    setIsEditMode(false);
  };

  const getCMAMWeaText = (isEnglish: boolean) => {
    return selectedClosure?.status?.name === 'Two-Way Communications (Open)'
      ? `${isEnglish ? 'Go to ' : 'Ir a '}` + weaMessageAppendingUrl
      : '';
  };

  const eventDetailsData = [
    {
      heading: `${STRINGS.EVENT}#`,
      description: selectedClosure?.event_id
    },
    {
      heading: STRINGS.CREATED_BY,
      description: selectedClosure?.created_by?.fullname
    },
    {
      heading: STRINGS.CMAM_WEA_TEXT,
      description: `${selectedClosure?.wea_short_msg_english} ${getCMAMWeaText(
        true
      )}`
    },
    {
      heading: STRINGS.CMAM_WEA_LONG_TEXT,
      description: `${selectedClosure?.wea_long_msg_english} ${getCMAMWeaText(
        true
      )}`
    },
    {
      heading: STRINGS.CMAM_SPANISH_WEA_TEXT,
      description:
        selectedClosure?.wea_short_msg_spanish?.length >
        weaPrefillTexts[selectedClosure?.maintained_by].length
          ? `${selectedClosure?.wea_short_msg_spanish} ${getCMAMWeaText(false)}`
          : 'N/A'
    },
    {
      heading: STRINGS.CMAM_SPANISH_WEA_LONG_TEXT,
      description:
        selectedClosure?.wea_short_msg_spanish?.length >
        weaPrefillTexts[selectedClosure?.maintained_by].length
          ? ` ${selectedClosure?.wea_long_msg_spanish} ${getCMAMWeaText(false)}`
          : 'N/A'
    },
    {
      heading: STRINGS.DESCRIPTION,
      description: selectedClosure?.description
    },
    {
      heading: STRINGS.JURISDICTIONS_AFFECTED,
      description: selectedClosure?.jurisdictions
    },
    {
      heading: STRINGS.ALERT_DURATION_HOUR_S,
      description: selectedClosure?.alert_duration
    },
    {
      heading: STRINGS.EVENT_CATEGORY,
      description: getEventType(selectedClosure?.event_type, false)
    },
    {
      heading: STRINGS.EVENT,
      description: selectedClosure?.event
    },
    {
      heading: STRINGS.URGENCY,
      description: selectedClosure?.urgency
    },
    {
      heading: STRINGS.SEVERITY,
      description: selectedClosure?.severity
    },
    {
      heading: STRINGS.CERTAINITY,
      description: selectedClosure?.certainity
    },
    {
      heading: STRINGS.WEA_HANDLING,
      description: selectedClosure?.wea_heandling
    },
    {
      heading: STRINGS.INCIDENT_STATUS,
      description: selectedClosure?.status?.name
    },
    {
      heading: STRINGS.LAST_UPDATED,
      description: dayjs(selectedClosure?.modified).format('MM/DD/YYYY hh:mm A')
    },
    {
      heading: STRINGS.REQUESTED_ALERT_TYPE,
      description: selectedClosure?.requested_alert_type
    },
    {
      heading: STRINGS.REQUESTED_BY,
      description: selectedClosure?.last_updated_by?.fullname
    },
    {
      heading: STRINGS.ALERT_STATUS,
      description: alertStatusOptions[selectedClosure?.alert_status]
    }
  ];

  const sectionBody = () => {
    return (
      <EventDetailStyles>
        <FormProvider {...methods}>
          <FormSegment onSubmit={handleSubmit(onSubmit)}>
            {!isEditMode &&
              selectedClosure?.alert_status === 'pending_review' && (
                <div className="event-details-edit">
                  <p className="d-flex align-items-center justify-content-center gap-2">
                    <h1 className="pe-3">{STRINGS.EDIT_EVENT_DETAILS}</h1>
                    <Button
                      {...btnStyles.secondary}
                      className="px-2"
                      onClick={() => setIsEditMode(true)}
                    >
                      <Icon
                        icon={'edit_02'}
                        width={'16px'}
                        height={'16px'}
                        stroke={`${colorState.icon.brand.primary}`}
                      />
                      {STRINGS.EDIT}
                    </Button>
                  </p>
                </div>
              )}
            <div className="event-details-wrapper">
              {eventDetailsData.map((item, index: number) => {
                if (isEditMode) {
                  return EditableFields.includes(item.heading) ? (
                    <Controller
                      name={IPAWSOptionTypes[item.heading]}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            displayName={item.heading}
                            placeHolder={
                              isFetchingIPAWSOptionsData
                                ? STRINGS.LOADING
                                : STRINGS.SELECT_OPTION
                            }
                            {...field}
                            options={
                              item.heading === 'Event Category'
                                ? getEventTypeOptions()
                                : getOptions(item.heading)
                            }
                            isSearchable
                            asterisk={false}
                            {...selectStyles}
                          />
                        );
                      }}
                    />
                  ) : TextAreaFields?.includes(item.heading) ? (
                    <TextArea
                      label={item.heading}
                      type="text"
                      isMargin={false}
                      disabled
                      value={item.description}
                      {...inputStyles}
                    />
                  ) : (
                    <Input
                      label={item.heading}
                      type="text"
                      isMargin={false}
                      disabled
                      value={item.description}
                      {...inputStyles}
                    />
                  );
                }
                return (
                  <div className="event-details-data" key={index}>
                    <h1>{item.heading}</h1>
                    <p>{item.description}</p>
                  </div>
                );
              })}
              <div className="clickable-links">
                {getPermission(currentUser, 'download_situation_report') && (
                  <div
                    className="d-flex gap-1 align-items-center mb-3"
                    tabIndex={0}
                    onKeyDown={async (e: any) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        await axios
                          .get(
                            `${api_prefix}closures/${selectedClosure?.id}/get_situation_report/`,
                            { responseType: 'blob' }
                          )
                          .then((response) => {
                            FileDownload(
                              response.data,
                              `SituationReport_${
                                selectedClosure?.id
                              }_${dayjs().format('YYYYMMDDHHmmss')}.pdf`
                            );
                          });
                      }
                    }}
                  >
                    <p
                      className="mb-0"
                      onClick={async () => {
                        await axios
                          .get(
                            `${api_prefix}closures/${selectedClosure?.id}/get_situation_report/`,
                            { responseType: 'blob' }
                          )
                          .then((response) => {
                            FileDownload(
                              response.data,
                              `SituationReport_${
                                selectedClosure?.id
                              }_${dayjs().format('YYYYMMDDHHmmss')}.pdf`
                            );
                          });
                      }}
                    >
                      {STRINGS.SITREP}
                    </p>
                    <Icon
                      icon="file_06"
                      width={16}
                      height={16}
                      stroke={colorState.icon.info['primary-strong']}
                      className="cursor-pointer"
                    />
                  </div>
                )}
                <p
                  onClick={() => setShowIPAWSRequestsHistory(true)}
                  tabIndex={0}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      setShowIPAWSRequestsHistory(true);
                    }
                  }}
                >
                  {STRINGS.IPAWS_REQUESTS_HISTORY}
                </p>
                {(selectedClosure?.alert_status !== 'pending_review' ||
                  selectedClosure?.alert_status !== 'not_submitted') && (
                  <p
                    onClick={() => setShowAdminRequestsHistory(true)}
                    tabIndex={0}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        setShowAdminRequestsHistory(true);
                      }
                    }}
                  >
                    {STRINGS.ADMIN_REQUEST_HISTORY}
                  </p>
                )}
              </div>
            </div>
            {selectedClosure?.alert_status === 'pending_review' &&
            selectedClosure.alert_duration ? (
              <div className="footer">
                {isEditMode ? (
                  <>
                    <Button
                      onClick={(e: any) => {
                        e.preventDefault();
                        setIsEditMode(false);
                      }}
                      {...btnStyles.secondary}
                    >
                      {STRINGS.CANCEL}
                    </Button>
                    <Button
                      disabled={!isDirty}
                      type="submit"
                      {...btnStyles.primary}
                    >
                      {STRINGS.SAVE}
                    </Button>
                  </>
                ) : (
                  <>
                    {getPermission(currentUser, 'decline_ipaws_alert') && (
                      <Button
                        onClick={(e: any) => {
                          e.preventDefault();
                          setIsModalOpen(true);
                        }}
                        {...btnStyles.warningSecondary}
                      >
                        {STRINGS.DISAPPROVE}
                      </Button>
                    )}
                    {getPermission(currentUser, 'approve_ipaws_alert') && (
                      <Button
                        onClick={(e: any) => {
                          e.preventDefault();
                          setIsApprove(true);
                          setIsModalOpen(true);
                        }}
                        disabled={cogDetails?.connection_status !== 'Connected'}
                        {...btnStyles.primary}
                      >
                        {STRINGS.APPROVE}
                      </Button>
                    )}
                  </>
                )}
              </div>
            ) : null}
            {!isEditMode && selectedClosure?.alert_status === 'approved' && (
              <div className="footer">
                <Button
                  onClick={(e: any) => {
                    e.preventDefault();
                    setIsConfirmationModalOpen(true);
                  }}
                  disabled={isRequestInProgress}
                  {...btnStyles.primary}
                >
                  {STRINGS.CANCEL_WEA}
                </Button>
              </div>
            )}
          </FormSegment>
        </FormProvider>
      </EventDetailStyles>
    );
  };

  const handleCloseConfirm = () => {
    dispatch(
      requestCancelAlert({
        closureId: selectedClosure?.id,
        onComplete: () => {
          onClose();
          setIsConfirmationModalOpen(false);
        }
      })
    );
  };

  const renderFooter = () => {
    return (
      <div className="footer">
        <Button
          onClick={() => setIsConfirmationModalOpen(false)}
          disabled={isSubmittingIPAWS}
          {...btnStyles.default}
        >
          {STRINGS.CANCEL}
        </Button>
        <Button
          onClick={handleCloseConfirm}
          disabled={isSubmittingIPAWS}
          {...btnStyles.danger}
        >
          {isSubmittingIPAWS ? STRINGS.IN_PROGRESS : STRINGS.CONFIRM}
        </Button>
      </div>
    );
  };

  return (
    <>
      <RightSidePanel
        header={STRINGS.EVENT_DETAILS}
        body={sectionBody()}
        onClose={onClose}
      />
      <IPAWSRequestsHistory
        isBodyPaddingNone={true}
        isOpen={showIPAWSRequestsHistory}
        postModalClose={() => setShowIPAWSRequestsHistory(false)}
        selectedClosure={selectedClosure}
        showHeader={true}
        title={`${STRINGS.IPAWS_REQUESTS_HISTORY_FOR} "${selectedClosure?.wea_short_msg_english}"`}
        {...modalStyles}
        style={customStyles}
      />
      <AdminRequestHistory
        isBodyPaddingNone
        isOpen={showAdminRequestsHistory}
        title={STRINGS.ADMIN_REQUEST_HISTORY}
        selectedClosure={selectedClosure}
        showHeader={true}
        getEventType={getEventType}
        alertStatusOptions={alertStatusOptions}
        postModalClose={() => setShowAdminRequestsHistory(false)}
        setShowAdminRequestsHistory={setShowAdminRequestsHistory}
        {...modalStyles}
        style={customAdminHistoryStyles}
      />
      {isModalOpen && (
        <ApproveOrDisapproveForm
          isBodyPaddingNone
          isApprove={isApprove}
          isOpen={isModalOpen}
          shouldCloseOnEsc={isSubmittingIPAWS}
          closeBtnDisabled={isSubmittingIPAWS}
          showHeader={true}
          title={
            isApprove
              ? STRINGS.FORM_APPROVAL_DETAILS
              : STRINGS.DISAPPROVE_CONFIRMATION
          }
          postModalClose={() => {
            setIsApprove(false);
            setIsModalOpen(false);
          }}
          selectedClosure={selectedClosure}
          onClose={onClose}
          {...modalStyles}
          style={customStyles}
        />
      )}
      <ConfirmationPopupModal
        isOpen={isConfirmationModalOpen}
        showHeader={false}
        showFooter={false}
        style={customConfirmationStyles}
        postModalClose={() => {
          setIsConfirmationModalOpen(false);
        }}
        closeModal={() => setIsConfirmationModalOpen(false)}
        showBorderTop={false}
        isBodyPaddingNone={true}
        description={'Are you sure you want to cancel this IPAWS alert?'}
        handleCloseConfirm={handleCloseConfirm}
        customFooter={renderFooter()}
      />
    </>
  );
};

export default IPAWSEventDetails;
