import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  QuestionType,
  createQuestionType,
  deleteQuestionType,
  notifyQuestionAction,
  receivedQuestionsLibraryDataType,
  receivedQuestionsclosuresDataType,
  RequestQuestionsLibraryType,
  RequestQuestionsclosuresType,
  showNewlyAddedQuestionPopupType,
  RequestQuestionsCategories,
  addNewCategoryAction,
  deleteCategoryAction
} from '../actions/ClosureQuestions';

const initialState: QuestionType = {
  isFetchingQuestionsLibraryData: false,
  isFetchingQuestionsclosuresData: false,
  questionslibraryData: null,
  closuresQuestionsData: null,
  categories: [],
  isShowNewlyAddedQuestionsToUsers: false,
  isSubmitting: false,
  newQuestionId: null
};

export const QuestionsSlice = createSlice({
  name: 'Questions',
  initialState,
  reducers: {
    requestQuestionsLibraryData: (
      state,
      _action: RequestQuestionsLibraryType
    ) => {
      state.isFetchingQuestionsLibraryData = true;
    },
    requestQuestionsclosureData: (
      state,
      _action: RequestQuestionsclosuresType
    ) => {
      state.isFetchingQuestionsclosuresData = true;
    },
    requestQuestionsLibraryFailed: (state) => {
      state.isFetchingQuestionsLibraryData = false;
    },
    requestQuestionsclosureFailed: (state) => {
      state.isFetchingQuestionsclosuresData = false;
    },
    receivedQuestionsLibraryData: (
      state,
      action: receivedQuestionsLibraryDataType
    ) => {
      if (action.payload.shouldAppend && state.questionslibraryData?.results) {
        action.payload.libraryQuestionsData.results = [
          ...state.questionslibraryData.results,
          ...action.payload.libraryQuestionsData.results
        ];
      }
      state.questionslibraryData = action.payload.libraryQuestionsData;
      state.isFetchingQuestionsLibraryData = false;
    },
    reveivedQuestionsclosureData: (
      state,
      action: receivedQuestionsclosuresDataType
    ) => {
      state.closuresQuestionsData = action.payload.data;
      state.isFetchingQuestionsclosuresData = false;
    },
    addClosureQuestion: (_state, _action: createQuestionType) => {},
    getclosureCategories: (_state, _action: RequestQuestionsCategories) => {},
    getclosuresCategoriesReceived: (state, action: PayloadAction<any>) => {
      state.categories = action.payload.results.map((obj) => ({
        ...obj,
        value: obj.name,
        label: obj.name
      }));
    },
    deleteQuestion: (_state, _action: deleteQuestionType) => {},
    showNewlyAddedQuestionPopup: (
      state,
      action: showNewlyAddedQuestionPopupType
    ) => {
      state.isShowNewlyAddedQuestionsToUsers =
        action.payload.isShowNewlyAddedQuestionsToUsers;
      state.newQuestionId = action.payload.newQuestionId;
    },
    notifyNewQuestions: (_state, _action: notifyQuestionAction) => {},
    addEditCategory: (_state, _action: addNewCategoryAction) => {},
    deleteCategory: (_state, _action: deleteCategoryAction) => {},
    submittingQuestion: (state) => {
      state.isSubmitting = true;
    },
    submitted: (state) => {
      state.isSubmitting = false;
    }
  }
});

export const {
  receivedQuestionsLibraryData,
  reveivedQuestionsclosureData,
  requestQuestionsLibraryData,
  requestQuestionsclosureData,
  requestQuestionsLibraryFailed,
  requestQuestionsclosureFailed,
  addClosureQuestion,
  getclosureCategories,
  getclosuresCategoriesReceived,
  deleteQuestion,
  showNewlyAddedQuestionPopup,
  notifyNewQuestions,
  submittingQuestion,
  addEditCategory,
  deleteCategory,
  submitted
} = QuestionsSlice.actions;
export default QuestionsSlice.reducer;
