import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import {
  removeParamFromURL,
  showError,
  showPopUp,
  trackUserRegistration
} from '../../CommonUtilities/CommonUtilities';
import { STRINGS } from '../../Constants/ConstantStrings';
import { api_prefix } from '../../Constants/constants';
import {
  closureQuestionsReceived,
  getClosureQuestions,
  getPublicClosuresData,
  getPublicClosuresDataReceived,
  getUserPhoneNumber,
  manageLocationReceived,
  manageLocationRequest,
  messageHistory,
  messageHistoryDataReceived,
  registerSubmit,
  registerSubmittedSuccessfully,
  submitted,
  userPhoneNumberReceived
} from '../reducers/PublicView';

function* getPublicClosuresDataSaga() {
  try {
    const url = `${api_prefix}publicview/get_all_closures/?limit=all`;
    const json = yield axios.get(url).then((response) => response.data);
    yield put(getPublicClosuresDataReceived(json));
  } catch (error: any) {
    showError(error?.response?.data?.message, 'error');
    yield put(getPublicClosuresDataReceived({ data: null }));
  }
}

function* getClosureQuestionsSaga({ payload }: any) {
  try {
    let url: string;
    if (payload.questions) {
      url = `${api_prefix}publicview/get_specific_closure_questions/?closure_id=${payload.closureId}&questions=${payload.questions}`;
    } else {
      url = `${api_prefix}publicview/user_closure_questions/?closure_id=${payload.closureId}&limit=all`;
    }
    const json = yield axios.get(url).then((response) => response.data);
    yield put(closureQuestionsReceived(json));
  } catch (error: any) {
    showError(error?.response?.data?.message, 'error');
    yield put(closureQuestionsReceived({ data: null }));
  }
}

function* registerSubmitSaga({ payload }: any) {
  try {
    let url: string;
    if (payload.isNewQuestionsSubmit) {
      url = `${api_prefix}publicview/update_users_response/`;
    } else {
      url = `${api_prefix}publicview/users_response/`;
    }
    yield axios.post(url, payload.data).then((response) => response.data);
    yield put(
      registerSubmittedSuccessfully({
        isRegisterSuccesful: true,
        isNewQuestionsSubmit: payload.isNewQuestionsSubmit
      })
    );
    trackUserRegistration();
  } catch (error: any) {
    yield put(submitted({ isRegisterUnsuccessful: true }));
    const errorMessage =
      error?.response?.data?.[0] ||
      error?.response?.data?.message ||
      error?.response?.data?.phone_number?.[0] ||
      error?.response?.data?.Error?.[0] ||
      'Something went wrong';

    showPopUp(errorMessage, 'error');
  }
}

function* manageLocationRequestSaga({ payload }: any) {
  try {
    const url = `${api_prefix}publicview/update_location/`;
    yield axios
      .post(url, payload.manageLocationPayload)
      .then((response) => response.data);
    const result = {
      isManageLocationUpdated: true,
      message: payload.manageLocationPayload.location_denied
        ? STRINGS.YOUR_CURRENT_LOCATION_REMOVED
        : STRINGS.YOUR_CURRENT_LOCATION_UPDATED
    };
    yield put(manageLocationReceived({ result }));
  } catch (error: any) {
    yield put(submitted({}));
    const result = {
      isManageLocationUpdated: false,
      message: error?.response?.data
    };
    yield put(manageLocationReceived({ result }));
    removeParamFromURL('user_uuid');
  }
}

function* messageHistoryRequestSaga({ payload }: any) {
  try {
    let url: string;
    if (!payload.message_type) {
      url = `${api_prefix}publicview/get_public_messages/?closure_id=${payload.closureId}`;
    } else {
      url = `${api_prefix}publicview/get_public_messages/?closure_id=${payload.closureId}&message_type=${payload.message_type}`;
    }
    const json = yield axios.get(url).then((response) => response.data);
    yield put(messageHistoryDataReceived(json));
  } catch (error: any) {
    showError(STRINGS.UNABLE_TO_RETRIEVE, 'error');
  }
}

function* getRegisteredUsersPhoneNumberSaga({ payload }) {
  try {
    const url = `${api_prefix}publicview/get_registered_user_phonenumber/`;
    const json: any = yield axios
      .post(url, { reg_user: payload.phoneNumberUUID })
      .then((response) => response?.data);
    yield put(userPhoneNumberReceived({ phoneNumber: json?.phone_number }));
  } catch (error) {
    yield put(userPhoneNumberReceived({ phoneNumber: null }));
    removeParamFromURL('user_uuid');
  }
}

export default function* watchPublicView() {
  yield takeLatest(getPublicClosuresData, getPublicClosuresDataSaga);
  yield takeLatest(getClosureQuestions, getClosureQuestionsSaga);
  yield takeLatest(registerSubmit, registerSubmitSaga);
  yield takeLatest(manageLocationRequest, manageLocationRequestSaga);
  yield takeLatest(messageHistory, messageHistoryRequestSaga);
  yield takeLatest(getUserPhoneNumber, getRegisteredUsersPhoneNumberSaga);
}
