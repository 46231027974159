import createSagaMiddleware from '@redux-saga/core';
import saga from './Store/sagas';
import rootReducer from './Store/reducers';
import { createLogger } from 'redux-logger';
import { isDevInstance, isTesting, isUatInstance } from './settings';
import { setupStore } from './setUpStore';
const sagaMiddleware = createSagaMiddleware();
const logger = createLogger({
  collapsed: true,
  duration: true
  // predicate: () => ENVIRONMENT !== 'production',
});

const middlewares: any = [sagaMiddleware];
if ((isDevInstance || isUatInstance) && !isTesting) {
  middlewares.push(logger);
}

export const store = setupStore(middlewares);

sagaMiddleware.run(saga);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
