import { Controller, useForm } from 'react-hook-form';
import { validateYupSchema } from '../../CommonUtilities/CommonUtilities';
import { STRINGS } from '../../Constants/ConstantStrings';
import {
  Button,
  Icon,
  Input,
  Modal,
  TextArea
} from '../../Universal/NovusDSImports';
import { FormSegment } from '../globalStyles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import {
  requestAlertApprove,
  requestAlertDisapprove
} from '../../Store/reducers/IPAWS';
import { RootState } from '../../store';
import { useEffect, useState } from 'react';
import {
  btnStyles,
  inputStyles,
  statusVariants
} from '../../Universal/NovusDSImports/variants';
import { colorState } from '../../Universal/Foundation';
import { ApprovePopupStyles, DisapprovePopupStyles } from './styles';
import PALoader from '../../SharedComponets/PALoader';

const ApproveOrDisapporveForm = (props: any) => {
  const { isApprove, selectedClosure, postModalClose, onClose } = props;

  const [newPasswordVisibility, setNewPasswordVisibility] =
      useState<boolean>(false),
    isRequestInProgress = useReduxSelector(
      (state: RootState) => state.IPAWS.isRequestInProgress
    ),
    cogDetails = useReduxSelector((state: RootState) => state.IPAWS.cogDetails),
    isFetchingCOGDetails = useReduxSelector(
      (state: RootState) => state.IPAWS.isFetchingCOGDetails
    ),
    isSubmittingIPAWS = useReduxSelector(
      (state: RootState) => state.IPAWS.isSubmittingIPAWS
    ),
    dispatch = useReduxDispatch();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  useEffect(() => {
    if (!isSubmittingIPAWS) {
      setIsSubmittingForm(false);
    }
  }, [isSubmittingIPAWS]);
  const schema = yup.object().shape({
    email: validateYupSchema('email', STRINGS.EMAIL, isApprove),
    password: validateYupSchema('string', STRINGS.PASSWORD, isApprove),
    disapproveMessage: validateYupSchema(
      'string',
      STRINGS.DISAPPROVAL_MESSAGE,
      !isApprove
    )
  });

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = methods;

  // useEffect(() => {
  //   if (isApproveRequestSuccess === false) {
  //     setError('password', {
  //       type: 'manual',
  //       message: 'Invalid email or password. Please try again.'
  //     });
  //   }
  // }, [isApproveRequestSuccess, setError]);

  const onSubmit = (data: any) => {
    setIsSubmittingForm(true);
    const payload = {};
    if (isApprove) {
      payload['email'] = data.email;
      payload['password'] = data.password;
      dispatch(
        requestAlertApprove({
          closureId: selectedClosure?.id,
          approvePayload: payload,
          onComplete: () => {
            postModalClose();
            onClose();
          }
        })
      );
    } else {
      payload['message'] = data.disapproveMessage;
      dispatch(
        requestAlertDisapprove({
          closureId: selectedClosure?.id,
          disapprovePayload: payload,
          onComplete: () => {
            postModalClose();
            onClose();
          }
        })
      );
    }
  };

  const ApprovalDetails = [
    {
      heading: `${STRINGS.COG_ID} : `,
      description: cogDetails?.logon_cog_id
    },
    {
      heading: `${STRINGS.EVENT_CERTIFICATE_EXPIRATION_DATE} : `,
      description: cogDetails?.logon_certificate_expiry
    },
    {
      heading: `${STRINGS.IPAWS_CONNECT_STATUS} : `,
      description: (
        <div style={statusVariants.success} className="px-2 py-1 rounded-pill">
          {cogDetails?.connection_status}
        </div>
      )
    },
    {
      heading: `${STRINGS.ALERT_DURATION_HOUR_S} : `,
      description: selectedClosure?.alert_duration
    }
  ];

  return (
    <>
      <FormSegment
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className="overflow-auto"
      >
        {isApprove ? (
          isFetchingCOGDetails ? (
            <PALoader />
          ) : (
            <ApprovePopupStyles className="d-flex flex-grow-1 overflow-auto flex-column">
              <div>
                <div className="form-details">
                  <p className="form-description-text">
                    {STRINGS.APPROVAL_FORM_DESCRIPTION_TEXT}
                  </p>
                  {ApprovalDetails.map((item, index: number) => {
                    return (
                      <div
                        className="d-flex align-items-center gap-1"
                        key={index}
                      >
                        <h1 className="details-heading">{item.heading}</h1>
                        <p className="description">{item.description}</p>
                      </div>
                    );
                  })}
                </div>
                <FormSegment className="formset-fields p-1">
                  <div className="form-group">
                    <Controller
                      name={'email'}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          id="email"
                          label="Email"
                          value={value}
                          type="email"
                          placeholder={STRINGS.ENTER_EMAIL}
                          onChange={onChange}
                          autoComplete="email"
                          isMargin={false}
                          disabled={isSubmittingForm}
                          onPaste={(e) => e.preventDefault()}
                          asterisk
                          {...inputStyles}
                          error={errors?.email}
                          hintText={errors?.email?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="form-group mb-0">
                    <Controller
                      name={'password'}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          id="password"
                          label="Password"
                          asterisk
                          type={newPasswordVisibility ? 'text' : 'password'}
                          value={value}
                          autoComplete="current-password"
                          placeholder="Enter Password"
                          onPaste={(e) => e.preventDefault()}
                          onChange={onChange}
                          isMargin={false}
                          disabled={isSubmittingForm}
                          onRightIconActionClick={() =>
                            setNewPasswordVisibility(!newPasswordVisibility)
                          }
                          iconRight={
                            newPasswordVisibility ? (
                              <Icon
                                icon={'eye'}
                                fill="none"
                                stroke={`${colorState.icon.default.secondary}`}
                              />
                            ) : (
                              <Icon
                                icon={'eye_off'}
                                fill="none"
                                stroke={`${colorState.icon.default.secondary}`}
                              />
                            )
                          }
                          {...inputStyles}
                          error={errors?.password}
                          hintText={errors?.password?.message}
                        />
                      )}
                    />
                  </div>
                </FormSegment>
              </div>
            </ApprovePopupStyles>
          )
        ) : (
          <DisapprovePopupStyles>
            <FormSegment className="formset-fields p-1">
              <div className="form-group mb-0">
                <Controller
                  name="disapproveMessage"
                  control={control}
                  render={({ field }) => {
                    return (
                      <>
                        <TextArea
                          {...field}
                          disabled={isSubmittingForm}
                          label="Please provide some notes for disapproval"
                          placeholder="Write a text message"
                          hintText={errors?.disapproveMessage?.message || ''}
                          {...inputStyles}
                          asterisk
                          error={errors?.disapproveMessage}
                          isMargin={false}
                        />
                      </>
                    );
                  }}
                />
              </div>
            </FormSegment>
          </DisapprovePopupStyles>
        )}
        <section className="footer">
          <div className="d-flex gap-3 justify-content-end w-100">
            <Button
              {...btnStyles.secondary}
              disabled={isSubmittingForm}
              onClick={postModalClose}
              type="button"
            >
              Cancel
            </Button>
            <Button
              {...btnStyles.primary}
              type="submit"
              disabled={
                isRequestInProgress ||
                isSubmittingForm ||
                cogDetails?.connection_status !== 'Connected'
              }
            >
              {isApprove
                ? isSubmittingForm
                  ? STRINGS.IN_PROGRESS
                  : STRINGS.APPROVE
                : isSubmittingForm
                ? STRINGS.IN_PROGRESS
                : STRINGS.SUBMIT}
            </Button>
          </div>
        </section>
      </FormSegment>
    </>
  );
};

export default Modal(ApproveOrDisapporveForm);
