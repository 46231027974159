import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { Button, Icon } from '../../Universal/NovusDSImports';
import { btnStyles } from '../../Universal/NovusDSImports/variants';
import { CollapseIconContainer, GridView } from './styles';
import { colorState } from '../../Universal/Foundation';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import {
  clearPolygon,
  getCentroid,
  getPermission,
  showError,
  showPopUp
} from '../../CommonUtilities/CommonUtilities';
import {
  closuresDataReceived,
  getClosuresData,
  getClosuresUnreadMessagesCount,
  getEventNames,
  getSavedPolygons,
  getTicketStatus,
  setNotificationsUpdatedClosureData,
  updateClosureNotificationCount
} from '../../Store/reducers/Closures';
import { RootState } from '../../store';
import {
  setSelectedClosure,
  setSelectedTab
} from '../../Store/reducers/Common';
import { TooltipComponent } from '../../SharedComponets/Tooltip';
import { STRINGS } from '../../Constants/ConstantStrings';
import { getCOGDetails } from '../../Store/reducers/IPAWS';
import { WebSocketHostName, WebSocketURL } from '../../settings';
import Cookies from 'js-cookie';
import {
  getMessagesData,
  setActiveStatus,
  setNewMessage
} from '../../Store/reducers/Messages';
import {
  getRegisteredUsers,
  setUpdatedRegisteredUsersCount
} from '../../Store/reducers/RegisteredUsers';

import PALoader from '../../SharedComponets/PALoader';
import {
  COGDetailsIntervalTime,
  MessagesTableRowLimit
} from '../../Constants/constants';

const GoogleMapsComponent = lazy(() => import('./GoogleMaps'));
const AddEditClosure = lazy(() => import('./AddEditClosure'));
const EventPanel = lazy(() => import('./EventPanel'));
const EventQuestions = lazy(() => import('../Questions'));
const RegisteredUsers = lazy(() => import('./RegisteredUsers'));
const ComposeMessage = lazy(() => import('./ComposeMessage'));
const Messages = lazy(() => import('../Messages'));
const IPAWSEventDetails = lazy(() => import('../IPAWS'));
const Card = lazy(() => import('../../SharedComponets/Card'));

export type TabType =
  | 'registered_users'
  | 'questions'
  | 'edit_closures'
  | 'messages'
  | 'close_event'
  | null;

export default function Closures() {
  const [isSideNav, setIsSideNav] = useState(true);
  const [showSideBar, setShowSideBar] = useState(false),
    [selectedClosureEventCard, setSelectedClosureEventCard] = useState<
      number | null
    >(null),
    [showMap, setShowMap] = useState<boolean>(true),
    [isDrawing, setIsDrawing] = useState<boolean>(false),
    [isDrawMode, setIsDrawMode] = useState<boolean>(false),
    [isRegisteredUsers, setIsRegisteredUsers] = useState<boolean>(false),
    [activeTabName, setActiveTabName] = useState<any>(),
    [selectedRegisteredUser, setselectedRegisteredUser] = useState<
      number | null
    >(null),
    [zoomLevel, setZoomLevel] = useState<number>(2),
    [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({
      lat: 0,
      lng: 0
    }),
    [affectedAreaType, setAffectedAreaType] = useState<
      'draw_polygon' | 'select_polygon' | ''
    >(''),
    selectedClosure = useReduxSelector(
      (state: RootState) => state.Common.selectedClosure
    ),
    currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    [selectedRows, setSelectedRows] = useState<any>({}),
    [drawnPolygon, setDrawnPolygon] = useState<
      null | undefined | Array<[number, number]>
    >(),
    [isEdit, setIsEdit] = useState<boolean>(false),
    [isMessageBoxOpen, setIsMessageBoxOpen] = useState<boolean>(false),
    polygonRef = useRef<null | google.maps.Polygon>(null),
    [showMapClearButton, setShowMapClearButton] = useState<boolean>(false),
    [selectedCard, setSelectedCard] = useState<any>(selectedClosure),
    [selectedMarker, setSelectedMarker] = useState<number | null>(null),
    [isCollapsableMap, setIsCollapsableMap] = useState<any>(true),
    dispatch = useReduxDispatch(),
    registeredUsersData = useReduxSelector(
      (state: RootState) => state.RegisteredUsers.registeredUsers
    ),
    selectedTab = useReduxSelector(
      (state: RootState) => state.Common.selectedTab
    ),
    closuresData = useReduxSelector(
      (state: RootState) => state.Closures.closuresData
    ),
    isMobileScreen = useReduxSelector(
      (state: RootState) => state.Common.isMobileScreen
    ),
    [isCloseEvent, setIsCloseEvent] = useState<boolean>(false),
    [registeredUsersLocation, setRegisteredUsersLocation] = useState<any>(null),
    mapRef = useRef<google.maps.Map | null>(null);

  const stateBoundary = useReduxSelector(
    (state: RootState) => state.ClientInfo.state_boundary
  );

  const openClosure = () => {
    setShowSideBar(true);
    setIsDrawing(true);
    dispatch(setSelectedClosure({ selectedClosure: null }));
    setSelectedClosureEventCard(null);
    clearPolygon(polygonRef.current);
    setDrawnPolygon(null);
  };

  const handleAddOrEditClosureClose = () => {
    setShowMapClearButton(false);
    if (polygonRef.current) {
      polygonRef.current.setMap(null);
      // polygonRef.current = null;
    }
    setShowSideBar(false);
    setIsDrawing(false);
    setIsEdit(false);
    setShowMap(true);
    dispatch(setSelectedTab({ selectedTab: '' }));
    setIsRegisteredUsers(false);
    setSelectedClosureEventCard(null);
    setIsDrawMode(false);
  };

  useEffect(() => {
    if (stateBoundary) {
      const centerPoint = getCentroid(stateBoundary.coordinates[0]);

      setMapCenter({
        lat: centerPoint[0],
        lng: centerPoint[1]
      });

      setZoomLevel(8);
    }
  }, [stateBoundary, setMapCenter]);

  useEffect(() => {
    if (getPermission(currentUser, 'view_closureticket')) {
      dispatch(getClosuresData({ limit: 'all' }));
      dispatch(getClosuresUnreadMessagesCount({ limit: 'all' }));
    }
    if (currentUser?.user_permission_set?.role_names?.includes('IPAWS Admin')) {
      dispatch(getCOGDetails({}));
      setInterval(() => {
        dispatch(getCOGDetails({}));
      }, COGDetailsIntervalTime);
    }
    if (getPermission(currentUser, 'view_events')) dispatch(getEventNames({}));
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (getPermission(currentUser, 'view_savedpolygons') && showSideBar)
      dispatch(getSavedPolygons({ limit: 'all' }));
  }, [dispatch, currentUser, showSideBar]);

  useEffect(() => {
    if (
      isEdit &&
      selectedClosure &&
      getPermission(currentUser, 'view_ticketstatus')
    ) {
      let filters = 'Two-Way Communications (Open)';
      if (
        selectedClosure?.status?.name === 'One-Way Communication (Preemptive)'
      ) {
        filters = filters + ', One-Way Communication (Preemptive)';
      } else if (selectedClosure?.status?.name === 'Pending (Draft)') {
        filters =
          filters + ', Pending (Draft), One-Way Communication (Preemptive)';
      }
      dispatch(getTicketStatus({ statusFilter: filters }));
    } else {
      dispatch(getTicketStatus({}));
    }
  }, [isEdit, selectedClosure, currentUser, dispatch]);

  useEffect(() => {
    if (!selectedClosure && localStorage.getItem('selectedClosure')) {
      closuresData?.results.forEach((closure: any) => {
        if (closure.id.toString() === localStorage.getItem('selectedClosure')) {
          dispatch(
            setSelectedClosure({
              selectedClosure: closure
            })
          );
        }
      });
    }
  }, [selectedClosure, closuresData, dispatch]);

  useEffect(() => {
    if (
      !selectedTab &&
      selectedClosure &&
      localStorage.getItem('selectedTab') &&
      localStorage.getItem('selectedTab') !== 'edit_closures' &&
      localStorage.getItem('selectedTab') !== 'close_event' &&
      localStorage.getItem('selectedTab') !== 'eventDetails'
    ) {
      if (
        currentUser?.user_permission_set.role_names.includes('Admin') ||
        currentUser?.user_permission_set.role_names.includes('Read Only Admin')
      ) {
        dispatch(
          setSelectedTab({
            selectedTab: localStorage.getItem('selectedTab') || ''
          })
        );
      } else {
        dispatch(setSelectedClosure({ selectedClosure: null }));
      }
    }
  }, [dispatch, selectedTab, selectedClosure]);

  useEffect(() => {
    return () => {
      dispatch(closuresDataReceived({ closuresData: null }));
    };
  }, [dispatch]);

  const handleMapCenter = (closure: any) => {
    setMapCenter({
      lat: closure?.centre_latitude,
      lng: closure?.centre_longitude
    });
  };

  useEffect(() => {
    if (selectedClosure) {
      handleMapCenter(selectedClosure);
      setZoomLevel(17);
    }
  }, [selectedClosure]);

  useEffect(() => {
    if (!selectedTab && closuresData) {
      closuresData.results.forEach((closure: any) => {
        if (closure.id === selectedCard?.id) {
          setZoomLevel(14);
          handleMapCenter(closure);
          setTimeout(() => {
            setZoomLevel(17);
          }, 500);
        }
      });
    }
  }, [selectedCard, selectedTab, closuresData]);

  const handleComposeMessageClose = () => {
    setIsMessageBoxOpen(false);
    setSelectedRows(null);
    setActiveTabName(null);
  };

  const onClickSideNav = () => {
    setIsSideNav(!isSideNav);
  };

  useEffect(() => {
    if (closuresData) {
      const updatedData: any = [];
      closuresData.results.forEach((closure: any) => {
        updatedData.push({ id: closure.id, registeredUsers: 0, messages: 0 });
      });
      dispatch(
        setNotificationsUpdatedClosureData({
          notificationsUpdatedClosureData: updatedData
        })
      );
    }
  }, [closuresData]);

  const reInitializeWebSocket = (webSocketConnection: any) => {
    clearTimeout(parseInt(localStorage.getItem('timeOutId') || '0'));
    clearTimeout(parseInt(localStorage.getItem('replyTimeOutID') || '0'));
    webSocketConnection.close();
    handleWebSockets();
  };

  const setTimeOutFunction = (webSocketConnection: any) => {
    const timeOutId: any = setTimeout(() => {
      try {
        webSocketConnection.send('ping');
        //setting timeout so that if there's no response from websocket within 3 seconds, then we will re-establish the connection
        const replyTimeOutId: any = setTimeout(() => {
          reInitializeWebSocket(webSocketConnection);
        }, 3000);
        localStorage.setItem('replyTimeOutID', `${replyTimeOutId}`);
      } catch (error) {
        showError(error);
      }
    }, 480000);

    //storing the value in localstorage instead of state variable as the state variables have their default values in initial useeffect
    localStorage.setItem('timeOutId', `${timeOutId}`);
  };

  const handleClearTimeouts = () => {
    clearTimeout(parseInt(localStorage.getItem('timeOutId') || '0'));
    localStorage.removeItem('timeOutId');
    clearTimeout(parseInt(localStorage.getItem('replyTimeOutID') || '0'));
    localStorage.removeItem('replyTimeOutID');
  };

  const getUpdatedData = (
    fetchRegisteredUsers: boolean,
    fetchMessages: boolean
  ) => {
    if (fetchMessages && localStorage.getItem('selectedTab') === 'messages') {
      dispatch(
        getMessagesData({
          closureId: parseInt(localStorage.getItem('selectedClosure') || ''),
          limit: MessagesTableRowLimit,
          offset: 0,
          unRead: false
        })
      );
      dispatch(setActiveStatus({ isActiveStatusChanged: true }));
    } else if (
      fetchRegisteredUsers &&
      localStorage.getItem('selectedTab') === 'registered_users'
    ) {
      dispatch(
        getRegisteredUsers({
          closureId: parseInt(localStorage.getItem('selectedClosure') || ''),
          limit: 'all',
          offset: 0
        })
      );
    }
  };

  const handleMessage = (message: any) => {
    if (
      message?.data &&
      JSON.parse(message.data)?.BODY === 'A New User Registered'
    ) {
      if (
        JSON.parse(message.data)?.Meta?.CLOSURE_ID?.toString() ===
          localStorage.getItem('selectedClosure') &&
        localStorage.getItem('selectedTab') === 'registered_users'
      ) {
        dispatch(setUpdatedRegisteredUsersCount({}));
      } else {
        dispatch(
          updateClosureNotificationCount({
            closureId: JSON.parse(message.data)?.Meta?.CLOSURE_ID,
            action: 'add_registered_user'
          })
        );
      }
    }
    if (
      message?.data &&
      JSON.parse(message.data)?.ACTION === 'NEW_MESSAGE_RECEIVED'
    ) {
      if (
        JSON.parse(message.data)?.Meta?.closure_id?.toString() ===
          localStorage.getItem('selectedClosure') &&
        localStorage.getItem('selectedTab') === 'messages'
      ) {
        dispatch(setNewMessage({ newMessage: message }));
      }
      dispatch(
        updateClosureNotificationCount({
          closureId: JSON.parse(message.data)?.Meta?.closure_id,
          action: 'add_message'
        })
      );
    }

    if (
      message?.data &&
      JSON.parse(message.data)?.ACTION === 'ACTIVE_STATUS_CHANGED' &&
      localStorage.getItem('selectedClosure') &&
      JSON.parse(message.data)?.Meta?.closure_id?.toString() ===
        localStorage.getItem('selectedClosure')
    ) {
      getUpdatedData(true, true);
      if (
        localStorage.getItem('selectedTab') === 'messages' ||
        localStorage.getItem('selectedTab') === 'registered_users'
      )
        showPopUp(
          JSON.parse(message.data)?.Meta?.deactivated
            ? STRINGS.USER_GOT_DEACTIVATED
            : STRINGS.USER_GOT_ACTIVATED,
          'info',
          {
            isCloseAutomatically: true
          }
        );
    }
    if (
      message?.data &&
      JSON.parse(message.data)?.ACTION === 'LOCATION_CHANGED' &&
      localStorage.getItem('selectedClosure') &&
      JSON.parse(message.data)?.Meta?.closure_id?.toString() ===
        localStorage.getItem('selectedClosure')
    ) {
      getUpdatedData(true, false);
      showPopUp(STRINGS.USER_UPDATED_LOCATION, 'info', {
        isCloseAutomatically: true
      });
    }
    if (
      message?.data &&
      JSON.parse(message.data)?.ACTION === 'MESSAGE_MARKED_READ' &&
      localStorage.getItem('selectedClosure') &&
      JSON.parse(message.data)?.Meta?.closure_id?.toString() ===
        localStorage.getItem('selectedClosure')
    ) {
      getUpdatedData(false, true);
      showPopUp(
        JSON.parse(message.data)?.Meta?.marked_read_count === 1
          ? STRINGS.MESSAGE_MARKED_READ
          : STRINGS.MESSAGES_MARKED_AS_READ,
        'info',
        {
          isCloseAutomatically: true
        }
      );
    }
  };

  const handleWebSockets = () => {
    let webSocketConnection: any = '';

    try {
      handleClearTimeouts();
      webSocketConnection = new WebSocket(
        `${WebSocketURL}${Cookies.get('access')}&host=${WebSocketHostName}`
      );
    } catch (error) {
      showError(error);
    }

    webSocketConnection.onopen = () => {
      setTimeOutFunction(webSocketConnection);
    };

    webSocketConnection.onclose = () => {
      localStorage.setItem('isWebSocketConnected', 'false');
    };

    //If any error occurs, we will disconnect and connect to websocket again
    webSocketConnection.onerror = () => {
      reInitializeWebSocket(webSocketConnection);
    };

    webSocketConnection.onmessage = (message: any) => {
      handleClearTimeouts();
      setTimeOutFunction(webSocketConnection);
      handleMessage(message);
    };
    return webSocketConnection;
  };

  useEffect(() => {
    //as this component might re-render, but we want the websocket to get initialised only once,
    //using isWebSocketConnected flag from local storage to avoid redundant connections
    const webSocketConnection =
      localStorage.getItem('isWebSocketConnected') === 'false'
        ? handleWebSockets()
        : null;
    localStorage.setItem('isWebSocketConnected', 'true');

    //set the localstorage flag to false on page reload
    window.addEventListener('pagehide', (event: any) => {
      if (!event.persisted) {
        localStorage.setItem('isWebSocketConnected', 'false');
      }
    });

    return () => {
      handleClearTimeouts();
      webSocketConnection?.close();
    };
  }, []);

  return (
    <div className={'w-100 grid-view'}>
      <section
        className={`left ${
          !isMobileScreen &&
          `transition ${isSideNav ? '' : 'transition-active'}`
        }`}
        tabIndex={0}
        aria-label="Left Section"
      >
        {isSideNav && (
          <Card
            className="w-100 d-flex overflow-auto flex-grow-1"
            tab-index={0}
          >
            <Suspense fallback={<PALoader />}>
              <EventPanel
                setIsEdit={setIsEdit}
                setShowMapClear={setShowMapClearButton}
                selectedMarker={selectedMarker}
                setSelectedMarker={setSelectedMarker}
                polygonRef={polygonRef}
                setShowSideBar={setShowSideBar}
                setDrawnPolygon={setDrawnPolygon}
                openClosure={openClosure}
                setIsDrawing={setIsDrawing}
                setShowMap={setShowMap}
                setIsRegisteredUsers={setIsRegisteredUsers}
                mapRef={mapRef}
                selectedClosureEventCard={selectedClosureEventCard}
                setSelectedClosureEventCard={setSelectedClosureEventCard}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                setIsCloseEvent={setIsCloseEvent}
                setRegisteredUsersLocation={setRegisteredUsersLocation}
                setMapCenter={setMapCenter}
                setZoomLevel={setZoomLevel}
                handleAddOrEditClosureClose={handleAddOrEditClosureClose}
              />
            </Suspense>
          </Card>
        )}
      </section>

      <CollapseIconContainer isSideNav={isSideNav}>
        <div
          className="collapse-icon"
          tabIndex={0}
          onKeyDown={(event: any) => {
            if (event.key === 'Enter') {
              onClickSideNav();
            }
          }}
        >
          <div
            data-tooltip-id="collapse"
            className={isSideNav ? 'collapse-left-icon' : 'collapse-right-icon'}
          >
            <Icon
              icon={`${isSideNav ? 'chevron_left' : 'chevron_right'}`}
              onClick={onClickSideNav}
            />
          </div>
          <TooltipComponent
            id="collapse"
            place="left"
            tooltipBgColor={colorState.surface.brand.primary}
            arrowColor={colorState.surface.brand.primary}
          >
            {isSideNav ? STRINGS.COLLAPSE : STRINGS.EXPAND}
          </TooltipComponent>
        </div>
      </CollapseIconContainer>
      <section
        className={`${isSideNav ? 'middle' : 'middle ms-3'}`}
        tabIndex={0}
        aria-label="Middle Section"
      >
        <Card className="d-flex flex-column">
          {selectedTab === 'questions' && (
            <Suspense fallback={<PALoader />}>
              <EventQuestions
                setIsRegisteredUsers={setIsRegisteredUsers}
                setShowMap={setShowMap}
              />
            </Suspense>
          )}
          {!selectedTab && (
            <GridView id="grid-view">
              <div className="header pb-0">
                <h1 className="mb-0">Map</h1>
                {getPermission(currentUser, 'add_closureticket') && (
                  <Button
                    disabled={showSideBar}
                    onClick={openClosure}
                    {...btnStyles.primary}
                    title="Add New Closure"
                  >
                    <Icon
                      icon={'map_pin'}
                      stroke={colorState.icon.default['primary-inverse']}
                      width={16}
                      height={16}
                    />
                    Add New Closure
                  </Button>
                )}
              </div>
            </GridView>
          )}
          {selectedTab === 'registered_users' && (
            <Suspense fallback={<PALoader />}>
              <RegisteredUsers
                setMapCenter={setMapCenter}
                setIsMessageBoxOpen={setIsMessageBoxOpen}
                isMessageBoxOpen={isMessageBoxOpen}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                setselectedRegisteredUser={setselectedRegisteredUser}
                isSideNav={isSideNav}
                selectedRegisteredUser={selectedRegisteredUser}
                setIsCollapsableMap={setIsCollapsableMap}
                isCollapsableMap={isCollapsableMap}
                setActiveTabName={setActiveTabName}
                activeTabName={activeTabName}
              />
            </Suspense>
          )}
          {selectedTab === 'messages' && (
            <Suspense fallback={<PALoader />}>
              <Messages />
            </Suspense>
          )}

          {showMap && (
            <GoogleMapsComponent
              isCollapsableMap={isCollapsableMap}
              setIsCollapsableMap={setIsCollapsableMap}
              selectedMarker={selectedMarker}
              setSelectedMarker={setSelectedMarker}
              mapRef={mapRef}
              isDrawing={isDrawing}
              setIsDrawing={setIsDrawing}
              setDrawnPolygon={setDrawnPolygon}
              isEdit={isEdit}
              drawnPolygon={drawnPolygon}
              showSideBar={showSideBar}
              isRegisteredUsers={isRegisteredUsers}
              mapCenter={mapCenter}
              polygonRef={polygonRef}
              showClearButton={showMapClearButton}
              setShowClearButton={setShowMapClearButton}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              isCloseEvent={isCloseEvent}
              registeredUsersLocation={registeredUsersLocation}
              setMapCenter={setMapCenter}
              zoomLevel={zoomLevel}
              setZoomLevel={setZoomLevel}
              isDropdownHeight={selectedTab === 'registered_users'}
              selectedRegisteredUser={selectedRegisteredUser}
              setselectedRegisteredUser={setselectedRegisteredUser}
              isDrawMode={isDrawMode}
              setIsDrawMode={setIsDrawMode}
              setAffectedAreaType={setAffectedAreaType}
              affectedAreaType={affectedAreaType}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setActiveTabName={setActiveTabName}
            />
          )}
        </Card>
      </section>
      {isMessageBoxOpen && isRegisteredUsers && selectedRows && (
        <section className="right" tabIndex={0} aria-label="Right Section">
          <Suspense fallback={<PALoader />}>
            <Card>
              <ComposeMessage
                onClose={() => {
                  setIsMessageBoxOpen(false);
                }}
                selectedUsers={Object.keys(selectedRows).map(
                  (item: any) => registeredUsersData?.results[item].id
                )}
                handleClose={handleComposeMessageClose}
                closureId={selectedClosure?.id}
              />
            </Card>
          </Suspense>
        </section>
      )}
      {showSideBar && (
        <section className="right" tabIndex={0} aria-label="Right Section">
          <Suspense fallback={<PALoader />}>
            <Card>
              <AddEditClosure
                onClose={handleAddOrEditClosureClose}
                drawnPolygon={drawnPolygon}
                isEdit={isEdit}
                setDrawnPolygon={setDrawnPolygon}
                setIsDrawing={setIsDrawing}
                polygonRef={polygonRef}
                mapRef={mapRef}
                setIsDrawMode={setIsDrawMode}
                setAffectedAreaType={setAffectedAreaType}
                affectedAreaType={affectedAreaType}
              />
            </Card>
          </Suspense>
        </section>
      )}
      {selectedTab === 'eventDetails' && (
        <section className="right" tabIndex={0} aria-label="Right Section">
          <Suspense fallback={<PALoader />}>
            <Card>
              <IPAWSEventDetails
                onClose={() => {
                  setDrawnPolygon(null);
                  dispatch(setSelectedTab({ selectedTab: '' }));
                }}
              />
            </Card>
          </Suspense>
        </section>
      )}
    </div>
  );
}
