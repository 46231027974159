import {
  lazy,
  MutableRefObject,
  Suspense,
  useEffect,
  useRef,
  useState
} from 'react';
import { colorState } from '../../Universal/Foundation';
import { Badge, Button, Icon, Lozenge } from '../../Universal/NovusDSImports';
import LeftSidePanel from '../../SharedComponets/LeftSidePanel';
import {
  btnStyles,
  confirmPopupModalStyles,
  modalStyles,
  statusVariants
} from '../../Universal/NovusDSImports/variants';
import { NoClosureEventIcon, SeenEyeIcon } from '../../Universal/Assets';
import { useReduxDispatch, useReduxSelector } from '../../Store/reduxHooks';
import dayjs from 'dayjs';
import {
  setSelectedClosure,
  setSelectedTab
} from '../../Store/reducers/Common';
import { RootState } from '../../store';
import {
  getCentroid,
  getPermission,
  removeEventManagedByLabel
} from '../../CommonUtilities/CommonUtilities';
import { STRINGS } from '../../Constants/ConstantStrings';
import { markAlertAsRead } from '../../Store/reducers/IPAWS';
import _ from 'lodash';
import { updateClosureNotificationCount } from '../../Store/reducers/Closures';
import { TooltipComponent } from '../../SharedComponets/Tooltip';
import PALoader from '../../SharedComponets/PALoader';

const NoDataFound = lazy(() => import('../../SharedComponets/NoDataFound'));
const CloseEventModal = lazy(() => import('./CloseEventModal'));
const ConfirmationPopupModal = lazy(
  () => import('../../SharedComponets/ConfirmationPopupModal')
);

interface EventPanelPropType {
  setDrawnPolygon: Function;
  setIsEdit: Function;
  setIsDrawing: Function;
  setShowSideBar: Function;
  selectedMarker: number | null;
  setSelectedMarker: Function;
  setIsRegisteredUsers: Function;
  setShowMap: Function;
  openClosure: Function;
  setShowMapClear: Function;
  polygonRef: MutableRefObject<google.maps.Polygon | null>;
  mapRef: MutableRefObject<google.maps.Map | null>;
  selectedClosureEventCard: number | null;
  setSelectedClosureEventCard: Function;
  selectedCard: any;
  setSelectedCard: Function;
  setIsCloseEvent: Function;
  setRegisteredUsersLocation: Function;
  setMapCenter: Function;
  setZoomLevel: Function;
  handleAddOrEditClosureClose: Function;
}

export default function EventPanel(props: EventPanelPropType) {
  const {
    setDrawnPolygon,
    setIsEdit,
    setIsDrawing,
    setShowSideBar,
    setIsRegisteredUsers,
    setShowMap,
    openClosure,
    setShowMapClear,
    polygonRef,
    mapRef,
    selectedCard,
    setSelectedCard,
    setIsCloseEvent,
    setRegisteredUsersLocation,
    setMapCenter,
    setZoomLevel,
    handleAddOrEditClosureClose
  } = props;
  const [listVisible, setListVisible] = useState(true);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false),
    [closeEventPayload, setCloseEventPayload] = useState<any>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false),
    [cards, setCards] = useState<any>(),
    dispatch = useReduxDispatch(),
    closuresData = useReduxSelector(
      (state: RootState) => state.Closures.closuresData
    ),
    isFetchingCOGDetails = useReduxSelector(
      (state: RootState) => state.IPAWS.isFetchingCOGDetails
    ),
    selectedClosure = useReduxSelector(
      (state: RootState) => state.Common.selectedClosure
    ),
    selectedTab = useReduxSelector(
      (state: RootState) => state.Common.selectedTab
    ),
    currentUser = useReduxSelector(
      (state: RootState) => state.Common.currentUserDetails
    ),
    isMobileScreen = useReduxSelector(
      (state: RootState) => state.Common.isMobileScreen
    ),
    isCloseEventSuccessful = useReduxSelector(
      (state: RootState) => state.Closures.closingEventSuccessful
    ),
    isMarkingAlertAsReadInProgress = useReduxSelector(
      (state: RootState) => state.IPAWS.isMarkAsReadInProgress
    ),
    notificationsUpdatedClosureData = useReduxSelector(
      (state: RootState) => state.Closures.notificationsUpdatedClosureData
    ),
    cogDetails = useReduxSelector((state: RootState) => state.IPAWS.cogDetails),
    isFetchingClosuresData = useReduxSelector(
      (state: any) => state.Closures.isFetchingClosureData
    ),
    closuresUnreadMessagesCount = useReduxSelector(
      (state: RootState) => state.Closures.closuresUnreadMessagesCount
    );

  useEffect(() => {
    if (closuresData?.results.length) {
      const data: any = [];
      closuresData.results.forEach((closure: any, index: number) => {
        data.push({
          id: closure.id,
          heading: removeEventManagedByLabel(closure.wea_short_msg_english),
          statusVariant: {
            status:
              closure.alert_status === 'approved'
                ? statusVariants.success
                : closure.alert_status === 'disapproved' ||
                  closure.alert_status === 'cancelled'
                ? statusVariants.danger
                : statusVariants.warning,
            content:
              closure.alert_status === 'approved'
                ? 'WEA Approved'
                : closure.alert_status === 'disapproved'
                ? 'WEA Disapproved'
                : closure.alert_status === 'cancelled'
                ? 'WEA Cancelled'
                : closure.alert_status === 'not_submitted'
                ? 'WEA Not Submitted'
                : 'WEA Review Pending'
          },
          status: closure.status.name,
          alert_status: closure.alert_status,
          last_updated: dayjs(closure.modified).format('MM/DD/YYYY  hh:mm A'),
          read_by: closure.read_by,
          registered_users_count:
            notificationsUpdatedClosureData?.[index]?.registeredUsers
        });
      });
      setCards(data);
      // setSelectedClosure(closuresData[selectedItem]);
      // setSelectedClosure(closuresData[0]);
    }
  }, [closuresData, notificationsUpdatedClosureData]);

  const menuItems = [
    { icon: 'user_check_01', text: 'Registered Users', id: 'registered_users' },
    { icon: 'help', text: 'Questions', id: 'questions' },
    { icon: 'edit_03', text: 'Edit Closure', id: 'edit_closures' },
    { icon: 'message_square_01', text: 'Messages', id: 'messages' },
    { icon: 'close_event', text: 'Close Event', id: 'close_event' }
  ];

  const CloseEventModalStyles = {
    content: {
      maxWidth: isMobileScreen ? '95%' : '40%',
      maxHeight: isMobileScreen ? '95%' : '60%',
      borderRadius: '12px'
    },
    overlay: {
      backgroundColor: 'rgba(114, 114, 114, 0.294)'
    }
  };

  const customConfirmationStyles = {
    content: {
      minWidth: '20%',
      minHeight: '10vh'
    },
    overlay: {
      backgroundColor: 'rgba(114, 114, 114, 0.294)'
    }
  };

  const PendingActionDropdown = (index: number) => {
    setListVisible(!listVisible);
    if (selectedItem !== null && selectedItem === index) {
      setSelectedItem(null);
    } else {
      setSelectedItem(index);
    }
  };

  const onEditClose = () => {
    setIsEdit(false);
    setShowSideBar(false);
    setIsDrawing(false);
    setDrawnPolygon(null);
  };

  useEffect(() => {
    if (selectedTab) {
      setDrawnPolygon(selectedClosure?.geometry?.coordinates[0]);
    }
    switch (selectedTab) {
      case 'registered_users':
        setIsRegisteredUsers(true);
        dispatch(
          updateClosureNotificationCount({
            closureId: selectedClosure?.id,
            action: 'clear_registered_users'
          })
        );
        break;
      case 'questions':
        setShowMap(false);
        break;
      case 'edit_closures':
        polygonRef.current?.setMap(mapRef.current);
        setIsDrawing(true);
        setShowMapClear(true);
        setIsEdit(true);
        setShowSideBar(true);
        break;
      case 'messages':
        setShowMap(false);
        break;
      case 'close_event':
        setDrawnPolygon(null);
        onModal();
        break;
      default:
        break;
    }
  }, [
    mapRef,
    polygonRef,
    selectedTab,
    setDrawnPolygon,
    setIsDrawing,
    setIsEdit,
    setIsRegisteredUsers,
    setShowMap,
    setShowMapClear,
    setShowSideBar,
    selectedClosure
  ]);

  useEffect(() => {
    if (selectedClosure && closuresData) {
      setSelectedItem(closuresData.results.indexOf(selectedClosure));
      setSelectedCard(selectedClosure);
      const updatedSelectedClosureIndex = closuresData.results.findIndex(
        (user) => user.id === selectedClosure.id
      );
      if (
        closuresData?.results?.length &&
        selectedClosure.id ===
          closuresData.results[updatedSelectedClosureIndex].id &&
        !_.isEqual(
          selectedClosure,
          closuresData.results[updatedSelectedClosureIndex]
        )
      ) {
        dispatch(
          setSelectedClosure({
            selectedClosure: closuresData.results[updatedSelectedClosureIndex]
          })
        );
      }
    } else if (!selectedClosure) {
      setSelectedItem(null);
      setSelectedCard(null);
    }
  }, [selectedClosure, closuresData]);

  useEffect(() => {
    if (closuresData) {
      setSelectedItem(
        selectedCard
          ? closuresData.results.findIndex(
              (user) => user.id === selectedCard.id
            )
          : null
      );
    }
  }, [selectedCard, closuresData]);

  useEffect(() => {
    if (isCloseEventSuccessful) {
      dispatch(setSelectedClosure({ selectedClosure: null }));
    }
  }, [isCloseEventSuccessful]);

  const handleActionClick = (menuItem: any, index: number) => {
    if (
      menuItem !== selectedTab ||
      closuresData?.results.indexOf(selectedClosure) !== selectedItem
    ) {
      onEditClose();
      setShowMap(true);
      dispatch(
        setSelectedClosure({ selectedClosure: closuresData?.results[index] })
      );
      setIsRegisteredUsers(false);
      dispatch(setSelectedTab({ selectedTab: menuItem }));
    }
  };

  const handleViewEventDetails = (item: any, index: number) => {
    if (selectedTab !== 'eventDetails' || selectedClosure?.id !== item.id) {
      setSelectedCard(closuresData?.results[index]);
      onEditClose();
      setShowMap(true);
      setIsRegisteredUsers(false);
      dispatch(
        setSelectedClosure({
          selectedClosure: closuresData?.results[index]
        })
      );
      dispatch(setSelectedTab({ selectedTab: 'eventDetails' }));
    }
  };

  const selectedClosureRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (selectedClosureRef.current) {
      // Scroll to the selected closure card
      selectedClosureRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [selectedCard]);

  const stateBoundary = useReduxSelector(
    (state: RootState) => state.ClientInfo.state_boundary
  );

  const handleClosureClick = (index: number) => {
    dispatch(
      setSelectedClosure({
        selectedClosure: closuresData?.results[index]
      })
    );
    if (
      selectedTab === 'eventDetails' &&
      currentUser?.user_permission_set.role_names.includes('IPAWS Admin') &&
      closuresData?.results[index].alert_status === 'not_submitted'
    )
      handleAddOrEditClosureClose();
  };

  const renderIPAWSConnectionStatus = () => {
    return (
      <>
        {currentUser?.user_permission_set.role_names.includes(
          'IPAWS Admin'
        ) && (
          <div className="ipwas-status">
            <h1>{STRINGS.IPAWS_CONNECT_STATUS} :</h1>
            <Lozenge
              variant="lowemphasis"
              type="success"
              text={
                cogDetails
                  ? cogDetails.connection_status
                  : isFetchingCOGDetails
                  ? STRINGS.LOADING
                  : 'N/A'
              }
            ></Lozenge>
          </div>
        )}
      </>
    );
  };

  const SectionBody = () => {
    return (
      <div>
        {renderIPAWSConnectionStatus()}
        {cards &&
          cards.map((item: any, index: number) => {
            if (
              !currentUser?.user_permission_set.role_names.includes('Admin') &&
              !currentUser?.user_permission_set.role_names.includes(
                'Read Only Admin'
              ) &&
              currentUser?.user_permission_set.role_names.includes(
                'IPAWS Admin'
              ) &&
              item.alert_status === 'not_submitted'
            )
              return <></>;
            return (
              <div key={index} className="card-data">
                <div
                  ref={selectedCard?.id === item.id ? selectedClosureRef : null}
                  className={
                    selectedCard?.id === item.id
                      ? 'selected-closure'
                      : 'non-selected-closure'
                  }
                />
                <section className="d-flex flex-column">
                  <h2
                    tabIndex={0}
                    aria-label="closure-text"
                    className={'selected-closure-item'}
                    onClick={() => {
                      handleClosureClick(index);
                    }}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        handleClosureClick(index);
                      }
                    }}
                  >
                    {item.heading}
                  </h2>
                  <div
                    style={item.statusVariant.status}
                    className={'card-status'}
                  >
                    <Icon
                      icon={'arrow_narrow_right'}
                      stroke={item.statusVariant.status}
                      className="me-2"
                    />
                    <div className="d-flex justify-content-between flex-grow-1">
                      <div>{item.statusVariant.content}</div>
                      {item.read_by &&
                        item.statusVariant.content === 'WEA Review Pending' && (
                          <img
                            src={SeenEyeIcon}
                            alt="seen-img"
                            loading="lazy"
                          />
                        )}
                    </div>
                  </div>

                  {item.read_by &&
                    item.statusVariant.content === 'WEA Review Pending' && (
                      <div className="d-flex justify-content-between mb-3">
                        <div className="read-by-text-wrapper">
                          <h3>{STRINGS.READ_BY}: </h3>
                          <p>{item.read_by.email}</p>
                        </div>
                        {/* Commented this code for now as we implemented this feature for only one ipaws admin and so tooltip is not needed  */}
                        {/* <div>
                          <div
                            data-tooltip-id={item.id}
                            tabIndex={0}
                            aria-label="info-icon"
                          >
                            <Icon
                              className="ms-1"
                              icon="info"
                              stroke={colorState.icon.default.primary}
                              width={18}
                              height={18}
                            />
                          </div>
                          <TooltipComponent
                            id={item.id}
                            place="bottom"
                            tooltipMaxWidth={'30vh'}
                          >
                            <div className="read-by-text-wrapper">
                              {STRINGS.READ_BY}: {item?.read_by?.fullname}
                            </div>
                          </TooltipComponent>
                        </div> */}
                      </div>
                    )}

                  {item.status && (
                    <div className="status-wrapper">
                      <h3>Status:</h3>
                      <p>{item.status}</p>
                    </div>
                  )}
                  {item.last_updated && (
                    <div className="updates-wrapper">
                      <h3>Last Updated:</h3>
                      <p>{item.last_updated}</p>
                    </div>
                  )}
                  {(currentUser?.user_permission_set.role_names.includes(
                    'Admin'
                  ) ||
                    currentUser?.user_permission_set.role_names.includes(
                      'Read Only Admin'
                    )) && (
                    <div className="action-dropdown">
                      <div
                        className={`header ${
                          selectedItem === index ? 'pb-2' : ''
                        }`}
                        onClick={() => PendingActionDropdown(index)}
                        tabIndex={0}
                        role="region"
                        aria-label="Collapsible Section"
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            PendingActionDropdown(index);
                          }
                        }}
                      >
                        <h3>{STRINGS.ACTIONS}</h3>
                        <div className="d-flex align-items-center">
                          {item.registered_users_count &&
                          selectedItem !== index ? (
                            <>
                              <div
                                className="notification-badge"
                                data-tooltip-id="register-users"
                              >
                                <Icon
                                  icon={'user_check_01'}
                                  stroke={colorState.icon.brand.primary}
                                  width={18}
                                  height={18}
                                />
                                <Badge variant="info">
                                  {item.registered_users_count}
                                </Badge>
                              </div>
                              <TooltipComponent id="register-users" noArrow>
                                No. of Registered Users
                              </TooltipComponent>
                            </>
                          ) : null}
                          {closuresUnreadMessagesCount &&
                          closuresUnreadMessagesCount[item.id] &&
                          selectedItem !== index ? (
                            <>
                              <div
                                className="notification-badge"
                                data-tooltip-id="messages"
                              >
                                <Icon
                                  icon={'message_square_01'}
                                  stroke={colorState.icon.brand.primary}
                                  width={18}
                                  height={18}
                                />
                                <Badge variant="info">
                                  {closuresUnreadMessagesCount[item.id]}
                                </Badge>
                              </div>
                              <TooltipComponent id="messages" noArrow>
                                No. of Messages
                              </TooltipComponent>
                            </>
                          ) : null}
                          <Icon
                            icon={`${
                              selectedItem === index
                                ? 'chevron_up'
                                : 'chevron_down'
                            }`}
                          />
                        </div>
                      </div>
                      {selectedItem === index && (
                        <ul className="action-list pt-1" role="list">
                          {menuItems.map((menuItem, menuIndex) => {
                            if (
                              (menuItem.id === 'edit_closures' &&
                                !getPermission(
                                  currentUser,
                                  'change_closureticket'
                                )) ||
                              (menuItem.id === 'close_event' &&
                                !getPermission(
                                  currentUser,
                                  'close_closureticket'
                                )) ||
                              (menuItem.id === 'registered_users' &&
                                !getPermission(
                                  currentUser,
                                  'view_registereduser'
                                )) ||
                              (menuItem.id === 'questions' &&
                                !getPermission(
                                  currentUser,
                                  'view_closurequestions'
                                )) ||
                              (menuItem.id === 'messages' &&
                                !getPermission(
                                  currentUser,
                                  'view_unread_messages'
                                ))
                            )
                              return <></>;
                            return (
                              <li
                                key={menuIndex}
                                onClick={() => {
                                  handleActionClick(menuItem.id, index);
                                }}
                                className={`
                                  ${
                                    selectedTab === menuItem.id &&
                                    selectedClosure?.id === item.id
                                      ? 'selected-item'
                                      : ''
                                  }
                                justify-content-between`}
                                tabIndex={0}
                                aria-label={menuItem.text}
                                onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                    handleActionClick(menuItem.id, index);
                                  }
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <Icon
                                    icon={menuItem.icon}
                                    stroke={
                                      selectedTab === menuItem.id &&
                                      selectedClosure?.id === item.id
                                        ? colorState.icon.brand.primary
                                        : ''
                                    }
                                  />
                                  <h4>{menuItem.text}</h4>
                                </div>
                                <div>
                                  {menuItem.id === 'registered_users' &&
                                  item.registered_users_count ? (
                                    <Badge variant="info">
                                      {item?.registered_users_count}
                                    </Badge>
                                  ) : null}
                                  {menuItem.id === 'messages' &&
                                  closuresUnreadMessagesCount &&
                                  closuresUnreadMessagesCount[item.id] ? (
                                    <Badge variant="info">
                                      {closuresUnreadMessagesCount[item.id]}
                                    </Badge>
                                  ) : null}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  )}
                  {currentUser?.user_permission_set.role_names.includes(
                    'IPAWS Admin'
                  ) &&
                    item.alert_status !== 'not_submitted' && (
                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <Button
                          className={`${
                            !(
                              selectedTab === 'eventDetails' &&
                              selectedClosure?.id === item.id &&
                              item.statusVariant.content ===
                                'WEA Review Pending'
                            ) && 'w-100'
                          }`}
                          onClick={() => {
                            handleViewEventDetails(item, index);
                          }}
                          {...btnStyles.secondary}
                        >
                          View Event Details
                          <Icon
                            icon="arrow_narrow_right"
                            stroke={colorState.icon.brand.primary}
                            width={16}
                            height={16}
                            className="ms-1"
                          />
                        </Button>
                        {/* disable the mark as read button based on read_by */}
                        {selectedTab === 'eventDetails' &&
                          item.statusVariant.content === 'WEA Review Pending' &&
                          selectedClosure?.id === item.id && (
                            <span
                              tabIndex={0}
                              aria-label="mark-as-read"
                              className={`${
                                (item.read_by ||
                                  isMarkingAlertAsReadInProgress) &&
                                'disabled'
                              } mark-as-read-text`}
                              onClick={() => {
                                if (
                                  getPermission(currentUser, 'set_read_by') &&
                                  !item.read_by &&
                                  !isMarkingAlertAsReadInProgress
                                ) {
                                  dispatch(
                                    markAlertAsRead({ closureId: item.id })
                                  );
                                }
                              }}
                            >
                              {isMarkingAlertAsReadInProgress
                                ? STRINGS.MARKING
                                : STRINGS.MARK_AS_READ}
                            </span>
                          )}
                      </div>
                    )}
                </section>
              </div>
            );
          })}
      </div>
    );
  };
  const onModal = () => {
    setIsModalOpen(true);
  };

  const setMapDefaultvalues = () => {
    if (stateBoundary) {
      const centerPoint = getCentroid(stateBoundary.coordinates[0]);

      setMapCenter({
        lat: centerPoint[0],
        lng: centerPoint[1]
      });
    } else {
      setMapCenter({ lat: 0, lng: 0 });
    }
    setZoomLevel(8);
  };
  const postModalClose = () => {
    setIsConfirmationModalOpen(false);
    setIsModalOpen(false);
    dispatch(setSelectedClosure({ selectedClosure: null }));
    dispatch(setSelectedTab({ selectedTab: '' }));
    setIsCloseEvent(false);
  };
  const handleCloseEvent = () => {
    setIsConfirmationModalOpen(true);
    setIsModalOpen(false);
    setIsCloseEvent(false);
  };

  const handleCloseConfirm = () => {
    setIsConfirmationModalOpen(false);
    setMapDefaultvalues();
    dispatch(setSelectedClosure({ selectedClosure: null }));
  };

  const NoClosuresData = () => {
    return (
      <>
        {renderIPAWSConnectionStatus()}
        <div className="d-flex flex-column justify-content-center align-items-center pt-4">
          <Suspense fallback={<PALoader />}>
            <NoDataFound
              src={NoClosureEventIcon}
              description={'No Events Created'}
              alt={'no-closure-events'}
            />
          </Suspense>
          {getPermission(currentUser, 'add_closureticket') && (
            <Button
              {...btnStyles.primary}
              className={'mt-3'}
              onClick={openClosure}
            >
              <Icon
                icon={'map_pin'}
                stroke={colorState.icon.default['primary-inverse']}
              />
              Add New Closure
            </Button>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {
        <LeftSidePanel
          header={'Closures'}
          icon={'filters'}
          isFilterButton={false}
          isMobileCollapsible={!isMobileScreen}
          body={
            !isFetchingClosuresData && closuresData?.results.length ? (
              SectionBody()
            ) : isFetchingClosuresData ? (
              <PALoader />
            ) : (
              NoClosuresData()
            )
          }
        />
      }
      {/* Close event modal */}
      <Suspense fallback={<PALoader />}>
        <CloseEventModal
          isOpen={isModalOpen}
          title={'Close this Event'}
          showHeader={true}
          showFooter={false}
          style={CloseEventModalStyles}
          postModalClose={postModalClose}
          closeModal={() => setIsModalOpen(false)}
          showBorderTop={true}
          isBodyPaddingNone={true}
          selectedClosure={selectedClosure}
          handleCloseEvent={handleCloseEvent}
          setCloseEventPayload={setCloseEventPayload}
          setIsCloseEvent={setIsCloseEvent}
          setRegisteredUsersLocation={setRegisteredUsersLocation}
          setMapCenter={setMapCenter}
          {...modalStyles}
        />
      </Suspense>
      {/* Confirmation popup] modal */}
      <Suspense fallback={<PALoader />}>
        <ConfirmationPopupModal
          isOpen={isConfirmationModalOpen}
          showHeader={true}
          title={STRINGS.CLOSE_EVENT}
          showFooter={false}
          style={customConfirmationStyles}
          postModalClose={postModalClose}
          closeModal={() => setIsConfirmationModalOpen(false)}
          showBorderTop={false}
          isBodyPaddingNone={true}
          description={STRINGS.ARE_YOU_SURE_YOU_WANT_TO_CLOSE_THIS_EVENT}
          handleCloseConfirm={handleCloseConfirm}
          closeEventPayload={closeEventPayload}
          {...confirmPopupModalStyles}
        />
      </Suspense>
    </>
  );
}
